import "../Css/SuggestionBox.css";
import SuggestionRow from "./SuggestionRow";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";


function SuggestionBox(props) {

  const onlineUser = JSON.parse(localStorage.getItem('flickerNetwork') != null) ? JSON.parse(localStorage.getItem('flickerNetwork')) : {username:"" , name:"", avatarPic:"profile-index.png"};
  const online = JSON.parse(localStorage.getItem('flickerNetwork')) != null ? true : false;
  
  return (
    <div id="suggestion-box-wraper">
      <div id="suggestion-box">
        <h5 className="title">{props.title}</h5>

        {props.title === "پیشنهاد افراد" ? (
          <div>
            {props.fetchedUsers.map((user,i) => (
              
              //don't show online user:
              user._id != onlineUser._id ?
              (<SuggestionRow
                key={i}
                username={user.username}
                name={user.name}
                job={user.job}
                image={user.avatarPic}
                _id={user._id}
                isPage={false}
                isPost={false}
                online={online}
              ></SuggestionRow>)
              : null
            ))}

          </div>
        ) : (
          <div>

            {props.fetchedPages.map((page,i) => (

              //don't show online user's page:
              page.creator_id != onlineUser._id ?

              (<SuggestionRow
                key={i}
                username={page.username}
                name={page.name}
                image={page.avatarPic}
                _id={page._id}
                isPage={true}
                isPost={false}
                online={online}
              ></SuggestionRow>)
              :null
            ))}
          
          </div>
        )}

        <Link
          className="button"
          to={
            props.title === "پیشنهاد افراد" ? "/search/users" : "/search/pages"
          }
          state={props.user}
        >
          مشاهده همه
        </Link>
      </div>
    </div>
  );
}

export default SuggestionBox;
