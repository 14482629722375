import axios from "axios";
import { Column } from "primereact/column";
import { ContextMenu } from "primereact/contextmenu";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Carousel } from 'primereact/carousel';


function ManageActivities(props)
{

    const toast = useRef(null);
    const [activities,setActivities] = useState([]);
    const [updateActivities,setUpdateActivities] = useState(0);
    const [selectedActivity,setSelectedActivity] = useState({user_id:"",avatarCount:"",coverCount:"",jobCount:"",bioCount:"",postsCount:"",pagesCount:""});
    const [showEditDialog,setShowEditDialog] = useState(false);
    const cm = useRef(null);
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const menuModel = [
        {label: 'ویرایش', icon: 'pi pi-fw pi-pencil', command: () => setShowEditDialog(true)
    
            }
    ];
    const navigate = useNavigate();
    const pictures = [{},{},{}];


    //get all activities from database:
    useEffect(() => {
        async function getAllActivities() {

        
        const result = await axios.get(process.env.REACT_APP_BACK+"/activities/all")
        .then((result) => {
            if (result.status == 200) {
            setActivities(result.data);
            console.log("all activities",result.data);
            return result.data;
            }
        })
        .catch((error) => {
            console.log(error);
            if(error.response.data.statusCode===401)
              navigate("/login")
        });

        
        return result.data;

        }
        getAllActivities();

    },[updateActivities]);

    const imageBodyTemplate = (rowData) => {

      if(rowData.user_id)
      {
        return  <Link to={"/"+rowData.user_id.username+"/about"}
            state={{otherUser: {_id:rowData.user_id._id, username:rowData.user_id.username, name:rowData.user_id.name , job:rowData.user_id.job , avatarPic:rowData.user_id.avatarPic} 
            , profileOfOther:true}}>
        <img 
        src={`${process.env.REACT_APP_BACK}/avatars/${rowData.user_id.avatarPic}`}
        style={{height:"50px",width:"50px",borderRadius:"50%"}}></img>
        </Link>
        ;
      }
      else
      return;
    }


    const editForm = useFormik({
        initialValues: {
          avatarCount: selectedActivity.avatarCount,
          coverCount: selectedActivity.coverCount,
          jobCount: selectedActivity.jobCount,
          bioCount: selectedActivity.bioCount,
          postsCount: selectedActivity.postsCount,
          pagesCount:selectedActivity.pagesCount
        },
    
        onSubmit: (values) => {
    
    
          const obj = {};
    
          for (const key in values) {
              obj[key] = values[key];
        }
    
    
          const data = JSON.stringify(obj);
     
          
          
       
        axios.patch(process.env.REACT_APP_BACK+"/activities/manage-activity/"+selectedActivity._id, data, config)
            .then(response => 
              {
    
              if(response.status == 200)
              {
                console.log(response);
                setShowEditDialog(false);
                setUpdateActivities(updateActivities+1);
          
              }
    
            })
            .catch(error => {
               console.log(error);
               if(error.response.data.statusCode===401)
              navigate("/login")
            });
    
            
          
        },
      });

      //initialize formik:
   useEffect(() => {
    async function initForm() {

      editForm.values.avatarCount = selectedActivity.avatarCount;
      editForm.values.coverCount = selectedActivity.coverCount;
      editForm.values.jobCount = selectedActivity.jobCount;
      editForm.values.bioCount = selectedActivity.bioCount;
      editForm.values.postsCount = selectedActivity.postsCount;
      editForm.values.pagesCount = selectedActivity.pagesCount;


    }
    initForm();
  },[selectedActivity]);

    return(
        
        <div id="manage-activities">




            <h2>
            مدیریت اکتیویتی ها
            </h2>


            {/* test */}
            <Carousel value={pictures} numVisible={3} numScroll={1} itemTemplate={(image,index)=><img key={index} src={require("../images/app.png")} style={{width:"50%"}}/>}></Carousel>

            <Toast ref={toast}></Toast>

            <ContextMenu className={props.theme} model={menuModel} ref={cm} />

            <DataTable size="normal" value={activities} paginator rows={20} 
            className="manager-table"
            contextMenuSelection={selectedActivity}
            onContextMenuSelectionChange={e => setSelectedActivity(e.value)}
            onContextMenu={e => cm.current.show(e.originalEvent)}
            >
            
            <Column filed="file" body={imageBodyTemplate} header="کاربر"></Column>
            <Column alignHeader="center" align="center" field="avatarCount" header="تغییر آواتار" sortable filter ></Column>
            <Column alignHeader="center" align="center" field="coverCount" header="تغییر کاور" sortable filter ></Column>
            <Column alignHeader="center" align="center" field="jobCount" header="تغییر شغل"  sortable></Column>
            <Column alignHeader="center" align="center" field="bioCount" header="تغییر بیوگرافی"  sortable></Column>
            <Column alignHeader="center" align="center" field="postsCount" header="تعداد پستها"  sortable></Column>
            <Column alignHeader="center" align="center" field="pagesCount" header="تعداد صفحه ها"  sortable></Column>

            
            </DataTable>


            <Dialog
                className={props.theme}
                id="edit-user-dialog"
                header={  "ویرایش اکتیویتی "  + selectedActivity.user_id ? selectedActivity.user_id.name :""  }
                visible={showEditDialog}
                onHide={() => setShowEditDialog(false)}
                >
            
          <form onSubmit={editForm.handleSubmit} className="p-fluid">
        

            <div className="field">
              <label htmlFor="avatarCount">تغییر آواتار</label>
              <span className=" p-input-icon-left">
                <InputText
                  id="avatarCount"
                  name="avatarCount"
                  value={editForm.values.avatarCount}
                  onChange={editForm.handleChange}
                />
              </span>
            </div>

            <div className="field">
              <label htmlFor="prises">تغییر کاور </label>
              <span className=" p-input-icon-left">
                <InputText
                  id="coverCount"
                  name="coverCount"
                  value={editForm.values.coverCount}
                  onChange={editForm.handleChange}
                  
                />
              </span>
            </div>

            <div className="field">
              <label htmlFor="jobCount">تغییر شغل</label>
              <span className=" p-input-icon-left">
                <InputText
                  id="jobCount"
                  name="jobCount"
                  value={editForm.values.jobCount}
                  onChange={editForm.handleChange}
                />
              </span>
            </div>

            <div className="field">
              <label htmlFor="bioCount">تغییر بیوگرافیها</label>
              <span className=" p-input-icon-left">
                <InputText
                  id="bioCount"
                  name="bioCount"
                  value={editForm.values.bioCount}
                  onChange={editForm.handleChange}
                />
              </span>
            </div>

            <div className="field">
              <label htmlFor="postsCount">تعداد پستها</label>
              <span className=" p-input-icon-left">
                <InputText
                  id="postsCount"
                  name="postsCount"
                  value={editForm.values.postsCount}
                  onChange={editForm.handleChange}
                />
              </span>
            </div>

            <div className="field">
              <label htmlFor="pagesCount">تعداد صفحه ها</label>
              <span className=" p-input-icon-left">
                <InputText
                  id="pagesCount"
                  name="pagesCount"
                  value={editForm.values.pagesCount}
                  onChange={editForm.handleChange}
                />
              </span>
            </div>

        


          

            <div className="two-columns">
              <div className="field col-one">
                <span>
                  <Button type="submit" label="ذخیره" className="mt-2" />
                </span>
              </div>

              <div className="field col-two">
                <span>
                  <Button
                    className="outline-button mt-2"
                    type="button"
                    label="انصراف"
                    onClick={() => setShowEditDialog(false)}
                  />
                </span>
              </div>
            </div>
          </form>
        

        
            </Dialog>
        


        </div>


    );

}

export default ManageActivities;