import { Link } from "react-router-dom";
import "../Css/MainNavigation.css";
import { useState, useEffect } from "react";
import "react-slideshow-image/dist/styles.css";
import { Button } from "primereact/button";

function MainNavigation() {
  const [login, setLogin] = useState(false);
  const [signup, setSignup] = useState(false);
  const [stickyMenu,setStickyMenu] = useState(false);


//go to top of the page:
useEffect(() => {
  window.scrollTo(0, 0)
}, []);

 
 //check sticky menu:
useEffect(() => {

  const handleScroll = () =>
  {
    const isSticky = window.scrollY > 700;
    setStickyMenu(isSticky);
  };
  window.addEventListener("scroll",handleScroll);

  return() => {
    window.removeEventListener("scroll",handleScroll);
  };

  
}, []);

  

  return (
    <header id="header" className={stickyMenu ? "sticky-menu active" : "sticky-menu" }>
      <ul className="login">
        <li>

        <Link to="/login">
          <Button
            label="ورود"
            className="login-button"
            onClick={() => setLogin(true)}
          />
          </Link>
          
          {" "}
          &nbsp;
          {/* <Dialog
            header="ورود به سایت"
            visible={login}
            style={{ width: "40vw" }}
            onHide={() => setLogin(false)}
          >
            <div>
              <ul className="add_product_field">
                <li className="label">
                  <label htmlFor="username">ایمیل یا شماره تلفن</label>
                </li>
                <li>
                  {" "}
                  <InputText id="username" />
                </li>
              </ul>

              <div style={{ marginTop: "2rem" }}>
                <ul className="add_product_field">
                  <li className="label">
                    <label htmlFor="password">رمز عبور</label>
                  </li>
                  <li>
                    {" "}
                    <Password inputId="password" toggleMask />
                  </li>
                </ul>
              </div>

              <div
                style={{
                  marginTop: "4rem",
                  marginLeft: "0rem",
                  display: "table",
                  margin: "auto",
                }}
              >
                <Link to="/dashboard">
                  <Button
                  className="login-dialog"
                    label="ورود"
                    onClick={() => {
                      setLogin(false);
                    }}
                  />
                </Link>
              </div>
            </div>
          </Dialog> */}
        </li>
        <li>

        <Link to="/register">
          <Button
            label="ثبت نام"
            className="register-button"
            onClick={() => setSignup(true)}
          />
          </Link>

          {/* <Dialog
            header="فرم ثبت نام"
            visible={signup}
            style={{ width: "50vw" }}
            onHide={() => setSignup(false)}
          >
            <Register />
          </Dialog> */}
        </li>
      </ul>

      {/* <nav>
                <ul>

                    <li>
                        <Link to="/new-product">محصول جدید</Link>
                    </li>
                    <li>
                        <Link to="/">همه محصولات</Link>
                    </li>
                </ul>
            </nav> */}

      
        <div className="logo">
        <Link to="/">
          <ul>
            <li className="logo-image" >
            <img src={ require('../images/logo.png') } />
            </li>
            <li>
            <p>
            فروشگاه زیبا
            </p>
            </li>
            </ul>
            </Link>
            </div>
     
    </header>
  );
}

export default MainNavigation;
