import "../Css/SideBar.css";
import SuggestionBox from "./SuggestionBox";

function SideBar(props)
{

    

    return(
        <div id="side-bar">
            <SuggestionBox fetchedUsers={props.fetchedUsers} title="پیشنهاد افراد"></SuggestionBox>
            <SuggestionBox fetchedPages={props.fetchedPages} title="پیشنهاد صفحه ها"></SuggestionBox>

        </div>
    );
}

export default SideBar;