import axios from "axios";
import { InputText } from "primereact/inputtext";
import { useEffect, useRef, useState } from "react";
import "../Css/Chip.css";
import { useNavigate } from "react-router-dom";


function Chip(props)
{

    const [suggestions,setSuggestions] = useState([]);
    const [selections,setSelections] = useState( props.selections ? props.selections : []);
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const [query,setQuery] = useState("");
    const chipSuggestionsRef = useRef(null);
    const chipInputRef = useRef(null);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
    const navigate = useNavigate();
    const [searchLoading,setSearchLoading] = useState(false);
    const suggestionsRef = useRef(suggestions); 

    


    useEffect(()=>
    {
      if(props.selections)
      setSelections(props.selections);
      
    },[props]);

    useEffect(()=>
      {
        suggestionsRef.current = suggestions;
        
      },[suggestions]);
     
  const fetchSuggestions = async (query) =>
  {


    let allUrl,conditionalUrl;
    let offset=10;

        if(props.type==="taggedUsers")
        {
            allUrl = process.env.REACT_APP_BACK+"/users/search/"+0;
            conditionalUrl = process.env.REACT_APP_BACK+"/users/search_users_and_pages/"+query+"/"+0;
        }
        else if(props.type === "tags")
        {
            allUrl=process.env.REACT_APP_BACK+"/posts/tags/";
            conditionalUrl= process.env.REACT_APP_BACK+"/posts/tags/"+query;
        }
        else if(props.type === "riderPr")
          {
              allUrl=process.env.REACT_APP_BACK+"/posts/products/rider/"+0
              conditionalUrl= process.env.REACT_APP_BACK+"/posts/search-rider-products/"+query;
          }
          else if(props.type === "horsePr")
            {
                allUrl=process.env.REACT_APP_BACK+"/posts/products/horse/"+0
                conditionalUrl= process.env.REACT_APP_BACK+"/posts/search-horse-products/"+query;
            }
         
          else if(props.type === "internalLinkToArticles")
          {
            conditionalUrl= process.env.REACT_APP_BACK+"/articles/search/"+query+"/"+0;
          }
          else if(props.type === "internalLinkToTags")
          {
            allUrl=process.env.REACT_APP_BACK+"/posts/tags/";
            conditionalUrl= process.env.REACT_APP_BACK+"/posts/tags/"+query;
          }

    //if query is empty:
    if (query=="") {
  
      await axios.get(allUrl, config).then(async (result) => 
      {

        if (result.status == 200) 
        {

              await setSuggestions(result.data);
              // console.log("fetched suggestions: ", result.data);
              
              return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
                  navigate("/login")
      });

  
    }
  else {

        let u = conditionalUrl;
        if(props.type==="riderPr" || props.type === "horsePr" )
          u += "/"+offset;

        await axios.get(u , config)
        .then(async(result) => {


            if (result.status == 200) 
            {
              
                
                //if suggestion doesnot contain the tag, add it:
                if(props.type === "tags" && !result.data.some(element => element.value === query))
                    result.data.push({value:query,count:0})

                // if(props.type !== "internalLinkToArticles")
                  await setSuggestions(result.data);

                
                
                if(result.data.length===0 && props.type==="tags" )
                  await setSuggestions([{value:query,count:0}]);

              console.log("fetched suggestions: ", result.data);
              
              
              return result.data;
            }
          })
          .catch((error) => {
            console.log(error);
            if(error.response.data.statusCode===401)
                  navigate("/login")
          });


          //lazy loading:
          
          const elements = document.querySelectorAll('.chip-suggestions');
          

          if (elements) {
          
            elements.forEach(element => {
              element.addEventListener('scroll', async function() {
                
              
               // Check if the user has scrolled down a certain amount
  
              const { scrollTop, scrollHeight, clientHeight } = element;
  
            if (scrollTop + clientHeight >= scrollHeight) {
                  
              
          
  
                  
            //fetch next:
            if(suggestionsRef.current.length === (offset))
            {
              offset += 10;
              await setSearchLoading(true);
  
              let u = conditionalUrl;
              if(props.type==="riderPr" || props.type === "horsePr")
                u += "/"+offset;
              
              await axios.get(u , config)
              .then((result) => {
                if (result.status == 200) {
  
                  setSuggestions(prevArray => [...prevArray, ...result.data]); 
                  // console.log("nexxxxxxxxxxxxxxt: ", result.data);
                  
                  
                  return result.data;
                }
              })
              .catch((error) => {
                console.log(error);
                if(error.response.data.statusCode===401)
                      navigate("/login")
              });
                
            
  
              setSearchLoading(false);
        
            }
          
            
          }
        
        
              });
  
        });
      }
            

           
    

  

  }
}

  //wait until user finishes typing:
  useEffect(() => {
    
    const delayedApiRequest = setTimeout(async () => {


      if (query) {
  
        await fetchSuggestions(query);
 
      }
    }, 500);

    
    return () => clearTimeout(delayedApiRequest);
  }, [query]);



  const onSearch =  async (event) => {

    if(event.target.value==="")
        setSuggestions([]);
    

    
    await setQuery(event.target.value.trim());
}

const onKeyDown = async (event) =>
{
  if(props.type ==="tags")
  {
    
    if(event.key ==="Enter")
    {
      event.preventDefault();
      if(query!=="")
        addSelectedItem({value:query});

    }

  }
}



const suggestionTemplate = (item, index) => {


  if (props.type === "taggedUsers" ) {
    // Template for user suggestions
    return (
      <div className="user-item" key={index}>
        <span style={{ display: "flex", alignItems: "center", direction: "rtl", padding: "0px 30px" }}>
          <img
            src={process.env.REACT_APP_BACK + `/avatars/${item.avatarPic}`}
            alt={item.name}
            className="user-avatar"
          />
          <span className="user-name">{item.name}</span>
        </span>
      </div>
    );
  } else if (props.type === "tags" ) {
    // Template for tag suggestions
    return (
      <div key={index}>
        <div className="user-item">
          <span style={{ display: "flex", alignItems: "center", direction: "rtl", padding: "0px 30px" }}>
            <span className="user-name">{item.value}</span>
          </span>
        </div>
      </div>
    );
  } else if (props.type === "riderPr") {
    // Template for rider products suggestions
    return (
      <div key={index}>
        <div className="products-item">
          <span style={{ display: "flex", alignItems: "center", direction: "rtl", padding: "0px 30px" }}>
          <img src={process.env.REACT_APP_BACK+`/posts/${item.file}`}  alt="محصولی از فلیکا" className="suggestion-products" />
            <span className="user-name"
            dangerouslySetInnerHTML={{
                            __html: item.description?.length>=50
                                ? item.description?.substring(0, 50)  + '...'
                                : item.description?.substring(0, 50)
                        }}
                    />
              </span>
          
        </div>
      </div>
    );
  } else if (props.type === "horsePr") {
    // Template for horse products suggestions
    return (
      <div key={index}>
        <div className="products-item">
          <span style={{ display: "flex", alignItems: "center", direction: "rtl", padding: "0px 30px" }}>
          <img src={process.env.REACT_APP_BACK+`/posts/${item.file}`}  alt="محصولی از فلیکا" className="suggestion-products" />
          <span className="user-name"
            dangerouslySetInnerHTML={{
                            __html: item.description?.length>=50
                                ? item.description?.substring(0, 50)  + '...'
                                : item.description?.substring(0, 50)
                        }}
                    />
              </span>
          
        </div>
      </div>
    );
  }

  else if (props.type === "internalLinkToArticles") {
   
    // Template for article suggestions
    return (
      <div key={index}>
         
        <div className="user-item">
          <span style={{ display: "flex", alignItems: "center", direction: "rtl" }}>
           
            <span className="user-name">{item.title}</span>
          </span>
        </div>
      </div>
    );
  }
  else if (props.type === "internalLinkToTags") {
   
    // Template for tag suggestions
    return (
      <div key={index}>
        <div className="user-item">
          <span style={{ display: "flex", alignItems: "center", direction: "rtl", padding: "0px 30px" }}>
            <span className="user-name">{item.value}</span>
          </span>
        </div>
      </div>
    );
  }



  // Default case if no type matches
  return null;
};


const selectedTemplate = (item,index) => {


    return (
      
        <span  className="selected-item" style={{display:"flex",alignItems:"center",direction:"rtl"}}>
        <i className="pi pi-times-circle"
        
        ></i>
        
        <span className="user-name">
          {props.type==="taggedUsers" ? item.name : null}
          {props.type==="tags" ? item.value : null}
          </span>

          
          {props.type==="taggedUsers" ? 
          <img src={process.env.REACT_APP_BACK+`/avatars/${item.avatarPic}`}  alt={item.name} className="user-avatar" />
          : null}

          {props.type==="riderPr" ? 
          <span style={{display:'flex',alignItems:'center'}}>
          <img src={process.env.REACT_APP_BACK+`/posts/${item.file}`}  alt="فلیکا، شبکه اسب دوستان" className="user-avatar" />
          
          <p
            dangerouslySetInnerHTML={{
                            __html: item.description.length>=50
                                ? item.description.substring(0, 50)  + '...'
                                : item.description.substring(0, 50)
                        }}
                    />
              
          </span>
          : null}

          {props.type==="horsePr" ? 
          <span style={{display:'flex',alignItems:'center'}}>
          <img src={process.env.REACT_APP_BACK+`/posts/${item.file}`}  alt="فلیکا، شبکه اسب دوستان" className="user-avatar" />
          <p
            dangerouslySetInnerHTML={{
                            __html: item.description.length>=50
                                ? item.description.substring(0, 50)  + '...'
                                : item.description.substring(0, 50)
                        }}
                    />
          </span>
          : null}

          {props.type==="internalLinkToTags" ? 
          <span style={{display:'flex',alignItems:'center'}}>
            {item}
          </span>
          : null}

          {props.type==="internalLinkToArticles" ? 
          <span style={{display:'flex',alignItems:'center'}}>
          
          {item}
          </span>
          : null}

          
          
        </span>
        
    
  
    );
  };

  const deleteSelectedItem = (index) =>
  {
    
    if (index >= 0 && index < selections.length) {
      const newArray = [...selections];
      newArray.splice(index, 1);
      setSelections(newArray);
      props.setSelections(newArray);

    } else {
      console.log("Invalid index");
    }
    
    
  }
  const addSelectedItem = (item) =>
  {
    const newArray = [...selections];


    //check if user was added before:
    if( (props.type==="taggedUsers"  && !selections.some(element => element._id === item._id) ||
           props.type==="tags") ||
           props.type==="riderPr"  && !selections.some(element => element._id === item._id) ||
           props.type==="horsePr"  && !selections.some(element => element._id === item._id) ||
           props.type==="internalLinkToTags"  && !selections.some(element => element.value === item.value) ||
           props.type==="internalLinkToArticles"  && !selections.some(element => element.value === item.value) 
    )
    {
      if(props.type==="internalLinkToTags")
        newArray.push(item.value);
      else if(props.type==="internalLinkToArticles")
        newArray.push(item.title);
      else
        newArray.push(item);
      setSelections(newArray);
      props.setSelections(newArray);
    }
    
    
    setSuggestions([]);
    setQuery("");

  }

  //calculate position of selections section:
  useEffect(() => {
  

      if (chipSuggestionsRef.current && chipInputRef.current) {
        const chipInputRect = chipInputRef.current.getBoundingClientRect();
        const chipSuggestionsRect = chipSuggestionsRef.current.getBoundingClientRect();
  
        const top = chipInputRect.top - chipSuggestionsRect.height;

  
        setPosition({top});
      }
      
  
      
     

  },[suggestions]);




return(

    <div id="chip" >

        <span id="chip-selections" 
              style={{display: !selections || selections.length===0 ? "none" : null, display:"flex",flexWrap:"wrap",alignItems:"center",direction:"rtl"}}
        >

            {selections && selections.map((item,index) =>(
                <span key={index} className="user-item" style={{display:"flex",alignItems:"center",direction:"rtl"}}
                onClick={() =>deleteSelectedItem(index)}>
                    {selectedTemplate(item,index)}
                </span>    

            ))}

        </span>

        

        <div className="chip-suggestions" 
        ref={chipSuggestionsRef}
            style={{display: suggestions.length===0 ? "none" : null , top: `${position.top}px` }}
        >

         

          {/* load animation */}
          {searchLoading ? 
            <div className="spinner" style={{marginBottom:"50px"}}></div>
          : null}
 
            {suggestions.map((item, index) => (
                <div key={index}
                onClick={()=> addSelectedItem(item)}>

                 
                {suggestionTemplate(item,index)}
                </div>
            ))}

        </div>

        <div id="chip-input"
        ref={chipInputRef}>
            <InputText 
                value={query}
                onChange={onSearch}
                onKeyDown={onKeyDown}
                placeholder="اینجا جستجو کنید ..."
            ></InputText>
        </div>

    
        

    </div>
);

    



}

export default Chip;