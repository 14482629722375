import axios from "axios";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import "../Css/PageForm.css";
import "../Css/NavPanel.css";
import HorseDropdown from "./HorseDropdown";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function EditPageForm(props)
{
  const horseGender = ["مادیان", "سیلمی","اخته"];
  const horseActivity = ["پرش", "درساژ","زیبایی","کورس","سایر"];
  const pageDescrption = {horse:"افتخارات و ...",event:"تاریخ برگزاری و ...",shop:"نوع محصولات و ...",farm:"زمینه فعالیت ، متراژ، تعداد مانژ ها و ..."}
  const groupeTypes=["عمومی","خصوصی"];
  const token = JSON.parse(localStorage.getItem('userToken'));
  const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
  const [horseMother,setHorseMother]=useState(props.page.mother!==null && props.page.mother!==undefined  ? {value:props.page.mother._id,label:props.page.mother.name}:null);
  const [horseFother,setHorseFother]=useState(props.page.father!==null && props.page.father!==undefined ? {value:props.page.father._id,label:props.page.father.name}:null);
  const navigate = useNavigate();
  const handleHorseMotherChange = (selectedValue) => {
    setHorseMother(selectedValue.value);
  };
  const handleHorseFotherChange = (selectedValue) => {
    setHorseFother(selectedValue.value);
  };
    const pageForm = useFormik({
        initialValues: {
            name: props.page.name,
            description: props.page.description,
            country: props.page.country,
            city:props.page.city,
            //for horse
            age:props.page.age,
            gender:props.page.gender,
            race:props.page.race,
            farm:props.page.farm,
            activity:props.page.activity,
            //for group
            groupMode:props.page.groupMode

        },
        validate: (values) => {
            let errors = {};
      
            if (!values.name) {
              errors.name = "نام الزامی است";
            }
      
            return errors;
          },
    
        onSubmit: (values) => {


          const obj = {};

          for (const key in values) 
              obj[key] = values[key];

          obj["father"]=horseFother;
          obj["mother"]=horseMother;
      

          const data = JSON.stringify(obj);
     
          
          
       
        axios.patch(process.env.REACT_APP_BACK+"/pages/update-page/"+props.page._id, data, config)
            .then(response => {

              if(response.status == 200)
              console.log(response)
              props.updatePage();
              props.close();
            })
            .catch(error => {
               console.log(error);
               if(error.response.data.statusCode===401)
               navigate("/login")
            });

            
          
        },
      });

      const isFormFieldValid = (phone) =>
          !!(pageForm.touched[phone] && pageForm.errors[phone]);
        const getFormErrorMessage = (phone) => {
          return (
            isFormFieldValid(phone) && (
              <small className="p-error" style={{float: "right"}}>{pageForm.errors[phone]}</small>
            )
          );
        };
    
    return(
        <div>
          <form onSubmit={pageForm.handleSubmit} className="p-fluid">
        

            <div className="two-columns">
              <div className="field col-one">
              <div>
              <label htmlFor="username">نام کاربری</label>
            </div>
            <InputText
            className={classNames({
              "p-invalid": isFormFieldValid("username"),
            })}
              id="username"
              name="username"
              value={pageForm.values.username}
              onChange={pageForm.handleChange}
              disabled={true}
              placeholder={props.page.username}
              autoFocus
            />
            {getFormErrorMessage("username")}
              </div>

              <div className="field col-two">
              <div>
              <label htmlFor="name">نام </label>
            </div>
            <InputText
            className={classNames({
              "p-invalid": isFormFieldValid("name"),
            })}
              id="name"
              name="name"
              value={pageForm.values.name}
              onChange={pageForm.handleChange}
              
            />
            {getFormErrorMessage("name")}
              </div>
            </div>

       

            {/* for horse ************************************************************************/}
            { props.type == "horse" ?
            <div>
                <div className="two-columns">
              <div className="field col-one">
              <div>
              <label htmlFor="age">سال تولد (میلادی)</label>
            </div>
            <InputText
            className={classNames({
              "p-invalid": isFormFieldValid("age"),
            })}
              id="age"
              name="age"
              value={pageForm.values.age}
              onChange={pageForm.handleChange}
              
            />
            {getFormErrorMessage("age")}
              </div>

              <div className="field col-two">
              <div>
              <label htmlFor="gender">جنسیت </label>
            </div>

            <Dropdown
                    id="gender"
                    name="gender"
                    onChange={pageForm.handleChange}
                    value={pageForm.values.gender}
                    options={horseGender}
                    

                  />
        
            {getFormErrorMessage("gender")}
              </div>
            </div>

            <div className="two-columns">
              <div className="field col-one">
              <div>
              <label htmlFor="mother">نام مادر</label>
            </div>
            <HorseDropdown 
            className={classNames({
              "p-invalid": isFormFieldValid("mother"),
            })}
            value={horseMother}
            onChange={handleHorseMotherChange}></HorseDropdown>
            {getFormErrorMessage("mother")}
              </div>

              <div className="field col-two">
              <div>
              <label htmlFor="father">نام پدر </label>
            </div>
            <HorseDropdown 
             className={classNames({
              "p-invalid": isFormFieldValid("father"),
            })}
            value={horseFother}
            onChange={handleHorseFotherChange}></HorseDropdown>
            {getFormErrorMessage("father")}
              </div>
            </div>


            <div className="two-columns">
              <div className="field col-one">
              <div>
              <label htmlFor="race">نژاد</label>
            </div>
            <InputText
            className={classNames({
              "p-invalid": isFormFieldValid("race"),
            })}
              id="race"
              name="race"
              value={pageForm.values.race}
              onChange={pageForm.handleChange}
              
            />
            {getFormErrorMessage("race")}
              </div>

              <div className="field col-two">
              <div>
              <label htmlFor="farm">باشگاه </label>
            </div>
            <InputText
            className={classNames({
              "p-invalid": isFormFieldValid("farm"),
            })}
              id="farm"
              name="farm"
              value={pageForm.values.farm}
              onChange={pageForm.handleChange}
              
            />
            {getFormErrorMessage("farm")}
              </div>
            </div>

            <div className="field">
            <div>
              <label htmlFor="activity">زمینه فعالیت </label>
            </div>
            <Dropdown
                    id="activity"
                    name="activity"
                    value={pageForm.values.activity}
                    onChange={pageForm.handleChange}
                    options={horseActivity}
                    

                  />
          
          </div>

            </div>
            : null }
            {/* **********************************************************************************/}

          <div className="field">
            <div>
              <label htmlFor="description">توضیح </label>
            </div>
            <div>
            <InputTextarea
              id="description"
              name="description"
              value={pageForm.values.description}
              onChange={pageForm.handleChange}
              placeholder={pageForm.values.description != null && pageForm.values.description != "" ? pageDescrption : null}
              
              
            />
            </div>
          </div>

          {props.type != "group" ?

          <div className="two-columns">
              <div className="field col-one">
                <span>
                  <label htmlFor="country">کشور</label>
                  <InputText
                    id="country"
                    name="country"
                    value={pageForm.values.country}
                    onChange={pageForm.handleChange}
                  
                    
                  />
                </span>
              </div>

              <div className="field col-two">
                <span>
                  <label htmlFor="city">شهر</label>
                  <InputText
                    id="city"
                    name="city"
                    value={pageForm.values.city}
                    onChange={pageForm.handleChange}
                    
                    
                  />
                </span>
              </div>
            </div>

            :null }

  {/* for group ************************************************************************/}

  {props.type == "group" ?
  <div>

  <div className="field">
            <div>
              <label htmlFor="groupMode">نوع گروه</label>
            </div>
            <Dropdown
            className={classNames({
              "p-invalid": isFormFieldValid("groupMode")})}
              id="groupMode"
              name="groupMode"
              onChange={pageForm.handleChange}
              value={pageForm.values.groupMode}
              options={groupeTypes}
              placeholder="انتخاب کنید"
              
            />
            {getFormErrorMessage("type")}
          </div>
          <div id="create-group-dialog">
          <div  style={{display:"inline flow-root list-item", textAlign:"justify",marginTop:"15px", fontSize:"14px"}}>
           <strong>گروه های عمومی </strong>
             در نتایج جستجو یافت می شوند و و اعضای آن می توانند افراد مورد علاقه خود را به گروه دعوت کنند. همچنین همه افراد به پست ها و اعضا دسترسی دارند.
          </div>
          <div style={{display:"inline flow-root list-item", textAlign:"justify",marginTop:"10px", fontSize:"14px"}}>
          <strong>گروه های خصوصی </strong>
            
                در نتایج جستجو یافت می شوند ولی پست های گروه فقط برای اعضا قابل مشاهده است. کاربران برای عضویت در گروه باید درخواست ارسال کنند و پس از تایید مدیر گروه عضو خواهند شد.
          </div>
          </div>
</div>
:null}
  {/*************************************************************************************/}



            <div className="two-columns">
              <div className="field col-one">
                <span>
                  <Button type="submit" label="ذخیره" className="mt-2" />
                </span>
              </div>

              <div className="field col-two">
                <span>
                  <Button
                    className="outline-button mt-2"
                    type="submit"
                    label="انصراف"
                    onClick={() => props.close()}
                  />
                </span>
              </div>
            </div>
          </form>
        </div>
    );
    
}

export default EditPageForm;