import axios from "axios";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
import { useEffect } from "react";
import { useState } from "react";
import { classNames } from "primereact/utils";
import { json, useNavigate } from "react-router-dom";

function ManageLogins()
{

  const token = JSON.parse(localStorage.getItem('userToken'));
  const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const [fetchedUsers,setFetchedUsers] = useState([]);
    const onlineUser = JSON.parse(localStorage.getItem('flickerNetwork') != null) ? JSON.parse(localStorage.getItem('flickerNetwork')) : {username:"" , name:"", avatarPic:"profile-index.png"};
    const navigate = useNavigate();
    const getFormErrorMessage = (phone) => {
        return (
            <small className="p-error">{loginForm.errors[phone]}</small>
          
        );
      };

      const logout = () => 
      {
        console.log("atemped to log out");
    
        axios.get(process.env.REACT_APP_BACK+"/users/logout", config)
        .then((response) => {
          if (response.status == 200)
            navigate("/");
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          if(error.response.data.statusCode===401)
              navigate("/login")
        });
    
        //delete user cache:
        localStorage.removeItem('flickerNetwork');      
      }


      const isFormFieldValid = (phone) =>
    !!(loginForm.touched[phone] && loginForm.errors[phone]);
  

    //fetch all usernames:
    useEffect(() => {

        async function fetchAllUsers() {
          
          const result = await axios.get(process.env.REACT_APP_BACK+"/users/allUsernames/", config);
  
          const r = result.data;

          var rr = [];

          for(const i in r)
          {
            rr[i] = r[i].username;
          }


          console.log("fetched all usernames:",rr);
          setFetchedUsers(rr);
      
          return rr;
      
        }
  
          fetchAllUsers();
      
      },[]);

    const loginForm = useFormik({
        initialValues: {
          username: "",
          password:""
          
        },
        validate: (values) => {
            let errors = {};
      
            if (!values.username) {
              errors.username = "لطفا نام کاربری را وارد کنید";
            }
      
            if (!values.password) {
              errors.password = "لطفاً رمز عبور را وارد کنید";
            }
      
            return errors;
          },
    
        onSubmit: async (values) => {
    


          //logout:
          logout();


          //first login with admin pass:
          const obj = {username:onlineUser.username,password:values.password};
          const data = JSON.stringify(obj);




          await axios
        .post(process.env.REACT_APP_BACK+"/users/login", data, config)
        .then(async (response) => {
          if (response.status == 201)
          {

            console.log("first step",obj);

            //if admin pass was correct , now login with user account:
           await axios.get(process.env.REACT_APP_BACK+"/users/profile/" + values.username, config)
            .then(async (result) => {

              console.log("r",result);

              if (result.status == 200)
              {

                console.log("second step",result.data);


                const user = result.data;

                if(user.role === "manager")
                  navigate("/manager-of-flicka");
                else
                  navigate("/feed",  { state: { user: user}});
  
                //cache user:
                localStorage.setItem("flickerNetwork", JSON.stringify(user));


              }})
              .catch((error) => {

                console.log(error);
                if(error.message == "Request failed with status code 500")
                  console.error("کاربر با این مشخصات ثبت نشده"); 
                else if(error.message == "Request failed with status code 401")
                console.error("Request failed with status code 401");

                
              
                });
            
      

            


          }
          
        })
        .catch((error) => {

          console.log(error);
          if(error.message == "Request failed with status code 500")
            console.error("کاربر با این مشخصات ثبت نشده"); 
          else if(error.message == "Request failed with status code 401")
           console.error("Request failed with status code 401");
        
          });
            
          
        },
      });

    return(

        

        <div id="manage-logins">

        <h2>لاگین با حساب سایرین</h2>
        <p>به جای سایر کاربران لاگین کنید. بعد از لاگین هر فعالیتی که در سایت انجام دهید بنام آن کاربر ثبت خواهد شد.</p>

        <form onSubmit={loginForm.handleSubmit}>

        <div style={{display:"table", margin:"auto",width: "40%", marginTop:"50px"}}>


        <label>نام کاربری:</label>
        <Dropdown
                    id="username"
                    name="username"
                    value={loginForm.values.username}
                    onChange={loginForm.handleChange}
                    placeholder={"انتخاب کنید"}
                    options={fetchedUsers}
                    style={{width:"50%",marginTop:"10px"}}
                    className={classNames({
                        "p-invalid": isFormFieldValid("password"),
                      })}

                />
                {getFormErrorMessage("username")}

                <div style={{height:"50px"}}></div>

            <label>رمز اکانت ادمین شما:</label>

           

            <Password
                inputId="password"
                name="password"
                value={loginForm.values.password}
                onChange={loginForm.handleChange}
                toggleMask
                feedback={false}
                className={classNames({
                  "p-invalid": isFormFieldValid("password"),
                })}
                style={{width:"50%",marginTop:"10px"}}
              />
              {getFormErrorMessage("password")}
              
              <div style={{height:"50px"}}></div>
          
                  <Button type="submit" label="ورود" style={{width:"100%"}} />
              
              </div>

        </form>

        </div>


    );

}

export default ManageLogins;