import { useState } from "react";
import "../Css/NewPage.css";

function NewPage(props) {

    const [detailedPageDialog, setDetailedPageDialog] = useState(false);

  return (
    <div id="new-page">

        

      <p>
        برای اسب ، باشگاه یا فروشگاه خود یک صفحه بسازید و آن را به بقیه معرفی
        کنید
      </p>
      <p>برای شروع، نوع صفحه خود را مشخص کنید</p>

      <div id="page-types">
        <div id="page-type-col1" onClick={()=>{setDetailedPageDialog(true); props.close("horse")}}>
          <div>
            <img src={require("../images/intro1.png")}></img>
          </div>
          <div>
            <h6>اسب</h6>
          </div>
          <div>
            <p>برای معرفی اسب خودتان یا اسبی سرشناس</p>
          </div>
        </div>

        

        <div id="page-type-col2" onClick={()=>{setDetailedPageDialog(true); props.close("event")}}>
          <div>
            <img src={require("../images/intro5.png")}></img>
          </div>
          <div>
            <h6>رویداد</h6>
          </div>
          <div>
            <p>
              برای معرفی یک رویداد یا مسابقه 
            </p>
          </div>
        </div>
        <div id="page-type-col3" onClick={()=>{setDetailedPageDialog(true);  props.close("shop")}}>
          <div>
            <img src={require("../images/intro6.png")}></img>
          </div>
          <div>
            <h6>فروشگاه</h6>
          </div>
          <div>
            <p>
              برای معرفی یک فروشگاه
            </p>
          </div>
        </div>
</div>

        <div id="page-types">
        <div id="page-type-col1">
         
            
        </div>

        <div id="page-type-col2" onClick={()=>{setDetailedPageDialog(true);  props.close("farm")}}>
          <div>
            <img src={require("../images/intro4.png")}></img>
          </div>
          <div>
            <h6>باشگاه یا مزرعه پرورش اسب</h6>
          </div>
          <div>
            <p>
              برای معرفی باشگاه یا مرزعه
            </p>
          </div>
        </div>
        <div id="page-type-col3">
        
        </div>



      </div>
    </div>
    
  );
}

export default NewPage;
