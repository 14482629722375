import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Css/HomePage.css";
import Post from "./Post";

function AllPosts()
{
    const navigate = useNavigate();
    const [fetchedPosts, setFetchedPosts] = useState([]);
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const videoRef = useRef(null);
    const [hasPlayed, setHasPlayed] = useState(false);
    const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
    const [offset,setOffset] = useState(10);

 

  const  loadMore = async () =>
  {
          
        //fetch next:
          
        let count = offset+10;
        console.log("count",count);
          await axios.get(process.env.REACT_APP_BACK+"/posts/public/" + count, config)
          .then((result) => {
            if (result.status == 200) {
                  
              setFetchedPosts(prevArray => [...prevArray, ...result.data]);
              setOffset(count);
              console.log("fetched posts",result.data);
              
              return result.data;
            }
          })
          .catch((error) => {
            console.log(error);
            if(error.response.data.statusCode===401)
            navigate("/login")
          });
            
        

  }

    //go to top of the page:
    useEffect(() => {
      window.scrollTo(0, 0)
    }, []);

    //fetching public posts:
   useEffect(() => {
    async function fetchPosts() {
    
      
      //fetch first group:
        axios
          .get(process.env.REACT_APP_BACK+"/posts/public/" + offset, config)
          .then((result) => {
            if (result.status == 200) {
              setFetchedPosts(result.data);
              console.log("fetched public posts: ", result.data);

              return result.data;
            }
          })
          .catch((error) => {
            console.log(error);
            if(error.response.data.statusCode===401)
                  navigate("/login")
          });


          
      
    }

    fetchPosts();
  }, []);




    return (

        <div id="posts-section" style={{marginTop:"0px"}}>

            <h2>جدیدترین پست های فلیکا</h2>

            <table>
              <tbody>
            <tr>

              

            {fetchedPosts.map((post, index) => (
            
            post.type != "group" ? 

            <td className="post-item" 
            key={index}
            
            onClick={() => {
              navigate("/posts/"+post._id,{ state: {isPublic: true} });
              }}>

            <Post
            creatorPage={post.creatorPage}
            creatorUsername={post.creator!==undefined ? post.creator.username : post.creatorPage.username}
            creator_id={post.creator!==undefined ? post.creator._id : post.creatorPage._id}
            creatorName={post.creator!==undefined ? post.creator.name : post.creatorPage.name}
            creatorAvatar={post.creator!==undefined ? post.creator.avatarPic : post.creatorPage.avatarPic}
            creatorJob={post.creator!==undefined ? post.creator.job : post.creatorPage.job}
            file={post.file}
            fileType={post.fileType}
            category={post.category}
            tags={post.tags}
            date={post.date}
            description={post.description}
            likesCount={post.likesCount}
            commentsCount={post.commentsCount}
            postOfOther={true}
            _id={post._id}
        
          />



                 {/* { page.fileType === "image" ? <img className="pi pi-images" src={`${process.env.REACT_APP_BACK}/posts/${page.file}`}></img>
                : <video 
                src={`${process.env.REACT_APP_BACK}/posts/${page.file}`}
                controls
                width="100%"
                height="100%"
                ref={videoRef}
                onLoadedMetadata={handleSeeked}
                onPlay={handlePlay}>
    
                </video>}
               { page.description ?  <p className="subtitle">{page.description}</p> : null} */}
            </td>
            
            : null

          ))}

          </tr>

         
          </tbody>
          </table>

          <div>
          <button id="load-more-btn"
          onClick={loadMore}
          > بیشتر ...</button>
          </div>


        </div>
    );
}

export default AllPosts;