import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Css/HomePage.css";
import Post from "./Post";

function AllHorseProducts()
{
    const navigate = useNavigate();
    const [fetchedHorseProduct, setFetchedHorseProducts] = useState([]);
    const config = { headers: { "Content-Type": "application/json" } };
    const videoRef = useRef(null);
    const [hasPlayed, setHasPlayed] = useState(false);
    const [lazyLoading, setLazyLoading] = useState(false);
    const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
    const [offset,setOffset]=useState(10);



    //go to top of the page:
    useEffect(() => {
      window.scrollTo(0, 0)
    }, []);

    //fetching horse products:
   useEffect(() => {

    async function fetchPosts() {

      
        await axios.get(process.env.REACT_APP_BACK+"/posts/products/horse/"+offset, config)
          .then((result) => {
            if (result.status == 200) {

              setFetchedHorseProducts(prevArray => [...prevArray, ...result.data]);
              console.log("fetched horse products: ", result.data);

              setLazyLoading(false);

              return result.data;
            }
          })
          .catch((error) => {
            console.log(error);
            if(error.response.data.statusCode===401)
                  navigate("/login")
          });


        
      
    }

    fetchPosts();

  }, [offset]);


    return (

            <div style={{marginTop:"0px",paddingTop:"100px"}} >

            <h2 >لوازم اسب</h2>

            <table>
              <tbody>
            <tr>

            {fetchedHorseProduct.map((post, index) => (
            
            post.type != "group" ? 

            <td className="post-item" 
            key={index}
            
            onClick={() => {
              navigate("/posts/"+post._id,{ state: {isPublic: true} });
              }}>

            <Post
            creatorPage={post.creatorPage}
            creatorUsername={post.creator!==undefined ? post.creator.username : post.creatorPage.username}
            creator_id={post.creator!==undefined ? post.creator._id : post.creatorPage._id}
            creatorName={post.creator!==undefined ? post.creator.name : post.creatorPage.name}
            creatorAvatar={post.creator!==undefined ? post.creator.avatarPic : post.creatorPage.avatarPic}
            creatorJob={post.creator!==undefined ? post.creator.job : post.creatorPage.job}
            file={post.file}
            fileType={post.fileType}
            category={post.category}
            tags={post.tags}
            date={post.date}
            description={post.description}
            likesCount={post.likesCount}
            commentsCount={post.commentsCount}
            postOfOther={true}
            _id={post._id}
        
          />


            </td>
            
            : null

          ))}

          </tr>
          </tbody>
          </table>

          <div>
        
        <button 
        style={{marginTop:"50px",direction:"rtl"}} 
        className="login-button"
        onClick={()=>
        {
          setOffset(offset+10);
        }
        }
        >
          بیشتر ...</button>

            

      </div>


        </div>
    );
}

export default AllHorseProducts;