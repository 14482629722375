import React, { createRef, useState } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { classNames } from "primereact/utils";
import "../Css/Register.css";
import { CountryService } from "../services/CountryService";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { InputMask } from 'primereact/inputmask';
import axios from "axios";
import * as Yup from 'yup';
import { Toast } from 'primereact/toast';


function Register() {
  
  const [showMessage, setShowMessage] = useState(false);
  const [step,setStep] = useState(1);
  const navigate = useNavigate();
  const [user,setUser] = useState();
  const [verificationCode,setVerificationCode] = useState();
  const [registerData,setregisterData] = useState();
  const toast = createRef();
  const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
 

  const vCodeForm = useFormik({
    initialValues: {
      vCode: "",
    },
    validate: (values) => 
    {
      
      let errors={};

      if(!values.vCode)
      {
        errors.vCode = "لطفاً کد تایید را وارد کنید";
      }
      
      return errors;


      

   },
    onSubmit: (values) => {


      //check if verification code is the same:
      if(values.vCode == verificationCode)
      {

        setStep(3);
        setShowMessage(true);

        //creating user in database
        const config = {     
          headers: { 
            'Content-Type': 'application/json'
        }
      }
      axios.post(process.env.REACT_APP_BACK+"/users/register", registerData, config)
          .then(response => 
            {
              if (response.status == 201)
              {
                console.log(response);
                setUser({_id:response.data._id , username: response.data.username, name: response.data.name , avatarPic: response.data.avatarPic ,coverPic: response.data.coverPic });
               
              }
          })
          .catch(error => {
             console.log(error);
             if(error.response.data.statusCode===401)
              navigate("/login")
          });
      }
      else
      {

        toast.current.show({
          severity: "Error",
          detail: "کد صحیح نبود. لطفا مجدداً تلاش کنید",
        });
        setStep(1);
      }


    
    
   
        
      
    },
  });

  const formik = useFormik({
    
    initialValues: {
      
      username: "",
      name: "",
      email: "",
      phone:"",
      password: "",
      notIran:false,
      accept:false
     
    },

    validate: async (values) => {
      
      let errors = {};

      if (!values.password) {
        errors.password = "رمز عبور الزامی است";
      }
      if (!values.accept) {
        errors.accept = "باید با قوانین و مقررات سایت موافقت کنید";
      }
      if(!validateEmail(values.email))
      {
        errors.email = "فرمت ایمیل اشتباه است";
      }
      if (!values.email) {
        errors.email = "ایمیل الزامی است";
      }
      if(!(await uniquePhone(values.phone)))
      {
        errors.phone  = "این شماره موبایل قبلا ثبت شده";
      }
      if (!values.phone) {
        errors.phone = "شماره موبایل  الزامی است";
      }
     
      if (!values.name) {
        errors.name = "نام و نام خانوادگی الزامی است";
      }
      if( !( await uniqueUsername(values.username)))
      {

        errors.username  = "این نام کاربری قبلا ثبت شده";
      }
      if (!values.username) {
        errors.username = "نام کاربری الزامی است";
      }
      

      
      return errors;
    },
    
    onSubmit: (values) => {
      

      values.username = values.username.toString().trim();
      values.username = values.username.toLowerCase();
      values.username = values.username.replace(/\s+/g,"_");
      const data = JSON.stringify({username: values.username, role:"subscriber" , name:values.name.trim() ,email:values.email.trim(),phone:values.phone.replace(/[^0-9]+/g, ""),password: values.password});
      setregisterData(data);

      //send verification code by sms***********************************************************************************************

      if(!values.notIran)
      {
      const phone = values.phone.replace(/[^0-9]+/g, "");
      const vCode = Math.floor(Math.random() * 9000) + 1000
      setVerificationCode(vCode);

          var d = JSON.stringify({
            "code": "z7c0f80uhzni7qw",
            "sender": "+983000505",
            "recipient": phone,
            "variable": {
              "verification-code": vCode
            }
          });


          var config = {
            method: 'post',
            url: 'https://api2.ippanel.com/api/v1/sms/pattern/normal/send',
            headers: { 
              'apikey': 'bv1PxCuaPVZUcIPOSqgmx7u9w8nzdBk1Boj_vnwvo48=', 
              'Content-Type': 'application/json'
            },
            data : d
          };

          axios(config)
          .then(function (response) {

            console.log("response",JSON.stringify(response.data));
            console.log("status",response.status);
            
            if(response.status === 200)
            {
              setStep(2);
            }
            
          })
          .catch(function (error) {
            console.log("error",error);
            if(error.response.data.statusCode===401)
              navigate("/login")
          });
//******************************************************************************************************************************* */

        }
        else
        {
          setStep(3);
          setShowMessage(true);

        //creating user in database
        const config = {     
          headers: { 
            'Content-Type': 'application/json'
        }
      }
      axios.post(process.env.REACT_APP_BACK+"/users/register", data, config)
          .then(response => 
            {
              if (response.status == 201)
              {
                console.log(response);
                setUser({_id:response.data._id , username: response.data.username, name: response.data.name , avatarPic: response.data.avatarPic ,coverPic: response.data.coverPic });
               
              }
          })
          .catch(error => {
             console.log(error);
             if(error.response.data.statusCode===401)
              navigate("/login")
          });
          
        }       

      
      
      

     
    },
  });

  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button
        label="خُب"
        className="p-button-text"
        autoFocus
        onClick={() => setShowMessage(false)}
      />
    </div>
  );

  const isFormFieldValid = (phone) =>
    !!(formik.touched[phone] && formik.errors[phone]);
  const getFormErrorMessage = (phone) => {
    return (
      isFormFieldValid(phone) && (
        <small className="p-error">{formik.errors[phone]}</small>
      )
    );
  };


  //check if phone is unique:
  const uniquePhone = (value) =>
 {
      const config = {     
        headers: { 'Content-Type': 'application/json' }
    }
        return new Promise((resolve, reject) => {
          

            axios.get(process.env.REACT_APP_BACK+"/users/check-phone/"+value.replace(/[^0-9]+/g, ""), config)
                .then((res) => {
                  if(res.data==="")
                    resolve(true)
                    else
                    resolve(false)
                })
                .catch((error) => {
                        resolve(false);
                        if(error.response.data.statusCode===401)
                            navigate("/login")
                    
                })
        })
    
  }
      const uniqueUsername =  async (value) =>
      {
          const config = {headers: { 'Content-Type': 'application/json' }};

          var result;
          await axios.get(process.env.REACT_APP_BACK+"/users/check-username/"+value, config)
                  .then((res) => {
                 
                    if(res.data)
                      result = true;
                      else
                      result = false;
                  })
                  .catch((error) => {
                      result = false;
                      if(error.response.data.statusCode===401)
                        navigate("/login")
                      
                  })
                  return result;
        
      }
      const validateEmail = (email) => {
        const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return pattern.test(email);
      };
     

      

  const passwordHeader = <h6>یک رمز عبور انتخاب کنید</h6>;
  const passwordFooter = (
    <React.Fragment>
      <Divider />
      <p className="mt-2">پیشنهادات</p>
      <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
        <li>حداقل یک کاراکتر انگلیسی کوچک</li>
        <li>حداقل یک کاراکتر انگلیسی بزرگ</li>
        <li>حداقل یک عدد</li>
        <li>حداقل یک رمز 8 کاراکتری</li>
      </ul>
    </React.Fragment>
  );

  return (


    
    
      <div className="form-demo register-form">

            <Toast ref={toast } style={{background:"red", color:"#ffffff", zIndex:"10000", margin:"0px"}} life="3000" position="center center"/>


            <Dialog 
            header=""
            visible={step == 2}
            onHide={() => setStep(1)}
            id="verification-code"
            style={{width: desktop ? "30%" : "80%"}}
          >
            <div>
              <img className="reg-image" src={require("../images/reg1.png")}></img>
           <p>کد تایید به شماره</p>
            <p>{formik.values.phone}</p>
           <p>ارسال شد</p>
           
           <form onSubmit={vCodeForm.handleSubmit} className="p-fluid">
           
           <div style={{marginTop:"40px",direction:"rtl",textAlign:"right"}}><label>کد تایید:</label></div>
           
           <div style={{marginBottom:"10px"}}>
            <InputText 
            name="vCode" 
            id="vCode"
            value={vCodeForm.values.username}
            onChange={vCodeForm.handleChange} 
            label="ویرایش" 
            style={{borderColor:"#404040b8",borderRadius:"30px"}} 
            className={classNames({
              "p-invalid": vCodeForm.errors["vCode"],
            })}
            />
             
            </div>
            <small className="p-error">{vCodeForm.errors["vCode"]}</small>
            
           
           <div style={{marginBottom:"40px"}}><Button type="submit"  className="filled-button" label="ادامه"  style={{width:"100%"}} /></div>
           
           </form>

            </div>
          </Dialog>




         <Dialog
          visible={step == 3}
          onHide={() => setStep(0)}
          position="top"
          showHeader={false}
          breakpoints={{ "960px": "80vw" }}
          style={{ width: "30vw" }}
        >
          <div className="flex align-items-center flex-column pt-6 px-3">
            <i
              className="pi pi-check-circle"
              style={{ fontSize: "5rem", color: "var(--green-500)" }}
            ></i>
            <h4>!ثبت نام موفقیت آمیز بود</h4>
            <p style={{ lineHeight: 1.5, textIndent: "1rem" }}>
              به فلیکا خوش آمدید
            </p>
            <p style={{ lineHeight: 1.5, textIndent: "1rem" }}>
              لطفاً در پنل کاربری اطلاعات تکمیلی خودتان را وارد کنید
            </p>

            <div><Button  label="بزن بریم" className="p-button mt-2 filled-button" onClick={()=>{
              setStep(0); 
              navigate("/"+user.username+"/about");
              
              //cache user:
              console.log("user:",user);
              localStorage.setItem("flickerNetwork", JSON.stringify(user));

              }}/></div>

          </div>
        </Dialog> 

        <div>
          <div>
            <h2>ثبت نام</h2>
            {/* <p className="text-center">
              لطفا اطلاعات خود را در فرم زیر وارد کنید
            </p> */}
            <form onSubmit={formik.handleSubmit} className="p-fluid">

            
            <div className="field">
                <span>
                  <label
                    htmlFor="name"
                    className={classNames({
                      "p-error": isFormFieldValid("name"),
                    })}
                  >
                    نام‌کاربری (به‌انگلیسی)* 
                  </label>
                  <InputText
                    id="username"
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    className={classNames({
                      "p-invalid": isFormFieldValid("username"),
                    })}
                    maxLength={25} 
                  />
                </span>
                {getFormErrorMessage("username")}
              </div>

              <div className="field">
                <span>
                  <label
                    htmlFor="name"
                    className={classNames({
                      "p-error": isFormFieldValid("name"),
                    })}
                  >
                    نام و نام خانوادگی*
                  </label>
                  <InputText
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    className={classNames({
                      "p-invalid": isFormFieldValid("name"),
                    })}
                    maxLength={25} 
                  />
                </span>
                {getFormErrorMessage("name")}
              </div>

              

            <div className="field">
                <label
                  htmlFor="email"
                  className={classNames({
                    "p-error": isFormFieldValid("email"),
                  })}
                >
                  ایمیل *
                </label>

                <span className=" p-input-icon-left">
                  <i className="pi pi-envelope" />
                  <InputText
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    className={classNames({
                      "p-invalid": isFormFieldValid("email"),
                    })}
                    maxLength={40} 
                  />
                </span>
                {getFormErrorMessage("email")}
              </div>

              <div className="field">
                <span>
                  <label
                    htmlFor="phone"
                    className={classNames({
                      "p-error": isFormFieldValid("phone"),
                    })}
                  >
                    شماره موبایل*
                  </label>
                  <InputMask 
              id="phone"
              name="phone"
              mask="(0999) 9999999" 
              value={formik.values.phone}
              onChange={formik.handleChange}
              style={{ direction: "ltr", textAlign: "left" }}
              placeholder="(0919)9999999"
              className={classNames({
                "p-invalid": isFormFieldValid("phone"),
              })}
              ></InputMask>
                 
                </span>
                {getFormErrorMessage("phone")}
              </div>
              <div className="field-checkbox" style={{marginTop:"-10px", marginBottom:"40px"}}>
                
                <Checkbox
                  id="notIran"
                  name="notIran"
                  checked={formik.values.notIran}
                  onChange={formik.handleChange}
                />
                <label>
                  از خارج از ایران ثبت نام می کنم
                </label>
                
                
             
              </div>


              
              <div className="field">
                <span>
                  <label
                    htmlFor="password"
                    className={classNames({
                      "p-error": isFormFieldValid("password"),
                    })}
                  >
                    رمز عبور*
                  </label>
                  <Password
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    promptLabel="لطفاً یک رمز عبور انتخاب کنید"
                    strongLabel="قوی"
                    mediumLabel="متوسط"
                    weakLabel="ضعیف"
                    toggleMask
                    className={classNames({
                      "p-invalid": isFormFieldValid("password"),
                    })}
                    header={passwordHeader}
                    footer={passwordFooter}
                    panelStyle={{direction:"rtl"}}
                  />
                </span>
                {getFormErrorMessage("password")}
              </div>



              <div className="field-checkbox">
                
                <Checkbox
                  id="accept"
                  name="accept"
                  checked={formik.values.accept}
                  onChange={formik.handleChange}
                  
                  className={classNames({
                    "p-invalid": isFormFieldValid("accept"),
                  })}
                />
                <label
                  htmlFor="accept"
                  className={classNames({
                    "p-error": isFormFieldValid("accept"),
                  })}
                >
                  من با قوانین و مقررات سایت موافقم*
                </label>
                
                
                
              </div>
              <div>{getFormErrorMessage("accept")}</div>

              <Button type="submit" label="ادامه" className="mt-2 filled-button" />
              

            </form>
          </div>
        </div>
      </div>
    
  );
}

export default Register;
