import { Image } from 'primereact/image';
import { ContextMenu } from 'primereact/contextmenu';
import "../Css/Profile.css";
import { useLongPress } from 'use-long-press';
import { useRef } from 'react';
import DoneAllIcon from '@material-ui/icons/DoneAllOutlined';
import DoneIcon from '@material-ui/icons/Done';

function Message(props)
{
    const onlineUser = JSON.parse(localStorage.getItem('flickerNetwork') != null) ? JSON.parse(localStorage.getItem('flickerNetwork')) : {username:"" , name:"", avatarPic:"profile-index.png"};
    const cm1 = useRef();
    const cm2 = useRef();

    //check if the message is only one emoji?
    function containsOnlyOneSticker(str) {
      const emojiRegex = /^(\p{Emoji}\uFE0F?)+$/u;
      return emojiRegex.test(str);
    }

    const bind = useLongPress((e) => {
  
      if(props.message.sender_id._id == onlineUser._id)
          cm1.current.show(e);
      else
          cm2.current.show(e);
      
      
    });


    const contextMenuOptions1 = [
      {
        label: 'پاسخ',
        icon: 'pi pi-reply',
        command: (event) => {
          var content=""
          if(props.message.messageType !=="image" && props.message.messageType !=="video" )
              props.message.content.length>30  ? content = props.message.content.slice(0,30) + " ... " : content = props.message.content;
          
          props.replyClicked(content);
          
        }
      }
  ,
      {
        label: 'حذف',
        icon: 'pi pi-trash',
        command: (event) => {
          
          props.deleteMessage(props.message._id, props.index);
        }
      }
      
    ];
  
    const contextMenuOptions2 = [
      {
        label: 'پاسخ',
        icon: 'pi pi-reply',
        command: (event) => {

          var content=""
          if(props.message.messageType !=="image" && props.message.messageType !=="video" )
              props.message.content.length>30  ? content = props.message.content.slice(0,30) + " ... " : content = props.message.content;
          
          props.replyClicked(content);
          
        }
      }
      
    ];


    return(

        <div {... bind()} >

            <ContextMenu  ref={cm1} model={contextMenuOptions1}/> 
            <ContextMenu  ref={cm2} model={contextMenuOptions2}/> 



            
          
            <p  id="chat-date" style={{textAlign:"center"}}>

            {props.index !== props.messages.length-1 && 
              new Date(props.message.date).toLocaleString('fa-IR', { year: 'numeric', month: 'numeric', day: 'numeric'}) !== new Date(props.messages[props.index+1].date).toLocaleString('fa-IR', { year: 'numeric', month: 'numeric', day: 'numeric'})

            ? 
            new Date(props.message.date).toLocaleString('fa-IR', { year: 'numeric', month: 'numeric', day: 'numeric' })
            
            : null}

            {props.index==props.messages.length-1 ? new Date(props.message.date).toLocaleString('fa-IR', { year: 'numeric', month: 'numeric', day: 'numeric'}) : null}

            </p>


             {/* reply banner */}

             <div style={{display:!props.message.reply_to_message ? "none" : null,marginBottom:"10px"}}>           
              
              <div id="reply-banner">
                  <span className="row1" style={{display:"flex",direction:"rtl",alignItems:"center"}}>

                    {/* <i className="pi pi pi-reply" 
                    style={{display:"flex",height:"10%",marginLeft:"10px", fontSize:"12px",cursor:"pointer",color:"#af734f",padding: "5px",borderRadius: "50%"}} 
                    ></i> */}
                  <span style={{color:"#49505796",fontSize:"13px"}}>{props.message.reply_to_message}</span>
                    
                  </span>

                </div>
                <div className="chat-arrow" style={{marginLeft: props.message.sender_id._id == onlineUser._id ? "80%" : "20%"}}></div>
                </div>
            
          

       <span key={props.index}          
       style={{display:"flex", direction: props.message.sender_id._id == onlineUser._id ? "rtl": "ltr", margin:"10px 0px 10px 0px",alignItems: "center",userSelect:"none"}}
       >

        
       <span className="message-col1">

       
      
       <img src={`${process.env.REACT_APP_BACK}/avatars/${props.message.sender_id.avatarPic}`}></img>
 
       </span>
       <span className="message-col2" style={{fontSize:containsOnlyOneSticker(props.message.content) ? "40px" : null }}> 
       {props.message.messageType!=="image" && props.message.messageType!=="video" ?props.message.content:null}
       {props.message.messageType==="image"?<Image preview className="uploadedMedia" src={`${process.env.REACT_APP_BACK}/messages/${props.message.content}`}></Image>:null}
       {props.message.messageType==="video"?<video className="uploadedMedia" controls preload="metadata"
          poster=""  src={`${process.env.REACT_APP_BACK}/messages/${props.message.content}`}></video>:null}
       
       </span>

       
    

       { props.message.sender_id._id == onlineUser._id ? <span></span> : null }
       
       </span>


       <span style={{display:"flex",direction: props.message.sender_id._id == onlineUser._id ? "rtl": "ltr"}}>

        {/* seen: */}
        {props.message.seen ? 
        
        <DoneAllIcon  style={{marginLeft:"5px",marginRight:"5px",fontSize:"15px"}}></DoneAllIcon>
        
        
        : 
        
        <DoneIcon  style={{marginLeft:"5px",marginRight:"5px",fontSize:"15px"}}></DoneIcon>}

       {/* time */}
       <span style={{fontSize:"12px",display:"flex"}}>
        
        {props.index !== 0 && 
              new Date(props.message.date).toLocaleString('fa-IR', { hour: 'numeric', minute: 'numeric'}) !== new Date(props.messages[props.index-1].date).toLocaleString('fa-IR', { hour: 'numeric', minute: 'numeric'})
        ?
        new Date(props.message.date).toLocaleString('fa-IR', {  hour: 'numeric', minute: 'numeric' })
      : null
      }

      {props.index==0 ? new Date(props.message.date).toLocaleString('fa-IR', { hour: 'numeric', minute: 'numeric'}) : null}
        </span>
        
        </span>

        </div>

    );

}

export default Message;