import { Avatar,Slider } from "@material-ui/core";
import { useState} from "react";
import AvatarEditor from "react-avatar-editor";
import "../Css/CropCoverPic.css";
import { Button } from "primereact/button";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CropCoverPic = (props) => {
  var editor = "";
  const [picture, setPicture] = useState({cropperOpen: false,img: null,zoom: 1, 
    croppedImg:process.env.REACT_APP_BACK+`/covers/${props.coverPic}`});
  const [hideAvatar,setHideAvatar] = useState(false);
  const [done,setDone] = useState(false);
  const [uploadedFile,setUploadedFile] = useState();
  const [filename,setFilename] = useState();
  const navigate = useNavigate();

  const handleSlider = (event, value) => {
    setPicture({
      ...picture,
      zoom: value
    });
  };

  const handleCancel = () => {
    setPicture({
      ...picture,
      cropperOpen: false
    });
    setHideAvatar(false);
    setDone(true);
  };

  const setEditorRef = (ed) => {
    editor = ed;
  };

  const handleSave = (e) => {
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();

      setPicture({
        ...picture,
        img: null,
        cropperOpen: false,
        croppedImg: croppedImg
      });

      setHideAvatar(false);
      setDone(true);

      //convert croped image to file:

      
      urltoFile(croppedImg,filename)
      .then((file)=>{
        return setUploadedFile(file);
      });
    }
  };

  const handleFileChange = (e) => {
    let url = URL.createObjectURL(e.target.files[0]);
    console.log(url);
    setPicture({
      ...picture,
      img: url,
      cropperOpen: true
    });

    setHideAvatar(true);
    setFilename(e.target.files[0].name);
  };

  const handleDone = async () =>
  {

    //uploading file:
    let formData = new FormData();
    formData.append("file", uploadedFile);

    const c = {
      headers: {
        "Contetnt-Type": "multipart/form-data",
      },
    };

    let req;
    if(props.isPage)
    {
        req = process.env.REACT_APP_BACK+"/pages/uploadCover/";
    }
    else
    {
        req =process.env.REACT_APP_BACK+"/users/uploadCover/";
    }
    
    await axios
      .post(req+props.user_id, formData, c)
      .then((res) => {
        console.log("response",res);

        if(res.data.roset1)
          props.setShowBadge(true);
          
      }).catch((error) => 
      {
        if(error.response.data.statusCode===401)
                  navigate("/login")
      });

    props.close();
  }

  const urltoFile=(url, filename, mimeType)=>{
    mimeType = mimeType || (url.match(/^data:([^;]+);/)||'')[1];
    return (fetch(url)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){
          return new File([buf], filename, {type:mimeType});
        })
    );
  }


  return (
    
      <div id="crop-cover">
        <div style={{ display:hideAvatar?"none":"block" }}>
            <div id="show-avatar" >
          <Avatar
          className="avatar"
            src={picture.croppedImg}
            style={{ width: "100%", height: "auto", padding: "5", borderRadius:"0%"}}
          />
          </div>
          <h6 style={{marginTop:"20px"}}>تصویر کاور را آپلود کنید</h6>
          <button id="brows-button" onClick={()=>setDone(false)}>
            <input type="file" accept="image/*" onChange={handleFileChange} />
          </button>
        </div>

        {picture.cropperOpen && (
          <div id="edit-cover">
            <AvatarEditor
              ref={setEditorRef}
              image={picture.img}
              width={1000}
              height={400}
              border={50}
              color={[255, 255, 255, 0.6]} // RGBA
              rotate={0}
              scale={picture.zoom}
            />
            <Slider
              aria-label="raceSlider"
              value={picture.zoom}
              min={1}
              max={10}
              step={0.1}
              onChange={handleSlider}
            ></Slider>
            <div>
              <button variant="contained" onClick={handleCancel}>
                انصراف
              </button>
              <button onClick={handleSave} style={{background:"#af734f", color:"#ffffff"}}>ذخیره</button>
            </div>
          </div>
        )}

        <div id="final-save" style={{ display: done ? "block" : "none"}}>
            <Button onClick={handleDone}>همین خوبه!</Button>
        </div>
      </div>
    
  );
};

export default CropCoverPic;
