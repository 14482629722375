import Register from "../components/Register";
import "../Css/RegisterLogin.css";
import { TabView, TabPanel } from 'primereact/tabview';
import { useEffect, useState } from "react";
import Login from "../components/Login";



function RegisterLogin(props) {

    const [activeIndex, setActiveIndex] = useState(props.type);

  return (
    <div className="main-layout">
      <div className="main-layout-pic-wrap">
        <img src={require("../images/login.png")} />
      </div>
      <div className="main-layout-form-wrap">

      

<TabView className="tab-view" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>

<TabPanel  id="logintab" header="ورود">
    <Login setTheme={props.setTheme}  />
    </TabPanel>
    <TabPanel id="registertab" header="عضویت">
    <Register />
    </TabPanel>
    
</TabView>


        
      </div>
    </div>
  );
}

export default RegisterLogin;
