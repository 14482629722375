import "../Css/UsersCarousel.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function UsersCarousel() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const image1 = "user1.jpg";
  const image2 = "user2.jpg";
  const image3 = "user3.jpg";
  const image4 = "user4.jpg";

  const title1 = "سوارکاران";
  const title2 = "مربیان";
  const title3 = "فروشندگان لوازم اسب و سوار";
  const title4 = "مدیران باشگاه ها و مزارع";

  const subtitle1 =
    "شما هم عاشق سواری و اسب هستید؟ ما هم همین طور! اصلا فلیکا را هم برای همین ساختیم. شما هم به ما بپیوندید و برای خودتان پروفایل بسازید. از مهارت ها، جوایز و عناوین تان بنویسید و با سایر سوارکاران و مربیان هم آشنا شوید. در فلیکا می توانید با مزارع و باشگاه های مشهور ایران و دنیا هم آشنا شوید و با آخرین محصولات اسب سوار آشنا شوید و با همدیگر خرید و فروش کنید.";
  const subtitle2 =
  "اگر مربی سواری هستید، در فلیکا عضو شوید و برای خودتان یک پروفایل بسازید. می توانید تخصص خود ( مربی پرش ، درساژ یا سوارکاری مبتدی) را ذکر کنید و با سایر مربیان و شاگردان بیشتری آشنا شوید. شاگردانتان نیز می توانند یک گروه تخصصی داشته باشند و عکس ها و فیلم هایشان از جلسات تمرین ، دورهمی ها و مسابقات را با هم به اشتراک بگذارند.";
  const subtitle3 = 
  "به دنیای اسب و سوار علاقه دارید؟ احتمالاً شما هم با دیدن انواع محصولات سواری ذوق زده می شوید. در فلیکا می توانید بطور کاملاً رایگان ثبت نام کرده و محصولات خود را برای فروش بگذارید و یا محصولات سایر اعضا را خریداری کنید.";
  const subtitle4 =
   "اگر باشگاه یا مزرعه پرورش اسب دارید، می توانید در فلیکا عضو شوید و برای کسب و کار خود یک صفحه بسازید. جامعه سواری ایران و دنیا می توانند از این طریق با شما و امکانات مجموعه تان آشنا شوند و با شما ارتباط بگیرند. می توانید برای مزارع مشهور در سراسر دنیا نیز صفحه بسازید و از آخرین امکانات و تجربیات آن ها بگویید.";

  return (
    <div className="users-banner">
      <div className="posts">
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={false}
          showDots={true}
          arrows={false}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={4000}
          keyBoardControl={true}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div className="post-item">
            <div className="info">
              <h4>{title1}</h4>
              <p>{subtitle1}</p>
            </div>

            <div className="image">
              <img src={require("../images/" + image1)}></img>
            </div>
          </div>

          <div className="post-item">
            <div className="info">
              <h4>{title2}</h4>
              <p>{subtitle2}</p>
            </div>

            <div className="image">
              <img src={require("../images/" + image2)}></img>
            </div>
          </div>

          <div className="post-item">
            <div className="info">
              <h4>{title3}</h4>
              <p>{subtitle3}</p>
            </div>

            <div className="image">
              <img src={require("../images/" + image3)}></img>
            </div>
          </div>

          <div className="post-item">
            <div className="info">
              <h4>{title4}</h4>
              <p>{subtitle4}</p>
            </div>

            <div className="image">
              <img src={require("../images/" + image4)}></img>
            </div>
          </div>

        </Carousel>
      </div>
    </div>
  );
}

export default UsersCarousel;
