import "../Css/Intro.css";
import IntroItem from "./IntroItem";

function Intro() {
  return (
    <div>
      <div className="intro-container">
        <IntroItem
          className="intro-item"
          title="ساخت گروه"
          subtitle="
            برای مربی یا استادتان یک گروه بسازید و به بقیه از فعالیت های گروهی تان تعریف کنید
            "
          icon="intro3.png"
        />

        <IntroItem
          className="intro-item"
          title="اشتراک گذاری مطالب"
          subtitle="
            نوشته ها و عکس های خودتان و اسبتان را با بقیه به اشتراک بگذارید. مطالب سایر سواران را دنبال کنید
            "
          icon="intro2.png"
        />

        <IntroItem
          className="intro-item"
          title="ساخت پروفایل"
          subtitle="
            برای خودتان و اسبتان یک پروفایل جذاب بسازید و با سواران و اسبان بیشتری آشنا شوید
            "
          icon="intro1.png"
        />
      </div>

      <div className="intro-container">
        <IntroItem
          className="intro-item"
          title="خرید و فروش لوازم اسب و سوار"
          subtitle="
            اگر به دنبال لوازم خاصی برای خودتان یا اسبتان می گردید یا محصولی برای فروش دارید، فلیکا به شما کمک می کند
            "
          icon="intro6.png"
        />

        <IntroItem
          className="intro-item"
          title="برگزاری رویدادها"
          subtitle="
            مسابقه ها ، رویدادها و دورهمی هایتان را به همراه تصاویری جذاب ثبت و با بقیه به اشتراک بگذارید
            "
          icon="intro5.png"
        />

        <IntroItem
          className="intro-item"
          title=" صفحه باشگاه ها و مزارع"
          subtitle="
            صفحه باشگاه ها و مزارع پرورش اسب در کشور و دنیا را دنبال کنید و از امکانات و کلاس هایشان مطلع شوید
            "
          icon="intro4.png"
        />
      </div>
    </div>
  );
}

export default Intro;
