import "../Css/Profile.css";
import { Dialog } from "primereact/dialog";
import SuggestionRow from "./SuggestionRow";
import { useEffect, useRef, useState } from "react";
import "../Css/SuggestionBox.css";
import { Divider } from "primereact/divider";
import { TabView, TabPanel } from "primereact/tabview";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import "../Css/Feed.css";
import Post from "./Post";
import NewPost from "./NewPost";
import "../Css/Register.css";
import SocialForm from "./SocialForm";
import EditProfileForm from "./EditProfileForm";
import "react-image-crop/dist/ReactCrop.css";
import axios from "axios";
import { Sidebar } from 'primereact/sidebar';
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { Helmet } from "react-helmet";
import Message from "./Message";
import io from 'socket.io-client';





function ProfileOfOther(props) {



  const onlineUser = JSON.parse(localStorage.getItem('flickerNetwork') != null) ? JSON.parse(localStorage.getItem('flickerNetwork')) : {username:"" , name:"", avatarPic:"profile-index.png"};
  const [fetchedUser, setFetchedUser] = useState(props.user);
  const [fetchedFollowers, setFetchedFollowers] = useState([]);
  const [fetchedFollowings, setFetchedFollowings] = useState([]);
  const [showFollowers, setShowFollowers] = useState(false);
  const [showFollowings, setShowFollowings] = useState(false);
  const [followersHeader, setFollowersHeader] = useState("دنبال شده ها");
  const [showNewPostDialog, setShowNewPostDialog] = useState(false);
  const [showEditAvatar, setShowEditAvatar] = useState(false);
  const [showEditCover, setShowEditCover] = useState(false);
  const [showEditProfileDialog, setShowEditProfileDialog] = useState(false);
  const [showEditSocialDialog, setShowEditSocialDialog] = useState(false);
  const [catDialog, setCatDialog] = useState(false);
  const [src, setSrc] = useState(null);
  const [profileUpdated, setProfileUpdated] = useState(0);
  const [avatarPic, setAvatarPic] = useState("profile-index.png");
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem('userToken'));
  const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
  const [fetchedPosts, setFetchedPost] = useState([]);
  const [isFollowed, setIsFollowed] = useState(false);
  const [followersCount,setFollowersCount] = useState(0);
  const [messageDialog,setMesaageDialog] = useState(props.message);
  const [messages,setMessages] = useState([]);
  const [newMessegeInserted,setNewMessegeInserted] = useState(false);
  const [postsLoading, setPostsLoading] = useState(false);
  const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
  const online = JSON.parse(localStorage.getItem('flickerNetwork')) != null ? "online" : "offline";
  const [uploadFile, setUploadFile] = useState("");
  const [uploadedSource,setUploadedSource] = useState("");
  const [isUploading,setIsUploading] = useState(false);
  const sidebarRef = useRef(null);
  const [hideMessageHeader,setHideMessageHeader] = useState(false);
  const [selected_message_content,setSelected_message_content]=useState("");
  const [lazyLoading, setLazyLoading] = useState(false);
  const [reply,setReply] = useState(false);
  const [theme,setTheme] = useState();
  const [offset,setOffset] = useState(10);
  const [loadMoreMessages,setLoadMoremessage]=useState(true);
  const [updateMesssages,setUpdateMesssages] = useState(true);
  const [socket,setSocket] = useState();
  const [isOnline,setIsOnline] = useState(false);
  const messagesRef = useRef(messages);
  const [isTyping,setIsTyping] = useState(false);

  // //hide message header when scrolling:
  // useEffect(() => {
    
  //   window.addEventListener('scroll', handleScroll);

  //   const dialogElement = document.querySelector('#message-body');
  //     if (dialogElement) {
  //       dialogElement.addEventListener('scroll', handleScroll)}
    
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // const handleScroll = () =>
  // {
  //   setHideMessageHeader(true);
  // }

  
 
  //*****************websocket connection**************************************************************************************
  
  const socketDeleteMessage = async (post_id) =>
  {

    setMessages(messagesRef.current.filter( m => m._id !== post_id));

  }
  
  const insertNewMessage = async (newMessage) =>
  {
  
          if(newMessage.reciever_id == onlineUser._id || onlineUser._id == newMessage.sender_id._id)
          {
            const newMessages = [newMessage,...messagesRef.current];
            console.log("new messages",newMessages);
            await setMessages(newMessages);
          }
  }  
  
  
  useEffect( () => {
      
      const initializeSocket = async () =>
      {
        
        
        const s = io('wss://flicka.ir:2000', { transports: ['websocket'] });
        // const s = io(process.env.SOCKET_ADDRESS, { transports: ['websocket'] });

        s.on('chat_message', async (chat_message) => {

         
          insertNewMessage(chat_message);
        });

        s.on('deleteMessage' , async(deleteMessage) =>
        {
          console.log("deleteMessage",deleteMessage);
          socketDeleteMessage(deleteMessage.post_id);
        });

        s.on('joinChatRoom', async (joinChatRoom) => {
          if(joinChatRoom.user_id === fetchedUser._id)
            setIsOnline(true);

          console.log("socket joinChatRoom",joinChatRoom);
        });
        s.on('leftChatRoom', async (leftChatRoom) => {
          if(leftChatRoom.user_id === fetchedUser._id)
          {
            setIsOnline(false);
          }

          console.log("socket leftChatRoom",leftChatRoom);
        });

        s.on("isTyping", async (isTyping) =>
        {
          console.log("isTyping",isTyping);

          if(isTyping.user_id == fetchedUser._id)
            setIsTyping(true);
        });


        s.on("stoppedTyping", async (stoppedTyping) =>
        {
          console.log("stopped Typing",stoppedTyping);

          if(stoppedTyping.user_id == fetchedUser._id)
              setIsTyping(false);
        });

        s.on('connect', () => {
          console.log('Connected to WebSocket');
        });
        
        s.on('disconnect', () => {
          console.log('Disconnected from WebSocket');
          
        });

        await setSocket(s);


        

    
      }


      if(messageDialog)
      {
        initializeSocket();
       
      }
      else
      {
       disconnectSocket();

      }

       // Add event listener for beforeunload
        window.addEventListener('unload', disconnectSocket);
      

    
      
      return () => {

        window.removeEventListener('unload', disconnectSocket);

        // Disconnect when the component unmounts
        disconnectSocket();
      
      };

      
    }, [messageDialog]);

    useEffect(()=>
    {
         //send isOnline to socket:
         socket?.emit('joinChatRoom', {user_id:onlineUser._id} );

         socket?.emit("checkStatus",{user_id:props.user._id});
    }
    ,[socket]);

    const disconnectSocket = () =>
    {
      
      socket?.emit("leftChatRoom",{user_id:onlineUser._id})
      socket?.disconnect();
    }

    //to get updated version of messages in event handler:
    useEffect(() => {
      messagesRef.current = messages;
    }, [messages]);

    const handleFocus = () =>
    {
      console.log("here");
      socket?.emit('isTyping', {user_id:onlineUser._id} );
    }

    const handleBlur = () =>
    {
      console.log("left");
      socket?.emit('stoppedTyping', {user_id:onlineUser._id} );
    }


    //******************************************************************************************************************* */


    

//get user theme:
useEffect(() =>
{
  const fetchTheme = async () =>
  {
        await axios
          .get(process.env.REACT_APP_BACK+"/settings/theme/"+onlineUser._id, config)
          .then((result) => {
            if (result.status == 200) {
              setTheme(result.data);
              
              
              console.log("theme: ", result.data);
              
              
            }
          })
          .catch((error) => {
            console.log(error);
            if(error.response.data.statusCode===401)
              navigate("/login")
          });

    }

    
      fetchTheme();
}  
,[]);


  

  const replyClicked = (content) =>
  {
    handleScrollToBottom();
    setSelected_message_content(content);
    setReply(true);
   
  }

  //submit to uploaded file to sever:
  const uploadMedia = async (event) => {

    setIsUploading(true);
    handleScrollToBottom();

    const file = event.target.files[0];
    await setUploadFile(file);
    
    const url = URL.createObjectURL(file);
    setUploadedSource(url);

    //upload file:
    let formData = new FormData();
    formData.append("file", file);

    const c = {
      headers: {
        "Contetnt-Type": "multipart/form-data",
      },
    };
    
    await axios
      .post(process.env.REACT_APP_BACK+"/notifications/uploadFile", formData, c)
      .then(async (res) => {

        let fileName = res.data.fileName;

        //create message in db:

        var data = {sender_id:onlineUser._id,reciever_id:fetchedUser._id, type:"message",content:fileName};
        if(isOnline)
          data["seen"]=true;

        if(file.type.includes("image"))
          data["messageType"]="image";
        else if(file.type.includes("video"))
          data["messageType"]="video";
        
          
        await axios
            .post(process.env.REACT_APP_BACK+"/notifications/message", JSON.stringify(data), config)
            .then((response) => {

            if(response.status == 201)
            {
              setNewMessegeInserted(true);
              //broadcast message (using socket):
              socket.emit('chat_message', response.data );
              // setUpdateMesssages(true);
              console.log(response);
              setIsUploading(false);
            }
            })
            .catch((error) => {
              console.log("error",error);
              setIsUploading(false);
              if(error.response.data.statusCode===401)
              navigate("/login")
            });

            // handleScrollToBottom();
                messageForm.resetForm();

       

       
      })
      .catch((error) =>
      {
        if(error.response.data.statusCode===401)
              navigate("/login")
      });


  }

  //scroll to button when a new message is sent:
  const handleScrollToBottom = () => {


    if (sidebarRef.current) {
      const sidebarElement = sidebarRef.current;
      sidebarElement.scrollTop = sidebarElement.scrollHeight;

      console.log("scrolled");
    }
  };


  const follow = () => {

    
    //follow:
    if(!isFollowed)
    {
      const data = JSON.stringify({ sender_id:onlineUser._id,sender_username:props.user_username,sender_name:onlineUser.name, sender_avatar:onlineUser.avatarPic, reciever_id:fetchedUser._id, type:"follow"});
      axios
        .post(process.env.REACT_APP_BACK+"/notifications/follow", data, config)
        .then((response) => {

        if(response.status == 201)
        {
          console.log(response);
          setIsFollowed(true);
          setFollowersCount(followersCount+1);
        }
        })
        .catch((error) => {
          console.log("error",error);
          if(error.response.data.statusCode===401)
              navigate("/login")
        });

    }
    //unFollow:
    else
    {
      axios
        .delete(process.env.REACT_APP_BACK+"/notifications/follow/"+onlineUser._id+"/"+fetchedUser._id, config)
        .then((response) => {

        if(response.status == 200)
        {
          console.log(response);
          setIsFollowed(false);
          setFollowersCount(followersCount-1);
        }
        })
        .catch((error) => {
          console.log("error",error);
          if(error.response.data.statusCode===401)
              navigate("/login")
        });

    }
  }

    //delete message:
    const deleteMessage = async (_id,index) =>
    {

      const result = await axios.delete(process.env.REACT_APP_BACK+"/notifications/message/"+_id, config)
        .then((result) => {
          if (result.status == 200) {
            console.log(result.data);
            
            //delete message from array:
            setMessages((prevState) => {
              const newState = [...prevState];
              newState.splice(index, 1); 
              return newState; 
            });

            //send delete command to socket
            socket.emit("deleteMessage",{post_id:_id});


            return result.data;
          }
        })
        .catch((error) => {
          console.log(error);
          if(error.response.data.statusCode===401)
              navigate("/login")
        });
  
    };

  //message form:
  const messageForm = useFormik({
    initialValues: {
      content:""
    },
    onSubmit: (values) => {


      
      
      var d = { sender_id:onlineUser._id,reciever_id:fetchedUser._id, type:"message",content:values.content};
      if(reply)
      {
        d["reply_to_message"]=selected_message_content;
        setReply(false);
      }

      if(isOnline)
          d["seen"]=true;

      const data = JSON.stringify(d);
      

      
      axios
        .post(process.env.REACT_APP_BACK+"/notifications/message", data, config)
        .then((response) => {

        if(response.status == 201)
        {

          //broadcast message (using socket):
          socket.emit('chat_message', response.data );

          setNewMessegeInserted(true);
        
          // setUpdateMesssages(true);


          // handleScrollToBottom();
          console.log(response);
        }
        })
        .catch((error) => {
          console.log("error",error);
          if(error.response.data.statusCode===401)
              navigate("/login")
        });

            messageForm.resetForm();
        }
        ,validate: (values) => {
          let errors = {};
    
          if (!values.content) {
            errors.name = "پیام خود را بنویسید";
          }
    
          return errors;
        }

  });


   //fetch messages:
   useEffect(() => {
    async function fetchMessages() {


      // if(fetchMessages.length === 0)
      // {
      //     const dialogElement = document.querySelector('#message-body');
          
      //     if (dialogElement) {

      //       dialogElement.addEventListener('scroll', async function() {
      
      //       const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  
      //         if (scrollTop === 0) 
      //         {
    
                

      //         }
      //       });
      //     }
      //   }
     
            if(newMessegeInserted || messages.length===0)
            {
              await axios.get(process.env.REACT_APP_BACK+"/notifications/message/"+onlineUser._id+"/"+fetchedUser._id+"/"+10, config)
              .then(async (result) => {
                if (result.status == 200) {
                      
                  await setMessages(result.data);
                  if(newMessegeInserted)
                  {
                    setNewMessegeInserted(false);
                    setLoadMoremessage(true);
                    setOffset(10);
                  }
                    setUpdateMesssages(false);

                  console.log("fetched messages",result.data);

                  if(result.data.length===0)
                    setLoadMoremessage(false);
                  
                  
                  return result.data;
                }
              })
              .catch((error) => {
                console.log(error);
                if(error.response.data.statusCode===401)
                navigate("/login")
              });
            }
            else
            {
                await axios.get(process.env.REACT_APP_BACK+"/notifications/message/"+onlineUser._id+"/"+fetchedUser._id+"/"+offset, config)
                .then(async (result) => {
                  if (result.status == 200) {
                        
                    await setMessages(prevArray => [...prevArray, ...result.data]);
                    setUpdateMesssages(false);

                    console.log("fetched messages",result.data);

                    if(result.data.length===0)
                      setLoadMoremessage(false);
                    
                    
                    return result.data;
                  }
                })
                .catch((error) => {
                  console.log(error);
                  if(error.response.data.statusCode===401)
                  navigate("/login")
                });
          }
              
          
      
            
          }



    if(messageDialog && updateMesssages)
      fetchMessages();

  },[messageDialog,updateMesssages]);

  
  const coverStyle={
  
    backgroundImage: ` url("${process.env.REACT_APP_BACK}/covers/${fetchedUser["coverPic"]}")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
};

  const closeEditProfile = () => {
    setShowEditProfileDialog(false);
  };
  const updateProfile = () => {
    setProfileUpdated(profileUpdated + 1);
  };
  const closeEditSocial = () => {
    setShowEditSocialDialog(false);
  };

  const selectImage = (file) => {
    setSrc(URL.createObjectURL(file));
  };

  //setFeedLoading(true):
useEffect(() => {
  async function feedLoading() {

    setPostsLoading(true);
    
}
    
if(props.profileTab == 1)
  feedLoading(); 

},[]);


  //fetch user posts:
useEffect(() => {
  async function fetchPosts() {

    let heightIndex;
    let offset = 10;

    //fetch first group:
    const result = await axios.get(process.env.REACT_APP_BACK+"/posts/posts-of/" + fetchedUser._id+"/"+offset, config)
        .then((result) => {
          if (result.status == 200) {

            setFetchedPost(result.data);
            setPostsLoading(false);
            console.log("fetched posts: ",result.data);
            
            return result.data;
          }
        })
        .catch((error) => {
          console.log(error);
          if(error.response.data.statusCode===401)
              navigate("/login")
        });


        window.addEventListener('scroll', async function() {

          const { clientHeight, scrollTop, scrollHeight } = document.documentElement;

          if (scrollTop + clientHeight >= scrollHeight-150) {

            
            offset += 10;
            await setLazyLoading(true);
            

            const result = await axios.get(process.env.REACT_APP_BACK+"/posts/posts-of/" + fetchedUser._id+"/"+offset, config)
            .then((result) => {
              if (result.status == 200) {

                setFetchedPost(prevArray => [...prevArray, ... result.data]);
                setPostsLoading(false);
                console.log("fetched posts: ",result.data);

                return result.data;


              }
            })
            .catch((error) => {
              console.log(error);
              if(error.response.data.statusCode===401)
              navigate("/login")
            });
              
          

            setLazyLoading(false);
            
            

          

        
          }
        });

    

  }

  if(props.profileTab == 1 && fetchPosts.length===0)
  {
    
    fetchPosts();
    
  }

}, []);

//set user avatar:
  useEffect(
    () => {
      setAvatarPic(fetchedUser["avatarPic"] != null ? fetchedUser["avatarPic"] : "profile-index.png");
    }
    , [fetchedUser]
  );

  //fetch user information:
  useEffect(() => {
    async function fetchUser() {

      const result = await axios.get(process.env.REACT_APP_BACK+"/users/profile/" + props.user.username, config);
      setFetchedUser(result.data);

      console.log("fetched user: ");
      console.log(result);
      setFollowersCount(result.data.followersCount);



      return result.data;

    }
    
//is user followed?:

    async function isUserFollowed() {

      const result = await axios.get(process.env.REACT_APP_BACK+"/notifications/follow/"+onlineUser._id+"/"+fetchedUser._id, config);
      setIsFollowed(result.data);
      return result.data;

    }
    setFetchedUser(fetchUser());
    isUserFollowed();
  }, [profileUpdated]);

  //fetch user followers:
useEffect(() => {
  async function fetchFollowers() {
    
    const result = await axios.get(process.env.REACT_APP_BACK+"/notifications/followers/" + props.user._id, config);
    
    const r = result.data.filter(item => item!==null && item!==undefined && item.type !== "group");
    setFetchedFollowers(r);
    console.log("fetched followers: ",r);
    return r;

    
}

if(showFollowers)
  fetchFollowers();
}
  , [showFollowers]
);

//fetch user followings:
useEffect(() => {
  async function fetchFollowings() {
    
    const result = await axios.get(process.env.REACT_APP_BACK+"/notifications/followings/" + props.user._id, config);
    
    
    const r = result.data.filter(item => item!==null && item!==undefined && item.type !== "group");
    setFetchedFollowings(r);
    console.log("fetched followings: ",r);
    return r;

    
}

if(showFollowings)
  fetchFollowings();
}
  , [showFollowings]
);


  const editItems = [
    {
      label: "ویرایش",
      icon: "pi pi-pencil",
    },
    {
      label: "حذف",
      icon: "pi pi-times",
    },
  ];

  
  

  const tabHandler = (e) => {

    if (e == 0)
      navigate("/" + fetchedUser["username"] + "/about", {
        state: { otherUser: fetchedUser , profileOfOther: props.profileOfOther},
      });
    if (e == 1)
      navigate("/" + fetchedUser["username"] + "/posts", {
        state: { otherUser: fetchedUser , profileOfOther: props.profileOfOther},
      });
  };



  return (
    <div id="user-profile" 
    style={{
      width: !props.profileOfOther ? "72%" : "94%"
      
  }}>



      <Helmet>
     <meta charSet="utf-8"/>
      <title>{fetchedUser.name}</title>
      <meta name="description" content={fetchedUser.job}/>
      </Helmet>
    
    


      <Sidebar
      id="message-dialog"
      className={theme}
      visible={messageDialog}
      position="right"
      style={{marginRight:"-0px",height:"100vh" }}
      onHide={() => {
        setMesaageDialog(false);
        props.setShowMessage(false);
      }}
      
      
      >

        <div id="message-header"
          className={hideMessageHeader ? "headerHidden" : "headerNotHidden"}
         >
          <div style={{textAlign:"center"}}><img src={`${process.env.REACT_APP_BACK}/avatars/${avatarPic}`}></img></div>
        
        <div style={{textAlign:"center"}}>

          {/* last visite */}
          <h6 >{fetchedUser.name}</h6>
          {fetchedUser["lastVisite"] && !isOnline ? 
          
          
          <span className="lastVisite">
            <i className="pi pi-clock" style={{marginLeft:"3px"}}></i>
          {"آخرین بازدید: "+new Date(fetchedUser["lastVisite"]).toLocaleDateString('fa-IR') + " , " + new Date(fetchedUser["lastVisite"]).toLocaleString('fa-IR', { hour: 'numeric', minute: 'numeric'})}
          </span> : null}

          {isOnline && <span><span className="online-dot"></span>آنلاین</span>}
          </div>
          </div>

          <div id="message-body" 
          className={hideMessageHeader ? "headerHidden" : "headerNotHidden"}
          ref={sidebarRef} 
  
          >
            
           {/* reply banner */}

        <div style={{display:!reply ? "none" : null,marginBottom:"50px"}}>           
        
        <div id="reply-banner">
            <span className="row1" style={{display:"flex",direction:"rtl",alignItems:"center"}}>

              <i className="pi pi pi-times" 
              style={{display:"flex",height:"10%",marginLeft:"10px", fontSize:"12px",cursor:"pointer",color:"#af734f",background: "rgb(175 115 79 / 24%)",padding: "5px",borderRadius: "50%"}} 
              onClick={() => setReply(false)}></i>
              
            <span style={{color:"rgba(175, 115, 79, 0.63)",fontSize:"13px"}}>پاسخ به:  </span>
            <span style={{color:"#49505796",fontSize:"13px"}}>{" "+selected_message_content+"   "}</span>
              
            </span>

          </div>
          <div className="chat-arrow"></div>
          </div>

          
           {/* uploading media: */}

          {isUploading ? 
          <span style={{display: "flex", direction: "rtl", margin: "10px 0px",alignItems: "center"}}>
         <span className="message-col1">
         <img src={`${process.env.REACT_APP_BACK}/avatars/${onlineUser.avatarPic}`}></img>
         </span>
          
         <span className="message-col2" id={isUploading ? "uploading" : "uploade-completed"}> 
         <div style={{position:"relative",height:"100%"}}>

          {/* load animation */}
            {/* <div className="spinner" style={{position:"absolute",marginRight:"32%",marginTop:"8%"}}></div> */}
  
         {uploadFile.type.includes("image")?<img className="uploadedMedia" src={uploadedSource}></img>:null}
         {uploadFile.type.includes("video")?<video className="uploadedMedia" controls preload="metadata" src={uploadedSource}></video>:null}
         
         </div>
         </span>       
         </span>
          : null}

            
          {messages.map((message, index) => (
            
            <div key={index} 
            

            //used for reply and delete:
            
            
            //  onContextMenu={(e) => {
            //   console.log("here");
            //   selected_message_index=index; 
            //   selected_message_id=message._id;
            //   if(message.messageType !=="image" && message.messageType !=="video" )
            //    message.content.length>30  ? selected_message_content= message.content.slice(0,30) + " ... " : selected_message_content=message.content;
            //   else
            //    selected_message_content="";


            //   if(message.sender_id._id == onlineUser._id)
            //   {
            //     cm1.current.show(e);
            //   }
            //   else
            //   {
            //     cm2.current.show(e);
            //   }
            //   }}
              >

                

                

                <Message 
                message={message} 
                messages={messages}
                index={index}
                replyClicked={replyClicked}
                deleteMessage={deleteMessage}
                >
                  
                </Message>
            
              

         </div>

            

          )
)}

          


          
           {loadMoreMessages ? 
            <div style={{display:"table", margin:"auto"}}
              onClick={()=>
                {
                  setOffset(offset+10);
                  setUpdateMesssages(true);
                
                }
              }
            >

              <i className="pi pi-plus-circle load-more"
                  ></i>
            </div>
        : null} 

       
        
        </div>
        

         
          



        <div id="message-form"
        className={hideMessageHeader ? "headerHidden" : "headerNotHidden"}
        style={{height: isTyping? "10%" : "6%"}}
        >
          
          <span style={{display:"flex",margin:"20px 0px 0px 0px"}}>
         <span className="message-col1" >
         <img src={`${process.env.REACT_APP_BACK}/avatars/${onlineUser.avatarPic}`}></img>
         </span>

         <div style={{width:"100%"}}> 

         { isTyping && <span><span className="isTyping online-dot-grey"></span><span style={{marginRight:"5px",fontSize:"12px"}}>داره تایپ میکنه ....</span></span>}

         <span className="message-col2">
         
         <form onSubmit={messageForm.handleSubmit} style={{ width: "84%", display: "flex", alignItems: "center"}}>
          
         
          <label htmlFor="file"><i className="pi pi-image" style={{color:"495057",marginRight:"10px",cursor:"pointer"}}/></label>
          <input type="file" id="file" style={{display: "none"}} onChange={uploadMedia}/>

              <InputText
                id="content"
                name="content"
                className="search-box"
                value={messageForm.values.content}
                placeholder="پیام خود را بنویسید ..."
                onChange={messageForm.handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              </form>
              <a style={{color:"#af734f", marginLeft:"8px"}} onClick={messageForm.handleSubmit}>ارسال</a>
              
              </span>
         </div>
         
         </span>
    
        </div>
        
          
      </Sidebar> 
    

      <Dialog
        id="categories"
        className={theme}
        header="دسته بندی ها"
        visible={catDialog}
        style={{ width: "40vw" }}
        onHide={() => setCatDialog(false)}
      >
        
      </Dialog>

      <Dialog
        id="edit-profile-form"
        className={theme}
        header="ویرایش پروفایل"
        visible={showEditProfileDialog}
        onHide={() => setShowEditProfileDialog(false)}
        style={{ width: "40vw" }}
        dismissable="false"
      >
        <EditProfileForm close={closeEditProfile} user={fetchedUser} updateProfile={updateProfile} />
      </Dialog>

      <Dialog
        id="edit-profile-form"
        className={theme}
        header="راه های ارتباطی"
        visible={showEditSocialDialog}
        onHide={() => setShowEditSocialDialog(false)}
        style={{ width: "40vw" }}
        dismissable="false"
      >
        <SocialForm isPage={false} user={fetchedUser} close={closeEditSocial} updateProfile={updateProfile} />
      </Dialog>

      <div
        id="profile-cover"
        onMouseEnter={() => {!props.profileOfOther ? setShowEditCover(true) : setShowEditCover(false)}}
        onMouseLeave={() => {!props.profileOfOther ? setShowEditCover(false) : setShowEditCover(false) }}
        style={coverStyle}
      >
        {!desktop ?
            <div
              className="badges"
              style={{
                background: "transparent",
                marginTop: "0px",
                width: "140px",
                height: "140px",
              }}
            >
              <span
                style={{ position: "absolute", margin: "18px 0px 0px 15px" ,fontSize:"12px" }}
              >
                <i
                  className="pi pi-times"
                  style={{ padding: "0px 5px 0px 0px", fontSize: "10px" }}
                ></i>
                {fetchedUser["roset1"] ? fetchedUser["roset1"] : 0 }
              </span>
              <img src={require("../images/badge1.png")}></img>
            </div>
             : null}
        {/* <i
          className="pi pi-camera"
          style={{ display: (online==="online" && (showEditAvatar || !desktop)) ? "flex" : "none" }}
        ></i> */}
      </div>

      
      <div id="profile-nav">
        <span id="p-row1">
          <span className="p-col1">



          <span style={{display:"flex",width: !desktop ? "30%" : null}}>
            <div
              className="p-avatarPic"
               onMouseEnter={() => { !props.profileOfOther ? setShowEditAvatar(true) : setShowEditAvatar(false);}}
              onMouseLeave={() => { !props.profileOfOther ? setShowEditAvatar(false) : setShowEditAvatar(false)}}
              style={{
                background: "#ffffff",
                borderRadius: "50%",
                boxShadow: "0 1px 4px rgba(0, 0, 0, 0.15)",
                marginTop: "-70px",
                width: "140px",
                height: "140px",
              }}
            >
              {/* <div
                id="edit-profile-pic"
                style={{
                  display: (online==="online" && (showEditAvatar || !desktop)) ? "block" : "none",
                  background: "#25252580",
                  borderRadius: "50%",
                  marginTop: "0px",
                  width: "140px",
                  height: "140px",
                  position: "absolute",
                  cursor: "pointer",
                }}
              >
                <i
                  className="pi pi-camera"
                  style={{
                    marginTop: "40%",
                    fontSize: "25px",
                    color: "#ffffff",
                  }}
                ></i>
              </div> */}
              <img src={`${process.env.REACT_APP_BACK}/avatars/${avatarPic}`} alt={`فلیکا - ${fetchedUser.name}`}   ></img>

            </div>
            </span>

           

            <span style={{display:"flex", textAlign:"left",width: !desktop ? "70%" : null}}>
            {/* follow / unfollow */}
            
            {online ? 
            <div 
            className="p-follow" style={{ textAlign: "right", margin: "-20px 10px 0px 0px" }}>

              <a 
              // className = { isFollowed ? "follow-button followed" : "follow-button"}
              style={{ 
                background: isFollowed ? "transparent" : "#af734f" ,
                border: isFollowed ? "1px solid #af734f" : "1px solid #af734f" ,
                color: isFollowed ? "#af734f" : "#ffffff" ,
                borderRadius:"30px",
                padding: "10px 10px 10px 10px",
                fontSize:"15px"
              }}
                onClick={follow}
                
              >
                
                {isFollowed ? "دنبال میکنم": "دنبال کردن"}
                </a>
            </div>

            : null}

             {/* message */}
             <div className="p-message" style={{ textAlign: "right", margin: "-20px 10px 0px 0px" }}>

            <a 
            // className = {"follow-button followed"}
            style={{ 
              background: "transparent" ,
              border:"1px solid #af734f",
              color: "#af734f",
              borderRadius:"30px",
              padding: "10px 10px 10px 10px",
              fontSize:"15px"
            }}
              onClick={()=>
                {
                  setMesaageDialog(true);
                  props.setShowMessage(true);
                }}
            >
              ارسال پیام
              </a>
            </div>

            </span>


          </span>
          
        

       

{desktop ?
  <span className="p-col2">
            <div
              className="badges"
              style={{
                background: "transparent",
                marginTop: "-70px",
                width: "140px",
                height: "140px",
              }}
            >
              <span
                style={{ position: "absolute", margin: "28px 0px 0px 25px" }}
              >
                <i
                  className="pi pi-times"
                  style={{ padding: "0px 5px 0px 0px", fontSize: "12px" }}
                ></i>
                {fetchedUser["roset1"] ? fetchedUser["roset1"] : 0 }
              </span>
              <img src={require("../images/badge1.png")}></img>
            </div>
            </span>
             : null}
          
        </span>

        <span> {/* user name: */}
            <div
            className="p-title" 
            style={{ textAlign: "right", margin: "-20px 10px 0px 30px" }}>

                    <span style={{display:"flex",alignItems:"center",direction:"rtl",marginTop:"30px"}}>
                    <h4 style={{ marginBottom: "0px", marginTop:"0px"}}>{fetchedUser.name}</h4>
                    {fetchedUser.blueTick ? <img id="blue-tick" src={require("../images/blueTick.png")}></img> : null}
                    </span>
              
              <p style={{ marginTop: "10px", fontSize: "12px" }}>{fetchedUser.job}</p>
            </div></span>


        <span id="p-row2">
          <span className="p-col1">
            <button
              style={{ marginLeft: "20px" }}
              onClick={() => {
                setShowFollowers(true);
                setFollowersHeader("دنبال کننده ها");
              }}
            >
              {followersCount} دنبال کننده
            </button>
            <button
              onClick={() => {
                setShowFollowings(true);
                setFollowersHeader("دنبال میکند");
              }}
            >
              {fetchedUser["followingsCount"]} دنبال میکند
            </button>
          </span>
          <Dialog
            id="suggestion-box"
            className={theme}
            visible={showFollowers}
            header={followersHeader}
            style={{ width: !desktop ? "75%" : "30%"}}
            onHide={() => setShowFollowers(false)}
            dismissableMask={true}
          >
            <div>
              <Divider style={{ marginTop: "0px" }} />
            </div>
            {fetchedFollowers.map((follower, index) => (
            
            follower!==null &&  <SuggestionRow
              key={index}
              name={follower.name}
              job={follower.job}
              image={follower.avatarPic}
              username={follower.username}
              _id={follower._id}
              isPage={follower.type == undefined ? false : true}
              
            ></SuggestionRow>

          ))}
          </Dialog>

          <Dialog
            id="suggestion-box"
            className={theme}
            visible={showFollowings}
            header={followersHeader}
            style={{ width: !desktop ? "75%" : "30%"}}
            onHide={() => setShowFollowings(false)}
            dismissableMask={true}
          >
            <div>
              <Divider style={{ marginTop: "0px" }} />
            </div>
            {fetchedFollowings.map((follower, index) => (
            
            
            follower !== null && <SuggestionRow
              key={index}
              name={follower.name}
              job={follower.job}
              image={follower.avatarPic}
              username={follower.username}
              _id={follower._id}
              isPage={follower.type == undefined ? false : true}
            ></SuggestionRow>
            

          ))}
           
          </Dialog>


          <span className="p-col2"></span>
        </span>
      </div>

      <div id="profile-content">
        <TabView
          id="profile-tab"
          style={{ direction: "rtl" }}
          activeIndex={props.profileTab}
          onTabChange={(e) => tabHandler(e.index)}
        >
          <TabPanel header="درباره">
            <div id="about-panel">
              <span id="tab1-col2" style={{display: !desktop ? "flex" : null}}>
                <div className="info-box">
                  <span className="info-box-header about-box-content">
                    <span className="info-header-col1">
                    <i className="pi pi-info-circle"></i>
                      <h6 style={{marginRight:"10px"}}>درباره</h6>
                    </span>

                    { !props.profileOfOther ?
                    <span
                      className="info-header-col2"
                      onClick={() => setShowEditProfileDialog(true)}
                    >
                      <i className="pi pi-pencil"></i>
                    </span>
                    : null }
                  </span>
                  <div className="info-box-content">
                  <span className="about-box-content"  style={{marginBottom:"10px" , display:"flex" , color:"#252525"}}><i className="pi pi-user"></i>{fetchedUser.name} {fetchedUser.job ? " , " : null} {fetchedUser.job}</span>
                  { fetchedUser["country"] != null && fetchedUser["country"] != ""?
                  <span className="about-box-content"  style={{marginBottom:"10px" , display:"flex" , color:"#252525"}}><i className="pi pi-map"></i>{fetchedUser.country} , {fetchedUser.city}</span>
                  : null }

                    { (fetchedUser["bio"] == null || fetchedUser["bio"] == "") && !props.profileOfOther ?
                    
                    <span
                      onClick={() => setShowEditProfileDialog(true)}
                      style={{
                        color: "#af734f",
                        cursor: "pointer",
                        display: "flex",
                        marginBottom: "10px",
                      }}
                    >
                      <i className="pi pi-plus-circle"></i>
                      افزودن بیوگرافی
                    </span>
                    : null }

                    { (fetchedUser["awards"] == null || fetchedUser["awards"] == "") && !props.profileOfOther ?
                    <span
                      onClick={() => setShowEditProfileDialog(true)}
                      style={{
                        color: "#af734f",
                        cursor: "pointer",
                        display: "flex",
                        marginBottom: "10px",
                      }}
                    >
                      <i className="pi pi-plus-circle"></i>
                      افزودن جایزه ها
                    </span>
                    : null }
                    <span>
                      <i className="pi pi-calendar"></i>
                      تاریخ عضویت:
                      {" "+new Date(fetchedUser["regDate"]).toLocaleDateString('fa-IR')}
                    </span>
                  </div>
                </div>

                <div className="info-box" style={{display: !desktop ? "none" : null}}>
                  <span className="info-box-header">
                    <span
                      className="info-header-col1"
                      onClick={() => setShowEditSocialDialog(true)}
                    >
                      <h6>راه های ارتباطی</h6>
                    </span>

                    { !props.profileOfOther ?
                    <span
                      className="info-header-col2"
                      onClick={() => setShowEditSocialDialog(true)}
                    >
                      <i className="pi pi-pencil"></i>
                    </span>
                    : null }
                  </span>
                  <div className="info-box-content">

                  { !props.profileOfOther ?
                  <div>
                  <span style={{marginBottom:"20px" , display:"flex" }}>شماره همراه و ایمیل شما به سایر کاربران نشان داده نمی شود</span>
                    <span style={{marginBottom:"10px" , display:"flex" , color:"#252525"}}><i className="pi pi-phone"></i>شماره همراه: {fetchedUser.phone}</span>
                    <span style={{marginBottom:"10px" , display:"flex", color:"#252525"}}><i className="pi pi-envelope"></i>ایمیل: {fetchedUser.email}</span>
                  </div>
                  :null }

                    { fetchedUser.website ? <span style={{marginBottom:"10px" , display:"flex", color:"#252525"}}><i className="pi pi-globe"></i>وبسایت: <a href={ fetchedUser.website}/></span> : null}
                    
                    { (fetchedUser.instagram != null && fetchedUser.instagram != "") || (fetchedUser.telegram != null && fetchedUser.telegram != "") || (fetchedUser.whatsapp != null && fetchedUser.whatsapp != "") || (fetchedUser.facebook != null && fetchedUser.facebook != "") || (fetchedUser.twitter != null && fetchedUser.twitter != "") || (fetchedUser.linkedin != null && fetchedUser.linkedin != "") || (fetchedUser.youtube != null && fetchedUser.youtube != "") ?
                    <span style={{marginBottom:"10px" , display:"flex", color:"#252525"}}>شبکه های اجتماعی:</span> : null }
                    <span style={{marginBottom:"10px" , display:"flex", color:"#252525"}}>
                      { fetchedUser.instagram != null && fetchedUser.instagram != "" ?<span className="social-icon"><a href={"https://instagram.com/"+fetchedUser.instagram}><i className="pi pi-instagram"></i></a></span> : null }
                      { fetchedUser.telegram != null  && fetchedUser.telegram != ""? <span className="social-icon"><a href={fetchedUser.telegram}><i className="pi pi-telegram"></i></a></span> : null }
                      { fetchedUser.whatsapp != null && fetchedUser.whatsapp != "" ? <span className="social-icon"><a href={"https://api.whatsapp.com/send?phone="+fetchedUser.whatsapp}><i className="pi pi-whatsapp"></i></a></span> : null }
                      { fetchedUser.facebook != null && fetchedUser.facebook != "" ? <span className="social-icon"><a href={"https://facebook.com/"+fetchedUser.facebook}><i className="pi pi-facebook"></i></a></span> : null }
                      { fetchedUser.twitter != null && fetchedUser.twitter != "" ? <span className="social-icon"><a href={"https://twitter.com/"+fetchedUser.twitter}><i className="pi pi-twitter"></i></a></span> : null }
                      { fetchedUser.linkedin != null && fetchedUser.linkedin != "" ? <span className="social-icon"><a href={"https://linkedin.com/"+fetchedUser.linkedin}><i className="pi pi-linkedin"></i></a></span> : null }
                      { fetchedUser.youtube != null && fetchedUser.youtube != "" ? <span className="social-icon"><a href={"https://youtube.com/"+fetchedUser.youtube}><i className="pi pi-youtube"></i></a></span> : null }
                      </span>

                      { !props.profileOfOther ?
                    <span
                      style={{
                        color: "#af734f",
                        cursor: "pointer",
                        display: "flex",
                        marginBottom: "10px",
                        marginTop:"10px",
                        fontSize:"13px"
                      }}
                      onClick={() => setShowEditSocialDialog(true)}
                    >
                      <i className="pi pi-plus-circle"></i>
                      افزودن راه ارتباطی
                    </span>

                    :null }

                  </div>
                </div>
              </span>

              <span id="tab1-col1" style={{display: !desktop ? "contents" : null}}>
                {fetchedUser["bio"] == null && fetchedUser["awards"] == null ? (
                  <div>
                    <img src={require("../images/intro-index.png")}></img>
                    <p style={{color:"#25252580",marginTop:"-20px",marginBottom:"50px"}}>اطلاعات بیوگرافی کاربر هنوز ثبت نشده!</p>
                  </div>
                ) : null}



                {fetchedUser["bio"] != null && fetchedUser["bio"] != ""?
                  <div className="about-box about-box-content">
                    <div><span  style={{ display: "flex", direction: "rtl", margin: "10px 0px 10px 0px" }}><i className="pi pi-id-card"></i><h6>بیوگرافی</h6></span></div>
                    <div><p>{fetchedUser["bio"]}</p></div>
                  </div>
                  :
                  null

                }
                {
                  fetchedUser["awards"] != null && fetchedUser["awards"] != ""?

                  <div className="about-box">
                  <div><span className="about-box-content"  style={{ display: "flex", direction: "rtl", margin: "10px 0px 10px 0px" }}><i className="pi pi-star"></i><h6>جایزه ها</h6></span></div>
                  <div><p>{fetchedUser["awards"]}</p></div>
                </div>

                    : null
                }

                {/* mobile (contact information)*/}
                {
                !desktop?

                  <div className="about-box">
                  <div><span style={{ display: "flex", direction: "rtl", margin: "10px 0px 10px 0px" }}><i className="pi pi-comments"></i><h6> راه های ارتباطی</h6></span></div>
                  { !props.profileOfOther ?
                    <span
                      className="info-header-col2"
                      onClick={() => setShowEditSocialDialog(true)}
                    >
                      <i className="pi pi-pencil"></i>
                    </span>
                    : null }
                  
                  <div className="info-box-content">

                  { !props.profileOfOther ?
                  <div>
                  <span style={{marginBottom:"20px" , display:"flex" }}>شماره همراه و ایمیل شما به سایر کاربران نشان داده نمی شود</span>
                    <span style={{marginBottom:"10px" , display:"flex" , color:"#252525"}}><i className="pi pi-phone"></i>شماره همراه: {fetchedUser.phone}</span>
                    <span style={{marginBottom:"10px" , display:"flex", color:"#252525"}}><i className="pi pi-envelope"></i>ایمیل: {fetchedUser.email}</span>
                  </div>
                  :null }

                    { fetchedUser.website ? <span className="about-box-content" style={{marginBottom:"10px" , display:"flex", color:"#252525"}}><i className="pi pi-globe"></i>وبسایت: <a  style={{marginRight:"5px"}} href={ fetchedUser.website}/>{ fetchedUser.website}</span> : null}
                    
                    { (fetchedUser.instagram != null && fetchedUser.instagram != "") || (fetchedUser.telegram != null && fetchedUser.telegram != "") || (fetchedUser.whatsapp != null && fetchedUser.whatsapp != "") || (fetchedUser.facebook != null && fetchedUser.facebook != "") || (fetchedUser.twitter != null && fetchedUser.twitter != "") || (fetchedUser.linkedin != null && fetchedUser.linkedin != "") || (fetchedUser.youtube != null && fetchedUser.youtube != "") ?
                    <span className="about-box-content" style={{marginBottom:"10px" , display:"flex", color:"#252525"}}>شبکه های اجتماعی:</span> : null }
                    <span style={{marginBottom:"10px" , display:"flex", color:"#252525"}}>
                    { fetchedUser.instagram != null && fetchedUser.instagram != "" ?<span className="social-icon"><a href={"https://instagram.com/"+fetchedUser.instagram}><i className="pi pi-instagram"></i></a></span> : null }
                      { fetchedUser.telegram != null  && fetchedUser.telegram != ""? <span className="social-icon"><a href={fetchedUser.telegram}><i className="pi pi-telegram"></i></a></span> : null }
                      { fetchedUser.whatsapp != null && fetchedUser.whatsapp != "" ? <span className="social-icon"><a href={"https://api.whatsapp.com/send?phone="+fetchedUser.whatsapp}><i className="pi pi-whatsapp"></i></a></span> : null }
                      { fetchedUser.facebook != null && fetchedUser.facebook != "" ? <span className="social-icon"><a href={"https://facebook.com/"+fetchedUser.facebook}><i className="pi pi-facebook"></i></a></span> : null }
                      { fetchedUser.twitter != null && fetchedUser.twitter != "" ? <span className="social-icon"><a href={"https://twitter.com/"+fetchedUser.twitter}><i className="pi pi-twitter"></i></a></span> : null }
                      { fetchedUser.linkedin != null && fetchedUser.linkedin != "" ? <span className="social-icon"><a href={"https://linkedin.com/"+fetchedUser.linkedin}><i className="pi pi-linkedin"></i></a></span> : null }
                      { fetchedUser.youtube != null && fetchedUser.youtube != "" ? <span className="social-icon"><a href={"https://youtube.com/"+fetchedUser.youtube}><i className="pi pi-youtube"></i></a></span> : null }
                      </span>

                      { !props.profileOfOther ?
                    <span
                      style={{
                        color: "#af734f",
                        cursor: "pointer",
                        display: "flex",
                        marginBottom: "10px",
                        marginTop:"20px",
                        fontSize:"13px"
                      }}
                      onClick={() => setShowEditSocialDialog(true)}
                    >
                      <i className="pi pi-plus-circle"></i>
                      افزودن راه ارتباطی
                    </span>

                    :null }

                  </div>
                </div>

                    : null
                }








              </span>
            </div>
          </TabPanel>
          <TabPanel header="پست ها">
            <div id="posts-panel">
              <span id="tab1-col2">
                <div className="info-box">
                  <span className="info-box-header">
                    <span className="info-header-col1">
                      <h6>دسته بندی ها</h6>
                    </span>

                    { !props.profileOfOther ?
                    <span
                      className="info-header-col2"
                      onClick={() => setCatDialog(true)}
                    >
                      <i className="pi pi-pencil"></i>
                    </span>
                    : null }
                  </span>
                  <div className="info-box-content">
                    <span>
                      <i className="pi pi-folder"></i>
                      همه دسته ها
                    </span>

                    { !props.profileOfOther ?
                    <span
                      onClick={() => setCatDialog(true)}
                      style={{
                        color: "#af734f",
                        cursor: "pointer",
                        display: "flex",
                        margin: "10px 0px 10px 0px",
                      }}
                    >
                      <i className="pi pi-plus-circle"></i>
                      افزودن دسته جدید
                    </span>
                    : null }
                  </div>
                </div>
              </span>

            { isFollowed || fetchedUser.accountType==="عمومی" ? 
              <span id="tab1-col1">
                <div id="feed">
                  <a>

                    { !props.profileOfOther ?

                    <span id="create-post">
                      <span id="feed-col1">
                        <Button
                          onClick={() => setShowNewPostDialog(true)}
                          tooltip="عکس"
                          tooltipOptions={{
                            className: "create-post-tooltip",
                            position: "top",
                          }}
                        >
                          <i className="pi pi-image"></i>
                        </Button>
                        <Button
                          onClick={() => setShowNewPostDialog(true)}
                          tooltip="ویدیو"
                          tooltipOptions={{
                            className: "create-post-tooltip",
                            position: "top",
                          }}
                        >
                          <i className="pi pi-video"></i>
                        </Button>
                      </span>

                      <span
                        onClick={() => setShowNewPostDialog(true)}
                        id="feed-col2"
                      >
                        انتشار پست جدید ...
                      </span>
                      <span
                        onClick={() => setShowNewPostDialog(true)}
                        id="feed-col3"
                      >
                        <img src={`${process.env.REACT_APP_BACK}/avatars/${avatarPic}`}></img>
                  
                      </span>
                    </span>


                    : null }

                    <Dialog
                     
                      className={theme+" new-post-dialog"}
                      breakpoints={{ "960px": "75vw", "640px": "100vw" }}
                      position="top"
                      header="پست جدید"
                      visible={showNewPostDialog}
                      style={{ width: "40vw" }}
                      onHide={() => setShowNewPostDialog(false)}
                    >
                      <NewPost type="normal" user={fetchedUser} />
                    </Dialog>
                  </a>
                  
                  {/* loading animation */}
            {postsLoading ? 
            <div className="spinner"></div>
        : null}

                  {fetchedPosts.map((post, index) => (
            
            
            <Post
                    key={index}
                    creatorUsername={fetchedUser.username}
                    creator_id={post.creator._id}
                    creatorName={post.creator.name}
                    creatorAvatar={post.creator.avatarPic}
                    creatorJob={post.creator.job}
                    file={post.file}
                    fileType={post.fileType}
                    category={post.category}
                    tags={post.tags}
                    taggedUsers={post.taggedUsers}
                    date={post.date}
                    description={post.description}
                    likesCount={post.likesCount}
                    commentsCount={post.commentsCount}
                    postOfOther={true}
                    _id={post._id}
                    updatePost={updateProfile}
                    //used for like & save:
                    userAvatar={onlineUser.avatarPic}
                    user_id={onlineUser._id}
                    user_name={onlineUser.name}
                    user_username={onlineUser.username}
                    theme={theme}
                    blueTick={post.creator.blueTick}
                  />
            

          ))}

          {/* load animation */}
          {lazyLoading ? 
            <div className="spinner" style={{marginBottom:"50px"}}></div>
        : null}


                  <br />
                </div>
              </span>

              :
              <span id="tab1-col1">
              <div style={{  paddingTop: desktop ? "100px" : "50px", display:"table" , margin:"auto" , paddingBottom:"100px"}}>این حساب خصوصی است</div> 
              </span>
              }


            </div>
          </TabPanel>

          
        </TabView>
      </div>
    </div>
  );
}
export default ProfileOfOther;
