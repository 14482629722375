import axios, { Axios } from "axios";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ContextMenu } from "primereact/contextmenu";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";


function ManagePages(props)
{

    const [pages,setPages] = useState([]);
    const [updatePages,setUpdatePages] = useState(0);
    const [selectedPage, setSelectedPage] = useState({name:"",username:"",avatarPic:"",type:""});
    const cm = useRef(null);
    const toast = useRef(null);
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const navigate = useNavigate();
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showEditPage, setShowEditPage] = useState(false);
    const imageBodyTemplate = (rowData) => {
        console.log("selected page",selectedPage);
        return  <Link to={"/"+rowData.username+"/about"}
            state={{ page:  {_id:rowData._id, username:rowData.username, name:rowData.name , avatarPic:rowData.image} , isPage:true , isUserPage:false }}>
        <img 
        src={`${process.env.REACT_APP_BACK}/avatars/${rowData.avatarPic}`}
        style={{height:"50px",width:"50px",borderRadius:"50%"}}></img>
        </Link>
        
    };
    const regDateBodyTemplate = (rowData) => {
        return new Date(rowData.regDate).toLocaleDateString('fa-IR');
    };
    
    const roleBodyTemplate = (rowData) => {
        if(rowData.role === "subscriber")
            return "مشترک";
        else if(rowData.role === "manager")
            return "مدیر کل";
        else 
            return "-";
        
    };

    
    const menuModel = [
        {label: 'ویرایش', icon: 'pi pi-fw pi-pencil', command: () => setShowEditPage(true)},
        {label: 'حذف', icon: 'pi pi-fw pi-trash', command: () => setShowConfirmDelete(true)}
    ];


    const deletePageFromDB = async (_id) => 
    {
      await axios.delete(process.env.REACT_APP_BACK+"/pages/" + _id, config)
      .then(response => 
        {
          if (response.status == 200)
          {
            console.log(response);      
            toast.current.show({severity: 'info', summary: 'صفحه حذف شد'});
            setUpdatePages(updatePages+1);    
            
          }

      })
      .catch(error => {
         console.log(error);
         if(error.response.data.statusCode===401)
              navigate("/login")
      });
    };

   

    //get all pages from database:
    useEffect(() => {
    async function getAllPages() {

      
      const result = await axios.get(process.env.REACT_APP_BACK+"/pages/all/1", config)
      .then((result) => {
        if (result.status == 200) {
          setPages(result.data);
          console.log("all pages",result.data);
          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
              navigate("/login")
      });
      return result.data;

    }
    getAllPages();
  },[updatePages]);

  
   //initialize formik:
   useEffect(() => {
    async function initForm() {

      editPageForm.values.name = selectedPage.name;
      editPageForm.values.country = selectedPage.country;
      editPageForm.values.city = selectedPage.city;
      editPageForm.values.blueTick = selectedPage.blueTick;
      

    }
    initForm();
  },[selectedPage]);

  const editPageForm = useFormik({
    initialValues: {
      name: selectedPage.name,
      country: selectedPage.country,
      city: selectedPage.city,
      blueTick: selectedPage.blueTick
    },

    onSubmit: (values) => {


      const obj = {};

      for (const key in values) {

        if(key !== "")
        {
          obj[key] = values[key];
        }
        
    }


      const data = JSON.stringify(obj);
 
      
   
    axios.patch(process.env.REACT_APP_BACK+"/pages/update-page/"+selectedPage._id, data, config)
        .then(response => 
          {

          if(response.status == 200)
          {
            console.log(response);
            setShowEditPage(false);
            setUpdatePages(updatePages+1);
      
          }

        })
        .catch(error => {
           console.log(error);
           if(error.response.data.statusCode===401)
              navigate("/login")
        });

        
      
    },
  });

    

    return(

        <div id="manage-pages">

            <h2>
            مدیریت صفحه ها
            </h2>

            <Toast ref={toast}></Toast>

            <ContextMenu model={menuModel} ref={cm} />

            <DataTable size="normal" value={pages} paginator rows={20} editMode="row"
            className="manager-table"
            contextMenuSelection={selectedPage}
            onContextMenuSelectionChange={e => setSelectedPage(e.value)}
            onContextMenu={e => cm.current.show(e.originalEvent)}>
            
            <Column filed="avatarPic" body={imageBodyTemplate} ></Column>
            <Column alignHeader="center" columnKey="username" align="center" field="username" header="نام کاربری" sortable filter ></Column>
            <Column alignHeader="center" columnKey="name" align="center" field="name" header="نام صفحه" sortable filter></Column>
            <Column alignHeader="center" columnKey="type" align="center" field="type" header="نوع صفحه" sortable filter ></Column>
            <Column alignHeader="center" columnKey="followersCount" align="center" field="followersCount" header="دنبال کنندگان" sortable  ></Column>
            <Column alignHeader="center" columnKey="creatorName" align="center" field="creatorName" header="سازنده" filter sortable></Column>
            <Column alignHeader="center" columnKey="country" align="center" field="country" header="کشور" filter sortable></Column>
            <Column alignHeader="center" columnKey="city" align="center" field="city" header="شهر" filter sortable></Column>
            <Column alignHeader="center" columnKey="regDate" align="center" field="regDate" header="تاریخ عضویت" body={regDateBodyTemplate} sortable></Column>
            
            
            </DataTable>


            <ConfirmDialog 
            className={props.theme}
            visible={showConfirmDelete} 
            onHide={() => setShowConfirmDelete(false)} 
            message="آیا از حذف این صفحه مطمئنید؟"
            header="تایید حذف"
            icon="pi pi-info-circle"
            acceptLabel="بله"
            rejectLabel="نه"
            acceptClassName="accept"
            rejectClassName="reject"
            accept={() => deletePageFromDB(selectedPage._id)} 
            reject={() => setShowConfirmDelete(false)} />

            <Dialog
            className={props.theme}
                id="edit-user-dialog"
                header="ویرایش صفحه"
                visible={showEditPage}
                onHide={() => setShowEditPage(false)}
                >
            
          <form onSubmit={editPageForm.handleSubmit} className="p-fluid">
          <div className="field">
                <span>
                  <label htmlFor="name">نام صفحه</label>
                  <InputText
                    id="name"
                    name="name"
                    value={editPageForm.values.name}
                    onChange={editPageForm.handleChange}
                  />
                </span>
  
            
            </div>

            <div className="field">
              <label htmlFor="bio">کشور</label>
              <span className=" p-input-icon-left">
                <InputText
                  id="country"
                  name="country"
                  value={editPageForm.values.country}
                  onChange={editPageForm.handleChange}
                />
              </span>
            </div>

            <div className="field">
              <label htmlFor="prises">شهر</label>
              <span className=" p-input-icon-left">
                <InputText
                  id="city"
                  name="city"
                  value={editPageForm.values.city}
                  onChange={editPageForm.handleChange}
                  
                />
              </span>
            </div>

            <div className="field">
              <label htmlFor="prises">تیک آبی</label>
              <span className=" p-input-icon-left">
              <InputSwitch
                    id="blueTick"
                    name="blueTick"
                    onChange={editPageForm.handleChange}
                    value={editPageForm.values.blueTick}
                    checked={editPageForm.values.blueTick}

                  />
              
              </span>
            </div>

          
        

          

            <div className="two-columns">
              <div className="field col-one">
                <span>
                  <Button type="submit" label="ذخیره" className="mt-2" />
                </span>
              </div>

              <div className="field col-two">
                <span>
                  <Button
                    className="outline-button mt-2"
                    type="button"
                    label="انصراف"
                    onClick={() => setShowEditPage(false)}
                  />
                </span>
              </div>
            </div>
          </form>
        

        
            </Dialog>

        </div>
    );
}
  
export default ManagePages;