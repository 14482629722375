import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../Css/ErrorHandler.css"
import { Link } from "react-router-dom";

function NotFound()
{

    return(

        <div id="error-handler">


            <div id="error-title">            
            <h2>خطای 404</h2>
            <p>متاسفانه این صفحه رو نداریم :(</p>
            {/* <p>{error.message}</p> */}
            </div>

            <div id="error-pic"><img src= {require('../images/404-2.gif')} style={{width:"700px",marginTop: "40px",marginBottom:"20px"}}></img></div>

            <div id="error-button">
              <Link to="/">
              <button>برگرد خونه</button>
              </Link>
              </div>

        </div>


    );
}

export default NotFound;