import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { useRef } from 'react';
import { Toast } from 'primereact/toast';
import { ContextMenu } from 'primereact/contextmenu';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useNavigate } from 'react-router-dom';


function ManageFiles(props)
{


    const [files,setFiles] = useState([]);
    const [updateFiles,setUpdateFiles] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const cm = useRef(null);
    const toast = useRef(null);
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const navigate = useNavigate();
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const menuModel = [
        {label: 'حذف', icon: 'pi pi-fw pi-trash', command: () => setShowConfirmDelete(true)}
    ];

    const imageBodyTemplate = (rowData) => {
        
        if(rowData.fileType==="عکس")
        {
        return  <img 
        src={`${process.env.REACT_APP_BACK}/posts/${rowData.name}`}
        style={{height:"80px",width:"80px"}}></img>
        }
        else if(rowData.fileType==="آواتار")
        {
          return  <img 
          src={`${process.env.REACT_APP_BACK}/avatars/${rowData.name}`}
          style={{height:"80px",width:"80px"}}></img>
        }
        else if(rowData.fileType==="کاور" )
        {
          return  <img 
          src={`${process.env.REACT_APP_BACK}/covers/${rowData.name}`}
          style={{height:"80px",width:"80px"}}></img>
        }
        else if(rowData.fileType==="ویدیو")
        {
            return <video 
            src={`${process.env.REACT_APP_BACK}/posts/${rowData.name}`}
            poster={`${process.env.REACT_APP_BACK}/posters/poster_${rowData.name.replace(/\.[^/.]+$/, '')}.png`}
            controls
            width="100px"
            height="100px">

            </video>;
        }
        else
            return null;

    }
    const dateBodyTemplate = (rowData) => {
        return new Date(rowData.date).toLocaleDateString('fa-IR');
    }

    //get all files from database:
  useEffect(() => {
    async function getAllFiles() {

      
      const result = await axios.get(process.env.REACT_APP_BACK+"/posts/files")
      .then((result) => {
        if (result.status == 200) {
          setFiles(result.data);
          console.log("all files",result.data);
          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
              navigate("/login")
      });

      
      return result.data;

    }
    getAllFiles();
  },[updateFiles]);

  const deleteFileFromDB = async (file,type) => 
    {
      await axios.delete(process.env.REACT_APP_BACK+"/posts/delete-file/" + file + "/" + type, config)
      .then(response => 
        {
          if (response.status == 200)
          {
            console.log(response);      
            toast.current.show({severity: 'info', summary: 'فایل حذف شد'});
            setUpdateFiles(updateFiles+1);    
            
          }

      })
      .catch(error => {
         console.log(error);
         if(error.response.data.statusCode===401)
              navigate("/login")
      });
    }


    return(
        <div id="manage-files">
            <h2>
            مدیریت فایل ها
            </h2>

            <Toast ref={toast}></Toast>

            <ContextMenu model={menuModel} ref={cm} />

            <DataTable size="normal" value={files} paginator rows={20} 
            className="manager-table"
            contextMenuSelection={selectedFile}
            onContextMenuSelectionChange={e => setSelectedFile(e.value)}
            onContextMenu={e => cm.current.show(e.originalEvent)}
            >
            
            <Column filed="file" body={imageBodyTemplate} ></Column>
            <Column alignHeader="center" align="center" field="name" header="نام فایل" sortable filter ></Column>
            <Column alignHeader="center" align="center" field="size" header="سایز (بایت)" sortable filter ></Column>
            <Column alignHeader="center" align="center" field="fileType" header="نوع" sortable filter ></Column>
            <Column alignHeader="center" align="center" field="date" header="تاریخ ایجاد" body={dateBodyTemplate} sortable></Column>
            
            
            </DataTable>


            <ConfirmDialog 
            className={props.theme}
            visible={showConfirmDelete} 
            onHide={() => setShowConfirmDelete(false)} 
            message="آیا از حذف این فایل مطمئنید؟"
            header="تایید حذف"
            icon="pi pi-info-circle"
            acceptLabel="بله"
            rejectLabel="نه"
            acceptClassName="accept"
            rejectClassName="reject"
            accept={() => deleteFileFromDB(selectedFile.name,selectedFile.fileType)} 
            reject={() => setShowConfirmDelete(false)} />

        </div>
    );
}

export default ManageFiles;