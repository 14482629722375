import "../Css/Login.css";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { classNames } from "primereact/utils";
import { createRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputMask } from "primereact/inputmask";
import { Toast } from "primereact/toast";
import CryptoJS from 'crypto-js';


function Login(props) {


  const [loginError,setLoginError]= useState("");
  const navigate = useNavigate();
  const [verificationCode,setVerificationCode] = useState();
  const [step,setStep] = useState(1);
  const toast = createRef();
  const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
  const [codeSent,setCodeSent] = useState(false);
  const [user,setUser] = useState();
  const config = {headers: { "Content-Type": "application/json" }};

  const encryptData = (message) => 
  {
 
    const encrypted = CryptoJS.AES.encrypt(message, process.env.ACCESS_JWT_SECRET).toString();

    return encrypted;
  };
  

  const phoneForm = useFormik({
    initialValues: {
      phone: "",
    },
    validate: (values) =>
    {

      let errors={}

      if(!values.phone)
        errors.phone = "لطفاً شماره موبایل را وارد کنید";


      return errors;

   
  },
    onSubmit: (values) => {


      //send verification code by sms***********************************************************************************************

      const phone = values.phone.replace(/[^0-9]+/g, "");
      const vCode = Math.floor(Math.random() * 9000) + 1000;

      //check if user has registred before:
      axios.get(process.env.REACT_APP_BACK+"/users/check-phone/"+phone, config)
      .then((response) => {

        setUser(response.data);
     

        //ok to send code:
        if (response.data!=="")
        {

          setVerificationCode(vCode);

          var d = JSON.stringify({
            "code": "z7c0f80uhzni7qw",
            "sender": "+983000505",
            "recipient": phone,
            "variable": {
              "verification-code": vCode
            }
          });


          var config1 = {
            method: 'post',
            url: 'https://api2.ippanel.com/api/v1/sms/pattern/normal/send',
            headers: { 
              'apikey': 'bv1PxCuaPVZUcIPOSqgmx7u9w8nzdBk1Boj_vnwvo48=', 
              'Content-Type': 'application/json'
            },
            data : d
          };

          axios(config1)
          .then(function (response) {

            console.log("response",JSON.stringify(response.data));
            console.log("status",response.status);
            
            if(response.status === 200)
            {
              setCodeSent(true);
            }
            
          })
          .catch(function (error) {
            console.log("error",error);
            if(error.response.data.statusCode===401)
              navigate("/login")
          });
        
          

        }
        //donnot send code:
        else
        {
          toast.current.show({
            severity: "Error",
            detail: "کاربر با این شماره تلفن ثبت نشده است. لطفا ثبت نام کنید",
          });
          setStep(1);
          setCodeSent(false);
        }
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "Error",
          detail: "کاربر با این شماره تلفن ثبت نشده است. لطفا ثبت نام کنید",
        });
        setStep(1);
        setCodeSent(false);

        
      });

   

      
//******************************************************************************************************************************* */


          
    },
  });

  const vCodeForm = useFormik({
    initialValues: {
      vCode: "",
    },
    validate: (values) =>
    {
      let errors={};

      if(!values.vCode)
        errors.vCode = "لطفاً کد تایید را وارد کنید";

      return errors;

   
    },
    onSubmit: (values) => {



      //check if verification code is the same:
      if(values.vCode == verificationCode)
      {
        

           



  
              //user can login:
              if(user.role === "manager")
                navigate("/manager-of-flicka");
              else
                navigate("/feed", { state: { user: user }});
              
                
                //cache user:
              localStorage.setItem("flickerNetwork", JSON.stringify(user));
              
               //ask for user token:
            
            const payload = {payload:encryptData(user._id+","+user.role)}
            axios.post(process.env.REACT_APP_BACK+"/users/getToken/", JSON.stringify(payload), config)
            .then((response) => 
            {
              console.log("response",response);
              
              if(response.status === 201)
              {

                console.log("token",response.data);

                localStorage.setItem("userToken", JSON.stringify(response.data));
              }
              
            })
            .catch(function (error) {
              console.log("error",error);
              if(error.response.data.statusCode===401)
                navigate("/login");

            });
              
              

              
              
              
          
        

       
      }
      else
      {
        

        toast.current.show({
          severity: "Error",
          detail: "کد صحیح نبود. لطفا مجدداً تلاش کنید",
        });
        setCodeSent(false);
      }      
    },
  });


 


  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validate: (values) => {
      let errors = {};

      if (!values.username) {
        errors.username = "لطفا نام کاربری را وارد کنید";
      }

      if (!values.password) {
        errors.password = "لطفاً رمز عبور را وارد کنید";
      }

      return errors;
    },

    onSubmit: (values) => {

      console.log("atemped to login");
      

      const data = JSON.stringify({
        username: values.username,
        password: values.password,
        adminUsername:""
      });

     
     



      axios.post(process.env.REACT_APP_BACK+"/users/login", data, config)
        .then((response) => {
          if (response.status == 201)
          {

            //user can login:
            const user = response.data.User;

      

            if(user.role === "manager")
              navigate("/manager-of-flicka");
            else
              navigate("/feed",  { state: { user: user}});

            //cache user:
            localStorage.setItem("flickerNetwork", JSON.stringify(user));
            localStorage.setItem("userToken", JSON.stringify(response.data.token));

            

            //set user theme:
            // props.setTheme(getUserTheme(user));


          }
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          if(error.message == "Request failed with status code 500")
            setLoginError("کاربر با این مشخصات ثبت نشده");
           else if(error.message == "Request failed with status code 401")
           setLoginError("نام کاربری یا رمز عبور اشتباه است");


           if(error.response.data.statusCode===401)
              navigate("/login")
        });


      
     
    },
  });

  const isFormFieldValid = (phone) =>
    !!(formik.touched[phone] && formik.errors[phone]);
  const getFormErrorMessage = (phone) => {
    return (
      isFormFieldValid(phone) && (
        <small className="p-error">{formik.errors[phone]}</small>
      )
    );
  };

  

  return (
    <div id="login-form">


          <Toast ref={toast } style={{background:"red", color:"#ffffff", zIndex:"10000", margin:"0px"}} life="3000" position="center center"/>

          <Dialog 
            header="ورود با رمز یکبار مصرف"
            visible={step == 2}
            onHide={() => {setStep(1); setCodeSent(false);}}
            id="verification-code"
            style={{width: desktop ? "30%" : "80%"}}
          >
            <div>

              
              {/* before sending code */}
              <form id="code-notsent" onSubmit={phoneForm.handleSubmit} style={{display : !codeSent ? "block" : "none" }}>
               
              <div style={{marginTop:"40px",direction:"rtl",textAlign:"right"}}><label>
              شماره موبایل*
                </label></div>
           
              <div>    
              <InputMask 
              id="phone"
              name="phone"
              mask="(0999) 9999999" 
              value={phoneForm.values.phone}
              onChange={phoneForm.handleChange}
              style={{ direction: "ltr", textAlign: "left",marginTop:"10px", width:"100%" }}
              placeholder="(0919)999-99-99"
              className={phoneForm.errors["phone"] ? "p-invalid" : null}
              ></InputMask>

              <small className="p-error">{phoneForm.errors["phone"]}</small>

              </div>

            <div style={{marginBottom:"40px",marginTop:"30px"}}>
              <Button type="submit" label="ارسال کد"  style={{width:"100%"}} />
              </div>
              </form>





            {/* after sending code */}
           <form onSubmit={vCodeForm.handleSubmit} className="p-fluid" style={{display : codeSent ? "block" : "none" }} >

           <div id="code-sent">
                <img className="reg-image" src={require("../images/reg1.png")}></img>
                <p>کد تایید به شماره</p>
                  <p>{phoneForm.values.phone.replace(/[^0-9]+/g, "")}</p>
                <p>ارسال شد</p>
              </div>
           
           <div style={{marginTop:"40px",direction:"rtl",textAlign:"right"}}><label>کد تایید:</label></div>
           
           <div style={{marginBottom:"10px"}}>
            <InputText 
            name="vCode" 
            id="vCode"
            value={vCodeForm.values.vCode}
            onChange={vCodeForm.handleChange} 
            label="ویرایش" 
            style={{borderColor:"#404040b8",borderRadius:"30px"}} 
            className={vCodeForm.errors["vCode"] ? "p-invalid" : null}
            />

             <small className="p-error">{vCodeForm.errors["vCode"]}</small>

            </div>
           
           <div style={{marginBottom:"40px"}}><Button classname="filled-button" type="submit" label="ادامه"  style={{width:"100%"}} /></div>
           </form>



            </div>
          </Dialog>




      <h2>ورود</h2>
      <p className="text-center">اگر حساب دارید وارد شوید</p>

      <form onSubmit={formik.handleSubmit}>
        <ul className="add_product_field">
          <li className="label">
            <label htmlFor="username">ایمیل یا شماره تلفن</label>
          </li>
          <li>
            {" "}
            <InputText
              id="username"
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("username"),
              })}
            />
            {getFormErrorMessage("username")}
          </li>
        </ul>

        <div style={{ marginTop: "2rem" }}>
          <ul className="add_product_field">
            <li className="label">
              <label htmlFor="password">رمز عبور</label>
            </li>
            <li>
              {" "}
              <Password
                inputId="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                toggleMask
                feedback={false}
                className={classNames({
                  "p-invalid": isFormFieldValid("password"),
                })}
              />
              {getFormErrorMessage("password")}
              {loginError!="" ? <small className="p-error">{loginError}</small> : null }
            </li>
          </ul>
        </div>

        <div
          style={{
            marginTop: "4rem",
            marginLeft: "0rem",
           
          }}
        >
          <Button type="submit" className="login-dialog filled-button" label="ورود" />
        </div>

        </form>

        <div
          style={{
            marginTop: "0rem",
            marginLeft: "0rem",
           
          }}
        >
          
          <Button className="login-dialog mobile-login" label="ورود با رمز یکبار مصرف" style={{background:"transparent !important",paddingRight:"0px",paddingLeft:"0px"}} onClick={()=>setStep(2)}/>

        </div>

      
    </div>
  );
}

export default Login;
