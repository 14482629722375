import { Dropdown } from 'primereact/dropdown';
import { useFormik } from "formik";
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

 

function NewGruop(props)
{
    const navigate = useNavigate();
    const groupeTypes=["عمومی","خصوصی"];
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const groupForm = useFormik({
        initialValues: {
          username:"",
          name: "",
          description: "",
          groupMode: "",
        },  
        validationSchema: Yup.object({

          groupMode: Yup.string()
            .required("نوع گروه الزامی است"),
          name: Yup.string()
            .required("نام گروه الزامی است"),
          username: Yup.string()
              .required('نام کاربری الزامی است')
              .test('Unique username', 'این نام کاربری موجود نیست', 
                  function (value) {
                  
                      return new Promise((resolve, reject) => {
                          axios.get(process.env.REACT_APP_BACK+"/pages/check-username/"+value, config)
                              .then((res) => {
                                if(res.data)
                                  resolve(true)
                                  else
                                  resolve(false)
                              })
                              .catch((error) => {
                                  if (error.response.data.content === "The email has already been taken.") {
                                      resolve(false);

                                      if(error.response.data.statusCode===401)
                                      navigate("/login")
                                  }
                              })
                      })
                  }
              ),
      }),
        onSubmit: (values) => {

          var data = {type:"group" ,creatorName:props.creatorName , creator_id:props.user_id ,username: values.username, name:values.name ,description:values.description, groupMode:values.groupMode};
        

          data=JSON.stringify(data);
     
          
          
       
        axios.post(process.env.REACT_APP_BACK+"/pages/create", data, config)
            .then(response => 
              {
                if (response.status == 202)
                {
                  //group owner is added to the group:
                  const data2 = JSON.stringify({ sender_id:props.user_id, sender_avatar:props.user_avatarPic, reciever_id:response.data.result._id, type:"follow"});
                  axios.post(process.env.REACT_APP_BACK+"/notifications/follow-page", data2, config);


                  console.log(response);
                  props.close();
                  navigate("/" + values.username + "/about", { state: {page: response.data.result, isPage: true , isUserPage:true , justCreated:true} })
                }
                  
            })
            .catch(error => {
               console.log(error);
               if(error.response.data.statusCode===401)
                navigate("/login")
            });
      
          
        },
      });
    
      const isFormFieldValid = (phone) =>
          !!(groupForm.touched[phone] && groupForm.errors[phone]);
        const getFormErrorMessage = (phone) => {
          return (
            isFormFieldValid(phone) && (
              <small className="p-error">{groupForm.errors[phone]}</small>
            )
          );
        };


    return(
        <div>
            <form onSubmit={groupForm.handleSubmit} className="p-fluid">

            <div className="two-columns">
              <div className="field col-one">
              <div>
              <label htmlFor="username">نام کاربری</label>
            </div>
            <InputText
            className={classNames({
              "p-invalid": isFormFieldValid("username"),
            })}
              id="username"
              name="username"
              value={groupForm.values.username}
              onChange={groupForm.handleChange}
            />
            {getFormErrorMessage("username")}
              </div>

              <div className="field col-two">
              <div>
              <label htmlFor="name">نام گروه</label>
            </div>
            <InputText
            className={classNames({
              "p-invalid": isFormFieldValid("name"),
            })}
              id="name"
              name="name"
              value={groupForm.values.name}
              onChange={groupForm.handleChange}
              placeholder="مثلاً: شاگردان استاد حیدری"
            />
            {getFormErrorMessage("name")}
              </div>
            </div>

          <div className="field">
            <div>
              <label htmlFor="description">توضیح گروه</label>
            </div>
            <InputTextarea
              id="description"
              name="description"
              value={groupForm.values.description}
              onChange={groupForm.handleChange}
            />
          </div>

          <div className="field">
            <div>
              <label htmlFor="groupMode">نوع گروه</label>
            </div>
            <Dropdown
            className={classNames({
              "p-invalid": isFormFieldValid("groupMode")})}
              id="groupMode"
              name="groupMode"
              onChange={groupForm.handleChange}
              value={groupForm.values.groupMode}
              options={groupeTypes}
              placeholder="انتخاب کنید"
              
            />
            {getFormErrorMessage("groupMode")}
          </div>

          <div style={{display:"inline flow-root list-item", textAlign:"justify",marginTop:"15px", fontSize:"14px"}}>
           <strong>گروه های عمومی </strong>
             در نتایج جستجو یافت می شوند و و اعضای آن می توانند افراد مورد علاقه خود را به گروه دعوت کنند. همچنین همه افراد به پست ها و اعضا دسترسی دارند.
          </div>

          <div style={{display:"inline flow-root list-item", textAlign:"justify",marginTop:"10px", fontSize:"14px"}}>
          <strong>گروه های خصوصی </strong>
            
                در نتایج جستجو یافت نمی شوند ولی پست های گروه فقط برای اعضا قابل مشاهده است. کاربران برای عضویت در گروه باید درخواست ارسال کنند و پس از تایید مدیر گروه عضو خواهند شد.
          </div>

          <div className="field">
              
                <span>
                  <Button type="submit" label="ذخیره" className="mt-2" />
                </span>
              

            </div>

        </form>
        </div>
    );

}

export default NewGruop;