import axios from "axios";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ContextMenu } from "primereact/contextmenu";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";


function ManageNotifs(props)
{

    const [notifs,setNotifs] = useState([]);
    const [updateNotifs,setUpdateNotifs] = useState(0);
    const [selectedNotif, setSelectedNotif] = useState({content:"",date:"",type:"",sender_id:"",reciever_id:""});
    const cm = useRef(null);
    const toast = useRef(null);
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
    const navigate = useNavigate();
    const menuModel = [
        {label: 'حذف', icon: 'pi pi-fw pi-trash', command: () => setShowConfirmDelete(true)}
    ];

    const regDateBodyTemplate = (rowData) => {
        return new Date(rowData.date).toLocaleDateString('fa-IR');
    };

    const typeBodyTemplate = (rowData) => {
        if(rowData.type==="message")
            return "پیام";
        else if(rowData.type==="like")
            return "لایک";
        else if(rowData.type==="follow")
            return "فالو";
        else if(rowData.type==="save")
            return "ذخیره";
        else if(rowData.type==="comment")
            return "کامنت";
        else
            return "";
    }

    const senderBodyTemplate = (rowData) => {
        return  rowData.sender_id !== undefined && rowData.sender_id !== null? 
        
        <span style={{display: desktop ? "table" : "inherit",margin: desktop ? "auto" : "inherit",marginTop: "auto"}}>
        
        <Link to={"/"+  rowData.sender_id.username +"/about"}
            state={{otherUser: {_id:rowData.sender_id._id, username:rowData.sender_id.username, name:rowData.sender_id.name , job:rowData.sender_id.job , avatarPic:rowData.sender_id.avatarPic} 
            , profileOfOther:true}} 
            style={{display: "flex",alignItems: "center"}}>
                <img src={`${process.env.REACT_APP_BACK}/avatars/${rowData.sender_id.avatarPic}`}
        style={{height:"50px",width:"50px",borderRadius:"50%"}}></img>
        <p style={{fontSize:"14px",marginRight:"10px"}}>
        {rowData.sender_id.username}
        </p>

        </Link>
        </span>

        : 
        null
    ;
    }

    const recieverBodyTemplate = (rowData) => {
        return  rowData.reciever_id !== undefined && rowData.sender_id !== null ? 
        
        <span style={{display: desktop ? "table" : "inherit",margin: desktop ? "auto" : "inherit",marginTop: "auto"}}>
        {rowData.reciever_id!==null ?

        <Link to={"/"+  rowData.reciever_id.username +"/about"}
            state={{otherUser: {_id:rowData.reciever_id._id, username:rowData.reciever_id.username, name:rowData.reciever_id.name , job:rowData.reciever_id.job , avatarPic:rowData.reciever_id.avatarPic} 
            , profileOfOther:true}} 
            style={{display: "flex",alignItems: "center"}}>
                <img src={`${process.env.REACT_APP_BACK}/avatars/${rowData.reciever_id.avatarPic}`}
        style={{height:"50px",width:"50px",borderRadius:"50%"}}></img>
        <p style={{fontSize:"14px",marginRight:"10px"}}>
        {rowData.reciever_id.username}
        </p>

        </Link>

        :
        <span
        style={{display: "flex",alignItems: "center"}}>
        <img src={`${process.env.REACT_APP_BACK}/avatars/deletedAcount-index.png`}
        style={{height:"50px",width:"50px",borderRadius:"50%"}}>
        </img>
        <p style={{fontSize:"14px",marginRight:"10px"}}>کاربر حذف شده</p>
        </span>

        }
        </span>

        : 
        null
    ;
    }


    const deleteNotifFromDB = async (_id) => 
    {
      await axios.delete(process.env.REACT_APP_BACK+"/notifications/delete-notif/" + _id, config)
      .then(response => 
        {
          if (response.status == 200)
          {
            console.log(response);      
            toast.current.show({severity: 'info', summary: 'اعلان حذف شد'});
            setUpdateNotifs(updateNotifs+1);    
            
          }

      })
      .catch(error => {
         console.log(error);
         if(error.response.data.statusCode===401)
              navigate("/login")
      });
    };

   

    //get all notifs from database:
    useEffect(() => {
    async function getAllNotifs() {

      
      const result = await axios.get(process.env.REACT_APP_BACK+"/notifications/all/notifs", config)
      .then((result) => {
        if (result.status == 200) {
          setNotifs(result.data);
          console.log("all notifs",result.data);
          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
      return result.data;

    }
    getAllNotifs();
  },[updateNotifs]);

  
    return(

        <div id="manage-pages">

            <h2>
            مدیریت اعلان ها
            </h2>

            <Toast ref={toast}></Toast>

            <ContextMenu model={menuModel} ref={cm} />

            <DataTable size="normal" value={notifs} paginator rows={20} editMode="row"
            className="manager-table"
            contextMenuSelection={selectedNotif}
            onContextMenuSelectionChange={e => setSelectedNotif(e.value)}
            onContextMenu={e => cm.current.show(e.originalEvent)}>
            
            
            <Column alignHeader="center" columnKey="type" align="center" field="type" header="نوع"  body={typeBodyTemplate} sortable  ></Column>
            <Column alignHeader="center" columnKey="creator" align="center"  header="فرستنده" body={senderBodyTemplate}  filter></Column>
            <Column alignHeader="center" columnKey="creator" align="center"  header="گیرنده" body={recieverBodyTemplate}  filter></Column>
            <Column alignHeader="center" columnKey="content" align="center" field="content" header="محتوا" filter></Column>
            <Column alignHeader="center" columnKey="date" align="center" field="date" header="تاریخ " body={regDateBodyTemplate} sortable></Column>
            
            
            
            </DataTable>


            <ConfirmDialog 
            className={props.theme}
            visible={showConfirmDelete} 
            onHide={() => setShowConfirmDelete(false)} 
            message=" آیا از حذف این اعلان مطمئنید؟  (فقط اعلان حذف می شود، لایک، فالو، ذخیره یا پیام حذف نمی شود) "
            header="تایید حذف"
            icon="pi pi-info-circle"
            acceptLabel="بله"
            rejectLabel="نه"
            acceptClassName="accept"
            rejectClassName="reject"
            accept={() => deleteNotifFromDB(selectedNotif._id)} 
            reject={() => setShowConfirmDelete(false)} />

        </div>
    );
}
  
export default ManageNotifs;