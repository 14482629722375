import "../Css/NewPost.css";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import "../Css/VideoImageUploader.css";
import Chip from "./Chip";
import { useLocation, useNavigate } from "react-router-dom";
import { Editor } from 'primereact/editor';
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { transparent } from "material-ui/styles/colors";
import { Toast } from "primereact/toast";

const token = JSON.parse(localStorage.getItem('userToken'));
const config = {  headers: {"Content-Type": "application/json", Authorization: `Bearer ${token}`} };
const user = JSON.parse(localStorage.getItem('flickerNetwork') != null) ? JSON.parse(localStorage.getItem('flickerNetwork')) : {username:"" , name:"", avatarPic:"profile-index.png"};


function EditArticle() {
    const location = useLocation();
    const [article,setArticle] = useState();
    const [selectedKeywords, setSelectedKeywords] = useState();
    const [selectedRiderPr, setSelectedRiderPr] = useState();
    const [selectedHorsePr, setSelectedHorsePr] = useState();
    const [selectedLinksToArticles,setSelectedLinksToArticles] = useState();
    const [selectedLinksToTags,setSelectedLinksToTags] = useState();
    const [showTags, setShowTags] = useState(false);
    const [showTagUsers, setShowTagUsers] = useState(false);
    const [isImageEdited,setIsImageEdited] = useState(false);
    const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
    const inputRef = React.useRef();
    const [source, setSource] = useState();
    const [uploadFile, setUploadFile] = useState("");
    const [fileType, setFileType] = useState();
    const navigate = useNavigate();
    const editorRef = useRef(null);
    const toast = useRef(null);
    const [internalLinksCreated,setInternalLinksCreated]= useState(false);
    

     // fetch single article:
     useEffect(() => {
      


        async function fetchSingleArticle() {

            
          await axios.get(process.env.REACT_APP_BACK+"/articles/"+location.state.article._id, config)
            .then(async(result) => {
              if (result.status == 200) {
                
                await setArticle(result.data);
                setSelectedKeywords(result.data.secondryKeywords);
                setSelectedHorsePr(result.data.horseProductsID);
                setSelectedRiderPr(result.data.riderProductsID);
                setSelectedLinksToArticles(result.data.internalLinkToArticles);
                setSelectedLinksToTags(result.data.internalLinkToTags);
                setSource(`${process.env.REACT_APP_BACK}/articles/${result.data.indexedImage}`);
                setInternalLinksCreated(result.data.internalLinksCreated);
        
                formik.setValues({
                    title: result.data.title,
                    content: result.data.content,
                    riderPr: "",
                    horsePr: "",
                    metaDescription: result.data.metaDescription,
                    keyword:result.data.keyword, 
                    file:result.data.indexedImage,
                    productKeyword:result.data.productKeyword,
                    indexedImage:result.data.indexedImage,
                    internalLinkToTags:result.data.internalLinkToTags,
                    internalLinkToArticles:result.data.internalLinkToArticles,
                });

         
            
                return result.data;
              }
            })
            .catch((error) => {
              console.log(error);
              if(error.response.data.statusCode===401)
                 navigate("/login")
            });
  
        }
        
            fetchSingleArticle();
        
        
      
    }, []);

    //auto complete secondry keywords:
    const findSecondryKeywords = async (keyword) =>
        {
            await axios.get(process.env.REACT_APP_BACK+"/sitemap/find_secondry_keywords/"+keyword, config)
                .then((result) => {
                  if (result.status == 200) {

                    let secondryKeywordsString=result.data.join('-');
                    formik.setFieldValue("secondryKeywords" , secondryKeywordsString);
                      
                            
                    return result.data;
                  }
                })
                .catch((error) => {
                  console.log(error);
                  if(error.response.data.statusCode===401)
                    navigate("/login")
                })
        }

        //making internal links to 2 tags and 1 article:
        const createInternalLinks = async () => {
            // Where to put the link:
            let numberOfParaghraph = 1;
            let content = article.content; // Start with the initial content
        
            // Process tags:
            for (const tag of selectedLinksToTags.slice(0, 2)) {
                try {
                    const result = await axios.get(`${process.env.REACT_APP_BACK}/posts/tags/addInternalLinkTo/${tag}`, config);
                    if (result.status === 200) {
                        const keyword = result.data.keyword;
                        content = addLinkToContent(content,keyword, `/tags/${tag}`, numberOfParaghraph);
                        numberOfParaghraph+=2;
                    }
                } catch (error) {
                    console.log(error);
                    if (error.response?.data?.statusCode === 401) {
                        navigate("/login");
                        return;
                    }
                }
            }
        
            // Process articles:
            for (const article of selectedLinksToArticles.slice(0, 1)) {
                try {
                    const result = await axios.get(`${process.env.REACT_APP_BACK}/articles/addInternalLinkTo/${article}`, config);
                    if (result.status === 200) {
                        const keyword = result.data.keyword;
                        const article_id = result.data._id;
                        content = addLinkToContent(content,keyword, `/weblog/${article_id}`, numberOfParaghraph);
                        numberOfParaghraph+=2;
                        toast.current.show({ severity: 'info', summary: "لینک های داخلی به متن مقاله اضافه شد" });
                    }
                } catch (error) {
                    console.log(error);
                    if (error.response?.data?.statusCode === 401) {
                        navigate("/login");
                        return;
                    }
                }
            }
        
            // Update the form content once after all processing
            formik.setFieldValue("content", content);
            setInternalLinksCreated(true);
        };
        
        
        const addLinkToContent = (content,keyword, link, numberOfParagraph) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(content, 'text/html');
            const paragraphs = doc.querySelectorAll('p');
        
            paragraphs.forEach((p, index) => {
                
                if ((index + 1) === numberOfParagraph) {
                    // Create an <a> element and set its attributes
                    const anchor = document.createElement('a');
                    anchor.href = link;
                    anchor.textContent = "[" + keyword + "]";
        
                    // Add a space and then append the <a> element to the paragraph
                    p.appendChild(document.createTextNode(' '));
                    p.appendChild(anchor);
                    console.log("number of p:",numberOfParagraph);
                
                }
            });
        
            return doc.body.innerHTML;
        };
        


    const colors = [
        'rgb(0, 0, 0)', 'rgb(230, 0, 0)', 'rgb(255, 153, 0)', 'rgb(255, 255, 0)', 'rgb(0, 138, 0)', 
        'rgb(0, 102, 204)', 'rgb(153, 51, 255)', 'rgb(255, 255, 255)', 'rgb(250, 204, 204)', 
        'rgb(255, 235, 204)', 'rgb(255, 255, 204)', 'rgb(204, 232, 204)', 'rgb(204, 224, 245)', 
        'rgb(235, 214, 255)', 'rgb(187, 187, 187)', 'rgb(240, 102, 102)', 'rgb(255, 194, 102)', 
        'rgb(255, 255, 102)', 'rgb(102, 185, 102)', 'rgb(102, 163, 224)', 'rgb(194, 133, 255)', 
        'rgb(136, 136, 136)', 'rgb(161, 0, 0)', 'rgb(178, 107, 0)', 'rgb(178, 178, 0)', 'rgb(0, 97, 0)', 
        'rgb(0, 71, 178)', 'rgb(107, 36, 178)', 'rgb(68, 68, 68)', 'rgb(92, 0, 0)', 'rgb(102, 61, 0)', 
        'rgb(102, 102, 0)', 'rgb(0, 55, 0)', 'rgb(0, 41, 102)', 'rgb(61, 20, 102)'
    ];

    const createColorOptions = (colors) => colors.map(color => (
        <option key={color} value={color} style={{ backgroundColor: color }}></option>
    ));

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setUploadFile(file);
        setIsImageEdited(true);
        if (file.type.includes("image")) setFileType("image");
        else if (file.type.includes("video")) setFileType("video");
        const url = URL.createObjectURL(file);
        setSource(url);
    };

    const handleChoose = (event) => {
        inputRef.current.click();
    };

    //supporting webp:
    const imageHandler = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const quill = editorRef.current.getQuill();
                    const range = quill.getSelection();
                    quill.insertEmbed(range.index, 'image', e.target.result);
                };
                reader.readAsDataURL(file);
            }
        };
    };

    

    const toolbarOptions = (
        <div id="toolbar">
            <select className="ql-header" defaultValue="">
                <option value="1">h1</option>
                <option value="2">h2</option>
                <option value="3">h3</option>
                <option value="">p</option>
            </select>
            <button className="ql-bold"></button>
            <button className="ql-code"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <select className="ql-align">
                <option value="">Left</option>
                <option value="center">Center</option>
                <option value="right">Right</option>
            </select>
            <button className="ql-strike"></button>
            <button className="ql-list" value="ordered"></button>
            <button className="ql-list" value="bullet"></button>
            <button className="ql-link"></button>
            <button className="ql-image"></button>
            
            <select className="ql-color">
                {createColorOptions(colors)}
            </select>
            <select className="ql-background">
                {createColorOptions(colors)}
            </select>
        </div>
    );

    const addAltToImages = (content) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const images = doc.querySelectorAll('img');
        images.forEach((img) => {
            // if (!img.hasAttribute('alt')) {
                img.setAttribute('alt', formik.values.title+"- فلیکا، شبکه اسب دوستان");
            // }
        });
        return doc.body.innerHTML;
    };


    const updateSecondryKeyword = async (event) =>
    {
        if (event.key === 'Enter') {

            //prevent form submitting:
            event.preventDefault(); 
            event.stopPropagation(); 

            let newsecondryKeywords = formik.values["secondryKeywords"].split('-').map(element => ({
                [element.trim()]: 0
              }));
    
            
              const data = selectedKeywords.concat(newsecondryKeywords);
              await axios.patch(process.env.REACT_APP_BACK + "/articles/updateKeywords/"+article._id, data, config)
                .then((res) => {
                    setSelectedKeywords(data);
                    formik.setFieldValue("secondryKeywords","");
                })
                .catch((error) => {
                    if (error.response.data.statusCode === 401)
                        navigate("/login")
                });
              

            
        }
        
    }

        const deleteSecondryKeyword = async(keyword) =>
        {
           
                    const data = selectedKeywords.filter((k) => {
                        const keyName = Object.keys(k)[0]; // Get the dynamic key name
                        return keyName !== keyword;
                    });
                  
                  await axios.patch(process.env.REACT_APP_BACK + "/articles/updateKeywords/"+article._id, data, config)
                    .then((res) => {
                        
                        setSelectedKeywords(data);
                        
                    })
                    .catch((error) => {
                        if (error.response.data.statusCode === 401)
                            navigate("/login")
                    });
            
            
        }

    const formik = useFormik({
        initialValues: {
            title: article?.title,
            content: article?.content,
            riderPr: "",
            horsePr: "",
            metaDescription: article?.metaDescription,
            keyword:article?.keyword, 
            file:article?.indexedImage,
            productKeyword:article?.productKeyword,
            indexedImage:article?.indexedImage,
            internalLinkToTags:article?.internalLinkToTags,
            internalLinkToArticles:article?.internalLinkToArticles,
        },
        validate: (values) => {
            let errors = {};
            if (!values.title) {
                errors.title = "لطفا تیتر مقاله را وارد کنید";
            }
            if (!values.content) {
                errors.content = "لطفا محتوای مقاله را وارد کنید";
            }
            if (!source) {
                errors.file = "لطفا تصویر شاخص مقاله را وارد کنید";
            }
            return errors;
        },
        onSubmit: (values) => {
            values["content"] = addAltToImages(values.content);
            values["riderPr"] = selectedRiderPr.map(element => element._id.trim());
            values["horsePr"] = selectedHorsePr.map(element => element._id.trim());
            values["internalLinkToTags"] = selectedLinksToTags;
            values["internalLinkToArticles"] = selectedLinksToArticles;
         
            submitPost(uploadFile,values);
            return;
        },
    });

    const isFormFieldValid = (phone) => !!(formik.touched[phone] && formik.errors[phone]);
    const getFormErrorMessage = (phone) => {
        return (
            isFormFieldValid(phone) && (
                <p className="p-error" style={{textAlign:'right'}}>{formik.errors[phone]}</p>
            )
        );
    };

    const uploadIndexedImage = async (uploadFile,values) =>
    {
        //upload file:
        let formData = new FormData();
        formData.append("file", uploadFile);

        const c = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        await axios.post(process.env.REACT_APP_BACK + "/articles/uploadFile", formData, {c})
            .then((res) => {
                    values["indexedImage"] = res.data.fileName;
            })
            .catch((error) => {
                if (error.response.data.statusCode === 401)
                    navigate("/login")
            });

    }

    const submitPost = async (uploadFile,values) => {

        //if user edited indexed image:
        if(isImageEdited)
            await uploadIndexedImage(uploadFile,values);

        


        var data = {
            title: values.title,
            content: values.content,
            riderProductsID: values.riderPr,
            horseProductsID: values.horsePr,
            metaDescription: values.metaDescription,
            keyword:values.keyword,
            indexedImage : values["indexedImage"],
            productKeyword:values.productKeyword,
            secondryKeywords:selectedKeywords,
            internalLinkToTags: values["internalLinkToTags"],
            internalLinkToArticles: values["internalLinkToArticles"],
            internalLinksCreated: internalLinksCreated? true : false

        };


        data = JSON.stringify(data);

        //creating article in database
        axios
            .patch(process.env.REACT_APP_BACK + "/articles/" + article._id,data, config)
            .then((response) => {
                navigate("/weblog/"+article._id);
            })
            .catch((error) => {
                if (error.response.data.statusCode === 401)
                    navigate("/login");
            });
    }


    const addWatermark = () =>
    {
        axios.get(process.env.REACT_APP_BACK + "/articles/add_watermark/" + article._id, config)
            .then((response) => {
                console.log("response",response);
                navigate("/weblog/"+article._id);
            })
            .catch((error) => {
                if (error.response.data.statusCode === 401)
                    navigate("/login");
            });

    }

    const removewatermark = () =>
    {
        axios.get(process.env.REACT_APP_BACK + "/articles/remove_watermark/" + article._id, config)
            .then((response) => {
                navigate("/weblog/"+article._id);
            })
            .catch((error) => {
                if (error.response.data.statusCode === 401)
                    navigate("/login");
            });

    }

    const removeImagesbackup = () =>
    {
        axios.get(process.env.REACT_APP_BACK + "/articles/remove_image_backup/" + article._id, config)
            .then((response) => {
                navigate("/weblog/"+article._id);
            })
            .catch((error) => {
                if (error.response.data.statusCode === 401)
                    navigate("/login");
            });

    }

    return (
        <div id="new_article">
            <Toast ref={toast}></Toast>
            <form onSubmit={formik.handleSubmit} className="new-post-form">
                <div>
                    <h2 style={{ marginBottom: '50px' }}>ویرایش مقاله</h2>
                    
                    {getFormErrorMessage("title")}
                    <div style={{ direction: 'rtl', marginBottom: '30px', alignItems: 'center' }}>
                        <span style={{ display: "flex", width: "fit-content" }}>
                            عنوان مقاله :
                        </span>
                        <InputText
                            style={{ display: "flex", width: "100%" }}
                            id="title"
                            name="title"
                            placeholder="تیتر مقاله ..."
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            maxLength={100}
                        />
                    </div>

                    {getFormErrorMessage("keyword")}
                    <div style={{ direction: 'rtl', marginBottom: '30px', alignItems: 'center' }}>
                        <span style={{ display: "flex", width: "fit-content" }}>
                             کلمه کلیدی اصلی :
                        </span>
                        <InputText
                            style={{ display: "flex", width: "100%" }}
                            id="keyword"
                            name="keyword"
                            placeholder=" کلمه کلیدی ..."
                            value={formik.values.keyword}
                            onChange={formik.handleChange}
                            maxLength={50}
                        />
                    </div>

                    <div style={{ direction: 'rtl', marginBottom: '30px', alignItems: 'center' }}>
                        <span style={{ display: "flex", width: "fit-content" }}>
                           کلمات کلیدی فرعی:
                        </span>
                        <span style={{fontSize:"12px",float:'right',marginBottom:'10px'}}>(اعداد روبروی هر کلید فرعی، تعداد لینک داخلی به آن را نشان می دهند)</span>
                        
                        <span id="keyword-selections" >
                            
                            {selectedKeywords?.map((item,index) =>(
                                <span  key={index} className="user-item" style={{display:"flex",alignItems:"center",direction:"rtl", cursor:'auto'}}>
                                  <i className="pi pi-times-circle" style={{marginLeft:'5px',cursor:'pointer'}} onClick={()=>deleteSecondryKeyword(Object.keys(item)[0])}></i>
                                   {Object.keys(item) + ": "+ Object.values(item)}
                                </span>    
                            ))}

                        </span>
                        
                        
                        <InputText
                            style={{ display: "flex", width: "100%" }}
                            id="secondryKeywords"
                            name="secondryKeywords"
                            placeholder="با - جدا کنید ، با enter تایید کنید"
                            value={formik.values.secondryKeywords}
                            onChange={formik.handleChange}
                            onKeyDown={updateSecondryKeyword}
                            maxLength={1000}
                        />
                        <span 
                            style={{textAlign:"right", fontSize:'12px', color:"#af734f",display:"flex", marginTop:"10px", cursor:"pointer"}}
                            onClick={() => findSecondryKeywords(formik.values["keyword"])}    
                            >پیدا کردن خودکار کلمات کلیدی فرعی (از پیشنهادات گوگل) </span>
                    </div>

                    {getFormErrorMessage("metaDescription")}
                    <div style={{ direction: 'rtl', marginBottom: '30px', alignItems: 'center' }}>
                        <span style={{ display: "flex", width: "fit-content" }}>
                            توضیحات متا:
                        </span>
                        <textarea
                            style={{ display: "flex", width: "100%" }}
                            id="metaDescription"
                            name="metaDescription"
                            placeholder="توضیحات متا ..."
                            value={formik.values.metaDescription}
                            onChange={formik.handleChange}
                            maxLength={150}
                        />
                    </div>

                    {getFormErrorMessage("productKeyword")}
                    <div style={{ direction: 'rtl', marginBottom: '30px', alignItems: 'center' }}>
                        <span style={{ display: "flex", width: "fit-content" }}>
                           کلمه کلیدی برای محصولات مرتبط:
                        </span>
                        <InputText
                            style={{ display: "flex", width: "100%" }}
                            id="productKeyword"
                            name="productKeyword"
                            placeholder="کلمه کلیدی برای محصولات مرتبط ..."
                            value={formik.values.productKeyword}
                            onChange={formik.handleChange}
                            maxLength={50}
                        />
                    </div>


                    <div style={{ direction: 'rtl', marginBottom: '30px', alignItems: 'center' }}>
                        <span style={{ display: "flex", width: "fit-content" }}>
                           لینک داخلی به مقاله ها (مقاله های مرتبط):
                        </span>
                        <Chip
                                    id="internalLinkToArticles"
                                    inputId="internalLinkToArticles"
                                    name="internalLinkToArticles"
                                    type="internalLinkToArticles"
                                    setSelections={setSelectedLinksToArticles}
                                    selections={selectedLinksToArticles}
                                />
                               
                        
                    </div>

                    <div style={{ direction: 'rtl', marginBottom: '30px', alignItems: 'center' }}>
                        <span style={{ display: "flex", width: "fit-content" }}>
                           لینک داخلی به برچسب ها (برچسب های مرتبط):
                        </span>

                        
                        <Chip
                                    id="internalLinkToTags"
                                    inputId="internalLinkToTags"
                                    name="internalLinkToTags"
                                    type="internalLinkToTags"
                                    setSelections={setSelectedLinksToTags}
                                    selections={selectedLinksToTags}
                                />
                        
                    </div>

                    <div style={{textAlign:'right', marginBottom:'30px', direction:'rtl',marginBottom:'50px'}}>
                        
                        <span 
                         style={{cursor: !internalLinksCreated ?'pointer' : null , marginLeft:'10px' ,
                            backgroundColor: !internalLinksCreated ?'#af734f' : transparent , color: !internalLinksCreated ? '#ffffff' : "#af734f", 
                            border:"1px #af734f solid" , borderRadius:'10px' , padding:'10px'}}
                            onClick={!internalLinksCreated ? createInternalLinks : null}
                         >
                            {!internalLinksCreated ? "لینک سازی داخلی" : "لینک های داخلی ساخته شده" }
                           </span>
                        

                    </div>



                    <div style={{textAlign:'right', marginBottom:'30px', direction:'rtl'}}>
                        
                        {!article?.imagesHasWatermark && (<span onClick={addWatermark}
                         style={{cursor:'pointer' , marginLeft:'10px',color:'#af734f' ,border:"1px #af734f solid" , borderRadius:'10px' , padding:'10px'}}>اضافه کردن واترمارک به تصاویر</span>)}
                       
                        {article?.imagesHasBackup && article?.imagesHasWatermark  && (<span onClick={removewatermark}
                         style={{cursor:'pointer' , marginLeft:'10px' , color:"#af734f"  ,border:"1px #af734f solid" , borderRadius:'10px' , padding:'10px'}}>برداشتن واترمارک از تصاویر</span>)}
                       
                        {article?.imagesHasBackup && (<span onClick={removeImagesbackup} 
                        style={{cursor:'pointer' , marginLeft:'10px' , color:"#af734f ",border:"1px #af734f solid" , borderRadius:'10px' , padding:'10px'}}>حذف بکاپ تصاویر</span>)}

                    </div>

                    {getFormErrorMessage("file")}
                    <div className="media-upload">
                        <div className="VideoInput">
                            <input
                                id="file"
                                name="file"
                                hidden={true}
                                ref={inputRef}
                                className="VideoInput_input"
                                type="file"
                                onChange={(e) => {
                                    handleFileChange(e);
                                    formik.setFieldValue("file", e.currentTarget.files[0]);
                                }}
                                accept=".jpg, .png, .jpeg , .webp"
                            />
                            <span id="upload-buttons">
                                <span style={{ display: "flex", width: "60%", alignItems: "center" }}>
                                    <i className="pi pi-image"></i>
                                    <p onClick={handleChoose}>آپلود عکس شاخص</p>
                                </span>
                                <span style={{ display: "flex", width: "40%", direction: "ltr", alignItems: "center" }}>
                                    <p id="delete-button" onClick={() => setSource(null)}><i className="pi pi-times" />حذف</p>
                                </span>
                            </span>
                            {source && (
                                <img
                                    className="VideoInput_video"
                                    width="100%"
                                    height="100%"
                                    src={source}
                                    
                                />
                            )}
                        </div>
                    </div>

                    <span id="content">
                        <Editor
                            ref={editorRef}
                            headerTemplate={toolbarOptions}
                            id="content"
                            name="content"
                            value={formik.values.content}
                            onTextChange={(e) => formik.setFieldValue('content', e.htmlValue)}
                            modules={{
                                toolbar: {
                                    container: "#toolbar",
                                    handlers: {
                                        image: imageHandler
                                    }
                                }
                            }}
                            style={{ height: '500px' }}
                            placeholder="محتوای مقاله ..."
                            rows={1}
                            cols={3}
                        />
                        {getFormErrorMessage("content")}
                        <div className="tag-user" style={{ marginTop: "20px", direction: "rtl", textAlign: "right", display: "flex", alignItems: "center" }}>
                            <span style={{ display: "flex", width: "30%", alignItems: 'center', cursor: 'pointer', color: '#af734f' }} onClick={() => { setShowTagUsers(false); setShowTags(!showTags); }}>
                                <i className="pi pi-tag" style={{ marginLeft: '10px' }}></i>محصولات سوار
                            </span>
                            <span style={{ display: "flex", width: "30%", alignItems: 'center', cursor: 'pointer', color: '#af734f' }} onClick={() => { setShowTags(false); setShowTagUsers(!showTagUsers); }} >
                                <i className="pi pi-user" style={{ marginLeft: '10px' }}></i>محصولات اسب
                            </span>
                            <span style={{ display: "flex", direction: "ltr", width: "70%" }}>
                                <span>
                                    <Button id={"is-not-uploading"} type="submit">ثبت تغییرات</Button>
                                </span>
                            </span>
                        </div>
                        <div>
                            <div id="tags-area" style={{ height: !showTags ? "0px" : null }}>
                                <h6 style={{ height: "20%", textAlign: "right", marginBottom: "0px", marginTop: "20px" }}>
                                    :محصولات سوار مرتبط
                                </h6>
                                <Chip
                                    id="riderPr"
                                    inputId="riderPr"
                                    name="riderPr"
                                    type="riderPr"
                                    setSelections={setSelectedRiderPr}
                                    selections={selectedRiderPr}
                                />
                            </div>
                            <div id="tag-users-area" style={{ height: !showTagUsers ? "0px" : null }}>
                                <h6 style={{ height: "20%", textAlign: "right", marginBottom: "0px", marginTop: "20px" }}>
                                    :محصولات اسب مرتبط
                                </h6>
                                <Chip
                                    id="horsePr"
                                    inputId="horsePr"
                                    name="horsePr"
                                    type="horsePr"
                                    setSelections={setSelectedHorsePr}
                                    selections={selectedHorsePr}
                                />
                            </div>
                        </div>
                    </span>
                </div>
            </form>
        </div>
    );
}

export default EditArticle;
