import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate , Link } from "react-router-dom";
import Tag from "../components/Tag";
import "../Css/HomePage.css";


function AllTags()
{

    const [fetchedTags,setFetchedTags] = useState([]);
    const navigate = useNavigate();
    const config = {  headers: {"Content-Type": "application/json"} };
    // Helper function to chunk the tags into groups of 10
    const chunkTags = (tags, size) => {
        const chunked = [];
        for (let i = 0; i < tags.length; i += size) {
            chunked.push(tags.slice(i, i + size));
        }
        return chunked;
    };

    const chunkedTags = chunkTags(fetchedTags, 10);
    
    
    
   //fetching tags:
   useEffect(() => {
    async function fetchtags() {
    
      
      
        axios
          .get(process.env.REACT_APP_BACK+"/posts/tags/", config)
          .then((result) => {
            if (result.status == 200) {
              setFetchedTags(result.data);
              console.log("fetched tags: ", result.data);

              return result.data;
            }
          })
          .catch((error) => {
            console.log(error);
            if(error.response.data.statusCode===401)
                  navigate("/login")
          });


          
      
    }

    fetchtags();
  }, []);
    
    
    return(
        <div className="alltags">

<div className="search-tags">
        <h2>!هر چیز که در جستن آنی، آنی</h2>

        <div className="tags">
                    {chunkedTags.map((tagGroup, groupIndex) => (
                        <ul className="section1" key={groupIndex}>
                            {tagGroup.map((tag, index) => (
                                <Link key={index} to={"/tags/" + tag.value}>
                                    <li>
                                        <Tag value={tag.value} />
                                    </li>
                                </Link>
                            ))}
                        </ul>
                    ))}
                </div>

      
      </div>

        </div>
    );
}

export default AllTags;