import "../Css/Profile.css";
import { Dialog } from "primereact/dialog";
import SuggestionRow from "./SuggestionRow";
import { useEffect, useState } from "react";
import "../Css/SuggestionBox.css";
import { Divider } from "primereact/divider";
import { TabView, TabPanel } from "primereact/tabview";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import "../Css/Feed.css";
import Post from "./Post";
import NewPost from "./NewPost";
import "../Css/Register.css";
import SocialForm from "./SocialForm";
import EditProfileForm from "./EditProfileForm";
import "react-image-crop/dist/ReactCrop.css";
import axios from "axios";
import CropCoverPic from "./CropCoverPic";
import CropAvatarPic from "./CropAvatarPic";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Helmet } from "react-helmet";
import Lottie from 'react-lottie';
import fireworks from '../assests/animation_lk9ixjes.json';






function Profile(props) {


  const user = JSON.parse(localStorage.getItem('flickerNetwork') != null) ? JSON.parse(localStorage.getItem('flickerNetwork')) : {username:"" , name:"", avatarPic:"profile-index.png"};
  const [fetchedUser, setFetchedUser] = useState(user);
  const [fetchedPosts, setFetchedPost] = useState([]);
  const [fetchedFollowers, setFetchedFollowers] = useState([]);
  const [fetchedFollowings, setFetchedFollowings] = useState([]);
  const [showFollowers, setShowFollowers] = useState(false);
  const [showFollowings, setShowFollowings] = useState(false);
  const [followersHeader, setFollowersHeader] = useState("دنبال شده ها");
  const [showNewPostDialog, setShowNewPostDialog] = useState(false);
  const [showEditAvatar, setShowEditAvatar] = useState(false);
  const [showEditCover, setShowEditCover] = useState(false);
  const [showEditProfileDialog, setShowEditProfileDialog] = useState(false);
  const [showEditSocialDialog, setShowEditSocialDialog] = useState(false);
  const [catDialog, setCatDialog] = useState(false);
  const [profileUpdated, setProfileUpdated] = useState(0);
  const [updatePosts, setUpdatePosts] = useState(true);
  const [avatarPic, setAvatarPic] = useState("profile-index.png");
  const [cropCover,setCropCover] = useState(false);
  const [cropAvatar,setCropAvatar] = useState(false);
  const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
  const navigate = useNavigate();
  const [postsLoading, setPostsLoading] = useState(false);
  const [uploadProgress,setUploadProgress] = useState(0);
  const token = JSON.parse(localStorage.getItem('userToken'));
  const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
  const [lazyLoading, setLazyLoading] = useState(false);
  const [showBadge,setShowBadge] = useState(false);
  const [theme,setTheme] = useState();

  const coverStyle={
    backgroundImage: ` url("${process.env.REACT_APP_BACK}/covers/${fetchedUser["coverPic"]}")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
};
const online = JSON.parse(localStorage.getItem('flickerNetwork')) != null ? "online" : "offline";

const fireworkslottieOptions = {
  loop: 3,
  autoplay: true,
  animationData: fireworks,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
  isClickToPauseDisabled:true 
  
};

//get user theme:
useEffect(() =>
{
  const fetchTheme = async () =>
  {
        await axios
          .get(process.env.REACT_APP_BACK+"/settings/theme/"+user._id, config)
          .then((result) => {
            if (result.status == 200) {
              setTheme(result.data);
              
              
              console.log("theme: ", result.data);
              
              
            }
          })
          .catch((error) => {
            console.log(error);
            if(error.response.data.statusCode===401)
              navigate("/login")
          });

    }

    
      fetchTheme();
}  
,[]);




const closeCropPic = () => {
  if(cropAvatar)
    setCropAvatar(false);
  else if(cropCover)
    setCropCover(false);

    //update page:
    setProfileUpdated(profileUpdated + 1);
};

const closeNewPost = (uploadFile,fileType,values,type) => {
  submitPost(uploadFile,fileType,values,type);
  setShowNewPostDialog(false);
  setUpdatePosts(true)
};

  const closeEditProfile = () => {
    setShowEditProfileDialog(false);
  };
  const updateProfile = () => {
    setProfileUpdated(profileUpdated + 1);
  };
  const closeEditSocial = () => {
    setShowEditSocialDialog(false);
  };

  const submitPost = (uploadFile,fileType,values,type) =>
  {
    //uploading file
    let formData = new FormData();
    formData.append("file", uploadFile);

    const c = {
      headers: {
        "Contetnt-Type": "multipart/form-data",
      },
    };
    
    axios
      .post(process.env.REACT_APP_BACK+"/posts/uploadFile", formData, { c
      ,
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setUploadProgress(progress);
      },
      onDownloadProgress: (progressEvent) => {
    
      },
      })
      .then((res) => {
        //Now do what you want with the response;

        let fileName = res.data.fileName;


        //save tags:
        var t = values.tags.split(" ");
        for(const index in t)
        {
          //remove empty elements from array:
          if(t[index]==="")
            t.splice(index, 1);

          //remove # from each element:
          if(t[index]!=undefined)
          {
            t[index] = t[index].substring(1);
            t[index].trim();
          }
         

        }

        const data = JSON.stringify({
          
          description: values.description,
          category: values.category,
          isProduct:false,
          file:fileName,
          creator: user._id,
          creatorAvatar:user.avatarPic,
          creatorJob:user.job,
          creatorName:user.name,
          creatorUsername:user.username,
          accountType:user.accountType,
          type:type,
          fileType:fileType,
          tags:t
        });

        //creating post in database
     

        axios
          .post(process.env.REACT_APP_BACK+"/posts", data, config)
          .then((response) => {
            console.log(response);


            if(response.data.roset1)
                  setShowBadge(true);

            setUpdatePosts(true);

            
          })
          .catch((error) => {
            console.log("error",error);
            if(error.response.data.statusCode===401)
              navigate("/login")
          });
      })
      .catch((error) =>
      {
        if(error.response.data.statusCode===401)
              navigate("/login")
      });

  }


  //set user avatar:
  useEffect(
    () => {
      setAvatarPic(fetchedUser["avatarPic"] != null ? fetchedUser["avatarPic"] : "profile-index.png");
    }
    , [fetchedUser]
  );

  //fetch user information:
  useEffect(() => {
    async function fetchUser() {

      console.log("profileUpdated",profileUpdated);

      
      const result = await axios.get(process.env.REACT_APP_BACK+"/users/profile/" + user.username, config);
      setFetchedUser(result.data);


      console.log("fetched user: ");
      console.log(result);
      return result.data;

    }
    setFetchedUser(fetchUser());
  }, [profileUpdated]);

//setFeedLoading(true):
useEffect(() => {
  async function feedLoading() {

    setPostsLoading(true);
    
}
    
if(props.profileTab == 1)
  feedLoading(); 

},[]);


//fetch user posts:
useEffect(() => {
  async function fetchPosts() {

    let heightIndex;
    let offset = 10;

    //fetch first group:
    const result = await axios.get(process.env.REACT_APP_BACK+"/posts/posts-of/" + user._id+"/"+offset, config)
        .then((result) => {
          if (result.status == 200) {

            setFetchedPost(result.data);
            setPostsLoading(false);
            console.log("fetched posts: ",result.data);
            
            return result.data;
          }
        })
        .catch((error) => {
          console.log(error);
          if(error.response.data.statusCode===401)
              navigate("/login")
        });




        window.addEventListener('scroll', async function() {

          const { clientHeight, scrollTop, scrollHeight } = document.documentElement;

          if (scrollTop + clientHeight >= scrollHeight) {

            
            offset += 10;
            await setLazyLoading(true);
            

            axios.get(process.env.REACT_APP_BACK+"/posts/posts-of/" + user._id+"/"+offset, config)
            .then((result) => {
              if (result.status == 200) {

                setFetchedPost(prevArray => [...prevArray, ... result.data]);
                setPostsLoading(false);
                console.log("fetched posts: ",result.data);

                return result.data;


              }
            })
            .catch((error) => {
              console.log(error);
              if(error.response.data.statusCode===401)
              navigate("/login")
            });
              
          

            setLazyLoading(false);
            
            

          

        
          }
        });



  }

  if(props.profileTab == 1 && updatePosts)
  {
    
    fetchPosts();
    setUpdatePosts(false);
  }

}, [props,updatePosts]);

//fetch user followers:
useEffect(() => {
  async function fetchFollowers() {
    
    const result = await axios.get(process.env.REACT_APP_BACK+"/notifications/followers/" + user._id, config);
    setFetchedFollowers(result.data);

    const r = result.data.filter(item => item => item !== null && item!==undefined &&  item.type !== "group");
    setFetchedFollowers(r);
    console.log("fetched followers: ",r);
    return r;
    
}

if(showFollowers)
  fetchFollowers();
}
  , [showFollowers]
);

//fetch user followings:
useEffect(() => {
  async function fetchFollowings() {
    
    const result = await axios.get(process.env.REACT_APP_BACK+"/notifications/followings/" + user._id, config);

    const r = result.data.filter(item => item !== null && item!==undefined && item.type !== "group");
    setFetchedFollowings(r);
    console.log("fetched followings: ",r);
    return r;
    
}

if(showFollowings)
  fetchFollowings();
}
  , [showFollowings]
);


  const editItems = [
    {
      label: "ویرایش",
      icon: "pi pi-pencil",
    },
    {
      label: "حذف",
      icon: "pi pi-times",
    },
  ];

  const tabHandler = (e) => {
    if (e == 0)
      navigate("/" + fetchedUser["username"] + "/about", {
        state: { user: fetchedUser , profileOfOther: props.profileOfOther},
      });
    if (e == 1)
      navigate("/" + fetchedUser["username"] + "/posts", {
        state: { user: fetchedUser , profileOfOther: props.profileOfOther},
      });
  };


  

  return (
    <div id="user-profile" 
    className={online}
    style={{
      width: !props.profileOfOther ? "72%" : "94%",
            
  }}>



{/* badge animation */}
<div onClick={()=> setShowBadge(false)}>
    {showBadge && 
    <div style={{position:"absolute" , height:"100vh",zIndex:"1000"}} >
    <Lottie     options={fireworkslottieOptions}  />
    
      <div className="popup">
        <div className="popup-content">
          <img src={require("../images/rosette.png")} alt="Popup Image" className="zoom-in" style={{width:"50%",height:"auto"}}/>
        </div>
      </div>
    </div>

    }
    </div>


      <Helmet>
     <meta charSet="utf-8"/>
      <title>{fetchedUser.name}</title>
      <meta name="description" content={fetchedUser.job}/>
      </Helmet>

    

        <Dialog
        id="crop-profile"
        className={theme}
        header="انتخاب عکس پروفایل"
        visible={cropAvatar}
        onHide={() => setCropAvatar(false)}
        
      >
        <CropAvatarPic  setShowBadge={setShowBadge} isPage={false} avatarPic={fetchedUser.avatarPic} user_id={fetchedUser._id} close={closeCropPic}/>
      </Dialog>

      
      <Dialog
        id="crop-profile"
        className={theme}
        header="انتخاب عکس کاور"
        visible={cropCover}
        onHide={() => setCropCover(false)}
      >
        <CropCoverPic setShowBadge={setShowBadge} isPage={false} coverPic={fetchedUser.coverPic} user_id={fetchedUser._id} close={closeCropPic}/>
      </Dialog>

      <Dialog
        id="categories"
        className={theme}
        header="دسته بندی ها"
        visible={catDialog}
        style={{ width: "40vw" }}
        onHide={() => setCatDialog(false)}
      >
        
      </Dialog>

      <Dialog
        id="edit-profile-form"
        className={theme}
        header="ویرایش پروفایل"
        visible={showEditProfileDialog}
        onHide={() => setShowEditProfileDialog(false)}
        
        dismissable="false"
      >
        <EditProfileForm  setShowBadge={setShowBadge} close={closeEditProfile} user={fetchedUser} updateProfile={updateProfile} />
      </Dialog>

      <Dialog
        id="edit-profile-form"
        className={theme}
        header="راه های ارتباطی"
        visible={showEditSocialDialog}
        onHide={() => setShowEditSocialDialog(false)}
        dismissable="false"
      >
        <SocialForm isPage={false} user={fetchedUser} close={closeEditSocial} updateProfile={updateProfile} />
      </Dialog>

      <div
        id="profile-cover"
        onMouseEnter={() => {!props.profileOfOther ? setShowEditCover(true) : setShowEditCover(false)}}
        onMouseLeave={() => {!props.profileOfOther ? setShowEditCover(false) : setShowEditCover(false) }}
        style={coverStyle}
      >
        {!desktop ?
            <div
              className="badges"
              style={{
                background: "transparent",
                marginTop: "0px",
                width: "140px",
                height: "140px",
              }}
            >
              <span
                style={{ position: "absolute", margin:"18px 0px 0px 15px",fontSize:"12px" }}
              >
                <i
                  className="pi pi-times"
                  style={{ padding: "0px 5px 0px 0px", fontSize: "10px" }}
                ></i>
                {fetchedUser["roset1"] ? fetchedUser["roset1"] : 0 }
              </span>
              <img src={require("../images/badge1.png")}></img>
            </div>
             : null}
        <i
          className="pi pi-camera"
          style={{ display: online==="online" && (showEditCover || !desktop)? "flex" : "none" }}
          onClick={() => setCropCover(true)}
        ></i>
        
      </div>
      <div id="profile-nav">
        <span id="p-row1">
          <span className="p-col1" style={{display:"flex"}}>

          <span style={{display:"flex",width:"fit-content"}}>
            <div className="p-avatarPic"
               onMouseEnter={() => { !props.profileOfOther ? setShowEditAvatar(true) : setShowEditAvatar(false);}}
              onMouseLeave={() => { !props.profileOfOther ? setShowEditAvatar(false) : setShowEditAvatar(false)}}
              onClick={() => setCropAvatar(true)}
              style={{
                background: "#ffffff",
                borderRadius: "50%",
                boxShadow: "0 1px 4px rgba(0, 0, 0, 0.15)",
                marginTop: "-70px",
                width: "140px",
                height: "140px",
              }}
            >

              
              <div
                id="edit-profile-pic"
                style={{
                  display: (online==="online" && (showEditAvatar || !desktop)) ? "block" : "none",
                  background: desktop ? "#25252580" : "#25252520",
                  borderRadius: "50%",
                  marginTop: "0px",
                  width: "140px",
                  height: "140px",
                  position: "absolute",
                  cursor: "pointer",
                }}
              >
                <i
                  className="pi pi-camera"
                  style={{
                    marginTop: "40%",
                    fontSize: "25px",
                    color: "#ffffff",
                  }}
                ></i>
              </div>

              <img src={`${process.env.REACT_APP_BACK}/avatars/${avatarPic}`} alt={`فلیکا - ${fetchedUser.name}`}></img>
          
            </div>
            </span>

            <span style={{display:"flex",width:"fit-content"}}>
            <div  className="p-title" style={{ textAlign: "right", margin: "-20px 10px 0px 0px" }}>

            <span style={{display:"flex",alignItems:"center",direction:"rtl",marginTop:"30px"}}>
                    <h4 style={{ marginBottom: "0px", marginTop:"0px"}}>{fetchedUser.name}</h4>
                    {fetchedUser.blueTick ? <img id="blue-tick" src={require("../images/blueTick.png")}></img> : null}
                    </span>
              
              <p style={{ marginTop: "10px", fontSize: "12px" }}>{fetchedUser.job}</p>
            </div>
            </span>

          </span>
          
          <span className="p-col2">
            {/* <SplitButton
              style={{ direction: "rtl" }}
              label=""
              model={editItems}
            ></SplitButton> */}

          { !props.profileOfOther ?
        
            <button onClick={() => setShowEditProfileDialog(true)}>
              <i className="pi pi-user-edit" style={{ fontSize: "22px" }}></i>
            </button>
            
            :null}

          {desktop ?
            <div
              className="badges"
              style={{
                background: "transparent",
                marginTop: "-70px",
                width: "140px",
                height: "140px",
              }}
            >
              <span
                style={{ position: "absolute", margin: "28px 0px 0px 25px" }}
              >
                <i
                  className="pi pi-times"
                  style={{ padding: "0px 5px 0px 0px", fontSize: "12px" }}
                ></i>
                {fetchedUser["roset1"] ? fetchedUser["roset1"] : 0 }
              </span>
              <img src={require("../images/badge1.png")}></img>
            </div>
             : null}
          </span>
         
        </span>
        <span id="p-row2">
          <span className="p-col1">
            <button
              style={{ marginLeft: "20px" }}
              onClick={() => {
                setShowFollowers(true);
                setFollowersHeader("دنبال کننده ها");
              }}
            >
              {fetchedUser["followersCount"]} دنبال کننده
            </button>
            <button
              onClick={() => {
                setShowFollowings(true);
                setFollowersHeader("دنبال میکند");
              }}
            >
              {fetchedUser["followingsCount"]} دنبال میکند
            </button>
          </span>
          <Dialog
            id="suggestion-box"
            className={theme}
            visible={showFollowers}
            header={followersHeader}
            style={{ width: !desktop ? "75%" : "30%"}}
            onHide={() => setShowFollowers(false)}
            dismissableMask={true}
          >
            <div>
              <Divider style={{ marginTop: "0px" }} />
            </div>

            {fetchedFollowers.map((follower, index) => (
            
            follower!==null && <SuggestionRow
              key={index}
              name={follower.name}
              job={follower.job}
              image={follower.avatarPic}
              username={follower.username}
              _id={follower._id}
              isPage={follower.type == undefined ? false : true}
              forSearch={false}
              isPost = {false}
              group_id = {undefined}
              
            ></SuggestionRow>

          ))}
          </Dialog>

          <Dialog
            id="suggestion-box"
            className={theme}
            visible={showFollowings}
            header={followersHeader}
            style={{ width: !desktop ? "75%" : "30%"}}
            onHide={() => setShowFollowings(false)}
            dismissableMask={true}
          >
            <div>
              <Divider style={{ marginTop: "0px" }} />
            </div>
            {fetchedFollowings.map((follower, index) => (
            
            follower!==null && <SuggestionRow
              key={index}
              name={follower.name}
              job={follower.job}
              image={follower.avatarPic}
              username={follower.username}
              _id={follower._id}
              isPage={follower.type == undefined ? false : true}
            ></SuggestionRow>

          ))}
           
          </Dialog>


          <span className="p-col2"></span>
        </span>
      </div>

      <div id="profile-content">
        <TabView
          id="profile-tab"
          style={{ direction: "rtl" }}
          activeIndex={props.profileTab}
          onTabChange={(e) => tabHandler(e.index)}
        >
          <TabPanel header="درباره">
            <div id="about-panel">
              <span id="tab1-col2" style={{display : !desktop ? "contents" : null}}>
                <div className="info-box" style={ !desktop ? { width:"80%", display: "block", direction: "rtl", margin: "0% 10% 5% 10%" } : null}>
                  <span className="info-box-header about-box-content">
                    <span className="info-header-col1">
                      <i className="pi pi-info-circle"></i>
                      <h6 style={{marginRight:"10px"}}>درباره</h6>
                    </span>

                    { !props.profileOfOther ?
                    <span
                      className="info-header-col2"
                      onClick={() => setShowEditProfileDialog(true)}
                    >
                      <i className="pi pi-pencil"></i>
                    </span>
                    : null }
                  </span>
                  <div className="info-box-content">
                  <span className="about-box-content" style={{marginBottom:"10px" , display:"flex" , color:"#252525"}}><i className="pi pi-user"></i>{fetchedUser.name} {fetchedUser.job ? " , " : null} {fetchedUser.job}</span>
                  { fetchedUser["country"] != null && fetchedUser["country"] != ""?
                  <span className="about-box-content"  style={{marginBottom:"10px" , display:"flex" , color:"#252525"}}><i className="pi pi-map"></i>{fetchedUser.country} , {fetchedUser.city}</span>
                  : null }

                    { (fetchedUser["bio"] == null || fetchedUser["bio"] == "") && !props.profileOfOther ?
                    
                    <span
                      id="addConnection"
                      onClick={() => setShowEditProfileDialog(true)}
                      style={{
                        color: "#af734f",
                        cursor: "pointer",
                        display: "flex",
                        marginBottom: "10px",
                      }}
                    >
                      <i className="pi pi-plus-circle"></i>
                      افزودن بیوگرافی
                    </span>
                    : null }

                    { (fetchedUser["awards"] == null || fetchedUser["awards"] == "") && !props.profileOfOther ?
                    <span
                    id="addConnection"
                      onClick={() => setShowEditProfileDialog(true)}
                      style={{
                        color: "#af734f",
                        cursor: "pointer",
                        display: "flex",
                        marginBottom: "10px",
                      }}
                    >
                      <i className="pi pi-plus-circle"></i>
                      افزودن جایزه ها
                    </span>
                    : null }
                    <span>
                      <i className="pi pi-calendar"></i>
                      تاریخ عضویت:
                      {" "+new Date(fetchedUser["regDate"]).toLocaleDateString('fa-IR')}
                    </span>
                  </div>
                </div>

                <div className="info-box" style={{display: !desktop ? "none" : null}}>
                  <span className="info-box-header">
                    <span
                      className="info-header-col1"
                      onClick={() => setShowEditSocialDialog(true)}
                    >
                      <h6>راه های ارتباطی</h6>
                    </span>

                    { !props.profileOfOther ?
                    <span
                      className="info-header-col2"
                      onClick={() => setShowEditSocialDialog(true)}
                    >
                      <i className="pi pi-pencil"></i>
                    </span>
                    : null }
                  </span>
                  <div className="info-box-content">

                  { !props.profileOfOther ?
                  <div>
                  <span style={{marginBottom:"20px" , display:"flex" }}>شماره همراه و ایمیل شما به سایر کاربران نشان داده نمی شود</span>
                    <span style={{marginBottom:"10px" , display:"flex" , color:"#252525"}}><i className="pi pi-phone"></i>شماره همراه: {fetchedUser.phone}</span>
                    <span style={{marginBottom:"10px" , display:"flex", color:"#252525"}}><i className="pi pi-envelope"></i>ایمیل: {fetchedUser.email}</span>
                  </div>
                  :null }

                    { fetchedUser.website ? <span style={{marginBottom:"10px" , display:"flex", color:"#252525"}}><i className="pi pi-globe"></i>وبسایت: { fetchedUser.website}</span> : null}
                    
                    { (fetchedUser.instagram != null && fetchedUser.instagram != "") || (fetchedUser.telegram != null && fetchedUser.telegram != "") || (fetchedUser.whatsapp != null && fetchedUser.whatsapp != "") || (fetchedUser.facebook != null && fetchedUser.facebook != "") || (fetchedUser.twitter != null && fetchedUser.twitter != "") || (fetchedUser.linkedin != null && fetchedUser.linkedin != "") || (fetchedUser.youtube != null && fetchedUser.youtube != "") ?
                    <span style={{marginBottom:"10px" , display:"flex", color:"#252525"}}>شبکه های اجتماعی:</span> : null }
                    <span style={{marginBottom:"10px" , display:"flex", color:"#252525"}}>
                      { fetchedUser.instagram != null && fetchedUser.instagram != "" ?<span className="social-icon"><a href={"https://instagram.com/"+fetchedUser.instagram}><i className="pi pi-instagram"></i></a></span> : null }
                      { fetchedUser.telegram != null  && fetchedUser.telegram != ""? <span className="social-icon"><a href={fetchedUser.telegram}><i className="pi pi-telegram"></i></a></span> : null }
                      { fetchedUser.whatsapp != null && fetchedUser.whatsapp != "" ? <span className="social-icon"><a href={"https://api.whatsapp.com/send?phone="+fetchedUser.whatsapp}><i className="pi pi-whatsapp"></i></a></span> : null }
                      { fetchedUser.facebook != null && fetchedUser.facebook != "" ? <span className="social-icon"><a href={"https://facebook.com/"+fetchedUser.facebook}><i className="pi pi-facebook"></i></a></span> : null }
                      { fetchedUser.twitter != null && fetchedUser.twitter != "" ? <span className="social-icon"><a href={"https://twitter.com/"+fetchedUser.twitter}><i className="pi pi-twitter"></i></a></span> : null }
                      { fetchedUser.linkedin != null && fetchedUser.linkedin != "" ? <span className="social-icon"><a href={"https://linkedin.com/"+fetchedUser.linkedin}><i className="pi pi-linkedin"></i></a></span> : null }
                      { fetchedUser.youtube != null && fetchedUser.youtube != "" ? <span className="social-icon"><a href={"https://youtube.com/"+fetchedUser.youtube}><i className="pi pi-youtube"></i></a></span> : null }
                      </span>

                      { !props.profileOfOther ?
                    <span
                    id="addConnection"
                      style={{
                        color: "#af734f",
                        cursor: "pointer",
                        display: "flex",
                        marginBottom: "10px",
                        marginTop:"10px",
                        fontSize:"13px"
                      }}
                      onClick={() => setShowEditSocialDialog(true)}
                    >
                      <i className="pi pi-plus-circle"></i>
                      افزودن راه ارتباطی
                    </span>

                    :null }

                  </div>
                </div>
              </span>

              <span id="tab1-col1" style={{display: !desktop ? "contents" : null}}>
                {fetchedUser["bio"] == null && fetchedUser["awards"] == null ? (
                  <div>
                    <img src={require("../images/intro-index.png")}></img>
                    <p style={{color:"#25252580",marginTop:"-20px",marginBottom:"50px"}}>اطلاعات بیوگرافی کاربر هنوز ثبت نشده!</p>
                  </div>
                ) : null}



                {fetchedUser["bio"] != null && fetchedUser["bio"] != ""?
                  <div className="about-box about-box-content">
                    <div><span style={{ display: "flex", direction: "rtl", margin: "10px 0px 10px 0px" }}><i className="pi pi-id-card"></i><h6>بیوگرافی</h6></span></div>
                    <div><p>{fetchedUser["bio"]}</p></div>
                  </div>
                  :
                  null

                }
                {
                  fetchedUser["awards"] != null && fetchedUser["awards"] != ""?

                  <div className="about-box about-box-content">
                  <div><span style={{ display: "flex", direction: "rtl", margin: "10px 0px 10px 0px" }}><i className="pi pi-star"></i><h6>جایزه ها</h6></span></div>
                  <div><p>{fetchedUser["awards"]}</p></div>
                </div>

                    : null
                }

                {/* mobile (contact information)*/}
                {
                !desktop?

                  <div className="about-box">
                  <div><span style={{ display: "flex", direction: "rtl", margin: "10px 0px 10px 0px" }}><i className="pi pi-comments"></i><h6> راه های ارتباطی</h6></span></div>
                  { !props.profileOfOther ?
                    <span
                      className="info-header-col2"
                      onClick={() => setShowEditSocialDialog(true)}
                    >
                      <i className="pi pi-pencil"></i>
                    </span>
                    : null }
                  
                  <div className="info-box-content">

                  { !props.profileOfOther ?
                  <div>
                  <span style={{marginBottom:"20px" , display:"flex" }}>شماره همراه و ایمیل شما به سایر کاربران نشان داده نمی شود</span>
                    <span className="about-box-content" style={{marginBottom:"10px" , display:"flex" , color:"#252525"}}><i className="pi pi-phone"></i>شماره همراه: {fetchedUser.phone}</span>
                    <span className="about-box-content" style={{marginBottom:"10px" , display:"flex", color:"#252525"}}><i className="pi pi-envelope"></i>ایمیل: {fetchedUser.email}</span>
                  </div>
                  :null }

                    { fetchedUser.website ? <span className="about-box-content" tyle={{marginBottom:"10px" , display:"flex", color:"#252525"}}><i className="pi pi-globe"></i>وبسایت: { fetchedUser.website}</span> : null}
                    
                    { (fetchedUser.instagram != null && fetchedUser.instagram != "") || (fetchedUser.telegram != null && fetchedUser.telegram != "") || (fetchedUser.whatsapp != null && fetchedUser.whatsapp != "") || (fetchedUser.facebook != null && fetchedUser.facebook != "") || (fetchedUser.twitter != null && fetchedUser.twitter != "") || (fetchedUser.linkedin != null && fetchedUser.linkedin != "") || (fetchedUser.youtube != null && fetchedUser.youtube != "") ?
                    <span className="about-box-content" style={{marginBottom:"10px" , display:"flex", color:"#252525"}}>شبکه های اجتماعی:</span> : null }
                    <span className="about-box-content" style={{marginBottom:"10px" , display:"flex", color:"#252525"}}>
                      { fetchedUser.instagram != null && fetchedUser.instagram != "" ?<span className="social-icon "><a href={"https://instagram.com/"+fetchedUser.instagram}><i className="pi pi-instagram"></i></a></span> : null }
                      { fetchedUser.telegram != null  && fetchedUser.telegram != ""? <span className="social-icon"><a href={fetchedUser.telegram}><i className="pi pi-telegram"></i></a></span> : null }
                      { fetchedUser.whatsapp != null && fetchedUser.whatsapp != "" ? <span className="social-icon"><a href={"https://api.whatsapp.com/send?phone="+fetchedUser.whatsapp}><i className="pi pi-whatsapp"></i></a></span> : null }
                      { fetchedUser.facebook != null && fetchedUser.facebook != "" ? <span className="social-icon"><a href={"https://facebook.com/"+fetchedUser.facebook}><i className="pi pi-facebook"></i></a></span> : null }
                      { fetchedUser.twitter != null && fetchedUser.twitter != "" ? <span className="social-icon"><a href={"https://twitter.com/"+fetchedUser.twitter}><i className="pi pi-twitter"></i></a></span> : null }
                      { fetchedUser.linkedin != null && fetchedUser.linkedin != "" ? <span className="social-icon"><a href={"https://linkedin.com/"+fetchedUser.linkedin}><i className="pi pi-linkedin"></i></a></span> : null }
                      { fetchedUser.youtube != null && fetchedUser.youtube != "" ? <span className="social-icon"><a href={"https://youtube.com/"+fetchedUser.youtube}><i className="pi pi-youtube"></i></a></span> : null }
                      </span>

                      { !props.profileOfOther ?
                    <span
                    id="addConnection"
                      style={{
                        color: "#af734f",
                        cursor: "pointer",
                        display: "flex",
                        marginBottom: "10px",
                        marginTop:"20px",
                        fontSize:"13px"
                      }}
                      onClick={() => setShowEditSocialDialog(true)}
                    >
                      <i className="pi pi-plus-circle"></i>
                      افزودن راه ارتباطی
                    </span>

                    :null }

                  </div>
                </div>

                    : null
                }





              </span>
            </div>
          </TabPanel>
          <TabPanel header="پست ها">
            <div id="posts-panel">
              <span id="tab1-col2" >
                <div className="info-box">
                  <span className="info-box-header">
                    <span className="info-header-col1">
                      <h6>دسته بندی ها</h6>
                    </span>

                    { !props.profileOfOther ?
                    <span
                      className="info-header-col2"
                      onClick={() => setCatDialog(true)}
                    >
                      <i className="pi pi-pencil"></i>
                    </span>
                    : null }
                  </span>
                  <div className="info-box-content">
                    <span>
                      <i className="pi pi-folder"></i>
                      همه دسته ها
                    </span>

                    { !props.profileOfOther ?
                    <span
                      onClick={() => setCatDialog(true)}
                      style={{
                        color: "#af734f",
                        cursor: "pointer",
                        display: "flex",
                        margin: "10px 0px 10px 0px",
                      }}
                    >
                      <i className="pi pi-plus-circle"></i>
                      افزودن دسته جدید
                    </span>
                    : null }
                  </div>
                </div>
              </span>

              <span id="tab1-col1">
                <div id="feed">
                  <a>

                    { !props.profileOfOther ?

                    <span id="create-post">
                      <span id="feed-col1">
                        <Button
                          onClick={() => 
                            {
                            if(!desktop)
                              navigate("/new-post");
                            else
                              setShowNewPostDialog(true);
                            
                            }}
                          tooltip="عکس"
                          tooltipOptions={{
                            className: "create-post-tooltip",
                            position: "top",
                          }}
                        >
                          <i className="pi pi-image"></i>
                        </Button>
                        <Button
                          onClick={() => 
                            {
                            if(!desktop)
                              navigate("/new-post");
                            else
                              setShowNewPostDialog(true);
                            
                            }}
                          tooltip="ویدیو"
                          tooltipOptions={{
                            className: "create-post-tooltip",
                            position: "top",
                          }}
                        >
                          <i className="pi pi-video"></i>
                        </Button>
                      </span>

                      <span
                        onClick={() => 
                          {
                          if(!desktop)
                            navigate("/new-post");
                          else
                            setShowNewPostDialog(true);
                          
                          }}
                        id="feed-col2"
                      >
                        انتشار پست جدید ...
                      </span>
                      <span
                        onClick={() => 
                          {
                          if(!desktop)
                            navigate("/new-post");
                          else
                            setShowNewPostDialog(true);
                          
                          }}
                        id="feed-col3"
                      >
                        <img src={`${process.env.REACT_APP_BACK}/avatars/${avatarPic}`}></img>

                      </span>
                    </span>


                    : null }

                    <Dialog
                      className={theme + " new-post-dialog"}
                      breakpoints={{ "960px": "75vw", "640px": "100vw" }}
                      position="top"
                      header="پست جدید"
                      visible={showNewPostDialog}
                      style={{ width: "40vw" }}
                      onHide={() => setShowNewPostDialog(false)}
                    >
                      <NewPost theme={theme} type="normal" user={fetchedUser} close={closeNewPost}/>
                    </Dialog>
                  </a>
                  
                  <div id="upload-progress" style={{display: (uploadProgress===100 || uploadProgress===0) ? "none" : "block"}}>
          
            <span>
          <div className="row1"><img src={process.env.REACT_APP_BACK+`/avatars/${avatarPic}`}></img></div>
          <div className="row2"><LinearProgress variant="determinate" value={uploadProgress} /></div>
          
          </span>
          </div>

                  {/* load animation */}
          {postsLoading ? 
            <div className="spinner"></div>
        : null}

          {fetchedPosts.map((post, index) => (
            
            
            <Post
                    key={index}
                    creatorUsername={fetchedUser.username}
                    creator_id={post.creator._id}
                    creatorName={post.creator.name}
                    creatorAvatar={post.creator.avatarPic}
                    creatorJob={post.creator.job}
                    file={post.file}
                    fileType={post.fileType}
                    category={post.category}
                    tags={post.tags}
                    taggedUsers={post.taggedUsers}
                    date={post.date}
                    description={post.description}
                    likesCount={post.likesCount}
                    commentsCount={post.commentsCount}
                    postOfOther={false}
                    _id={post._id}
                    updatePost={closeNewPost}
                    //used for like & save:
                    userAvatar={post.creator.avatarPic}
                    user_id={post.creator._id}
                    user_name={post.creator.name}
                    user_username={post.creator.username}
                    theme={theme}
                    blueTick={post.creator.blueTick}
                  />
            

          ))}

          {/* load animation */}
          {lazyLoading ? 
            <div className="spinner" style={{marginBottom:"50px"}}></div>
        : null}
                    
                  
                  <br />
                </div>
              </span>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
}
export default Profile;
