import "../Css/NavPanel.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import Notification from "./Notification";
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import NewPost from "./NewPost";
import NewGruop from "./NewGroup";
import NewPage from "./NewPage";
import PageForm from "./PageForm";
import axios from "axios";
import "simplebar/dist/simplebar.min.css";
import { TabPanel, TabView } from "primereact/tabview";
import { Button } from "primereact/button";
import SuggestionRow from "./SuggestionRow";
import Lottie from 'react-lottie';
import fireworks from '../assests/animation_lk9ixjes.json';
import badge from '../assests/Animation-1689760549885.json';

function NavPanel(props) {
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem('userToken'));
  const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
  const [createGroupDialog, setCreateGroupDialog] = useState(false);
  const [CreatePageDialog, setCreatePageDialog] = useState(false);
  const [showNewPostDialog, setShowNewPostDialog] = useState(false);
  const [detailedPageDialog, setDetailedPageDialog] = useState(false);
  const [userPages, setUserPages] = useState([]);
  const [userProducts, setUserProducts] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [updatePages, setUpdatePages] = useState(true);
  const [newPageType, setNewPageType] = useState();
  const [notifs, setNotifs] = useState([]);
  const [updateNotifs, setUpdateNotifs] = useState(true);
  const [messages, setMessages] = useState([]);
  const [downloadMessages, setDownloadMessages] = useState(false);
  const [notifTabIndex, setNotifTabIndex] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showAllPages, setShowAllPages] = useState(false);
  const [showAllGroups, setShowAllGroups] = useState(false);
  const [showAllProducts, setShowAllProducts] = useState(false);
  const [allPosts,setAllPosts] = useState([]);
  const [allProducts,setAllProducts] = useState([]);
  const [showSearchbox,setShowSearchbox] = useState(false);
  const [searchValue,setSearchValue] = useState("");
  const [notifCount,setNotifCount] = useState(props.notifCount);
  const [newMessagesCount,setNewmessagesCount] = useState(0);             //nubmer of new messsages
  const [lazyLoading, setLazyLoading] = useState(false);
  const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
  const [showBadge,setShowBadge] = useState(false);
  const [searchPostsLoading,setSearchPostsLoading] = useState(false);

  const fireworkslottieOptions = {
    loop: 3,
    autoplay: true,
    animationData: fireworks,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    isClickToPauseDisabled:true 
    
  };
  
  const randomise = (r) =>
  {
         //Fisher-yates shuffle algorithm:
         for(let i=r.length-1; i>0; i--)
         {
            const j = Math.floor(Math.random()*(i+1));
            [r[i],r[j]] = [r[j],r[i]];
         }

         return r;
  }



  const user = JSON.parse(localStorage.getItem("flickerNetwork") != null)
    ? JSON.parse(localStorage.getItem("flickerNetwork"))
    : null;


  const closeNewPost = (category, uploadFile, fileType, values, type) => {
    submitPost(uploadFile, fileType, values, type);
    setShowNewPostDialog(false);
    if (category == "لوازم اسب")
      navigate("/shop/horse", { state: { updateProducts: true } });
    else if (category == "لوازم سوار")
      navigate("/shop/rider", { state: { updateProducts: true } });
  };

  const submitPost = (uploadFile, fileType, values, type) => {
    //uploading file
    let formData = new FormData();
    formData.append("file", uploadFile);

    const c = {
      headers: {
        "Contetnt-Type": "multipart/form-data",
      },
    };

    axios
      .post(process.env.REACT_APP_BACK + "/posts/uploadFile", formData, {
        c,
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setUploadProgress(progress);
        },
        onDownloadProgress: (progressEvent) => {},
      })
      .then((res) => {
        //Now do what you want with the response;

        let fileName = res.data.fileName;

        //save tags:
        var t = values.tags.split(" ");
        for (const index in t) {
          //remove empty elements from array:
          if (t[index] === "") t.splice(index, 1);

          //remove # from each element:
          if (t[index] != undefined) {
            t[index] = t[index].substring(1);
            t[index].trim();
          }
        }

        const data = JSON.stringify({
          description: values.description,
          category: values.category,
          isProduct: false,
          file: fileName,
          creator: user._id,
          creatorAvatar: user.avatarPic,
          creatorJob: user.job,
          creatorName: user.name,
          creatorUsername: user.username,
          type: type,
          fileType: fileType,
          tags: t,
        });

        //creating post in database
      

        axios
          .post(process.env.REACT_APP_BACK + "/posts", data, config)
          .then((response) => {
            console.log(response);


            if(response.data.roset1)
                  props.etShowBadge(true);



          })
          .catch((error) => {
            console.log("error", error);
            if(error.response.data.statusCode===401)
              navigate("/login")
          });
      })
      .catch((error) =>
      {
        if(error.response.data.statusCode===401)
              navigate("/login")
      });
  };

  const notifTabHandler = (index) => {
    if (index === 1) setDownloadMessages(true);

    setNotifTabIndex(index);
  };

  //fetch notifications:
  useEffect(() => {
    async function fetchNotif() {

    let heightIndex;
    let offset = 10;

    //fetch first group:
    const result = await axios
        .get(process.env.REACT_APP_BACK + "/notifications/" + user._id+"/"+offset, config)
        .then((result) => {
          if (result.status == 200) {
            
            let temp = result.data;
            let a=[];
            let manyLikes=0;

            //merge consequantial message , like and follow notifs in one notif:
            {temp.map((notif, index) => {
              if (notif.sender_id !== null && notif.sender_id !== undefined ) 
              {
                const prevNotif = temp[index - 1];
                const messageSameGroup =
                  prevNotif &&
                  prevNotif.sender_id &&
                  prevNotif.sender_id["_id"] === notif.sender_id["_id"] &&
                  prevNotif.type=== "message" && notif.type==="message";

                const likeSameGroup =
                  prevNotif &&
                  prevNotif.sender_id &&
                  prevNotif.type=== "like" && notif.type==="like" &&
                  prevNotif.post_id._id === notif.post_id._id;

                

           

                  if(!messageSameGroup && !likeSameGroup)
                    a.push(notif);
                  
                  if(likeSameGroup)
                  {
                    a[a.length-1]["manyLikes"] =manyLikes+1; 
                  }
                    
              }
            })}

            
            setNewmessagesCount(0);
            setNotifs(a);
            heightIndex=a.length-1;
            console.log("fetched notifs: ", a);
            return a;
          }
        })
        .catch((error) => {
          console.log(error);
          if(error.response.data.statusCode===401)
              navigate("/login")
        });
    

        //for desktop:
        if(desktop)
        {
          const nav_bar_panel = document.getElementById('nav-bar-panel');

          nav_bar_panel.addEventListener('scroll', async function() {
  
            const { clientHeight, scrollTop, scrollHeight } = document.documentElement;

            if (scrollTop + clientHeight === scrollHeight) {

                offset += 10;
                await setLazyLoading(true);
          
                await axios
                .get(process.env.REACT_APP_BACK + "/notifications/" + user._id+"/"+offset, config)
                .then((result) => {
                  if (result.status == 200) {
          
          
                    let temp = result.data;
                    let a=[];
                    
                    //merge consequantial message notifs in one notif:
                    {temp.map((notif, index) => {
                      if (notif.sender_id !== null && notif.sender_id !== undefined ) 
                      {
                        const prevNotif = temp[index - 1];
                        const sameGroup =
                          prevNotif &&
                          prevNotif.sender_id["_id"] === notif.sender_id["_id"] &&
                          prevNotif.type=== "message" && notif.type==="message";

                          if(sameGroup)
                            ;   
                          else
                            a.push(notif);
                            
                      }
                    })}
          
                    setNewmessagesCount(0);
                   
                    console.log("new messages",newMessagesCount);
                    setNotifs(prevArray => [...prevArray, ... a]);
                    heightIndex=a.length-1;
                    console.log("fetched notifs: ", a);
                    return a;
          
          
                  }
                })
                .catch((error) => {
                  console.log(error);
                  if(error.response.data.statusCode===401)
                    navigate("/login")
                });
                  
              
          
                setLazyLoading(false);
                
                
          
              
          
             
              }
            });

        }
        else
        {

        //for mobile:
        window.addEventListener('scroll', async function() {


          const { clientHeight, scrollTop, scrollHeight } = document.documentElement;

          if (scrollTop + clientHeight >= scrollHeight - 150) {

            
            offset += 10;
            await setLazyLoading(true);
            

            await axios
            .get(process.env.REACT_APP_BACK + "/notifications/" + user._id+"/"+offset, config)
            .then((result) => {
              if (result.status == 200) {


                
                let temp = result.data;
                    let a=[];
                    
                    //merge consequantial message notifs in one notif:
                    {temp.map((notif, index) => {
                      if (notif.sender_id !== null && notif.sender_id !== undefined ) 
                      {
                        const prevNotif = temp[index - 1];
                        const sameGroup =
                          prevNotif &&
                          prevNotif.sender_id["_id"] === notif.sender_id["_id"] &&
                          prevNotif.type=== "message" && notif.type==="message";

                          if(sameGroup)
                            // heightIndex += 1;   
                            ;
                          else
                            a.push(notif);
                          
                      }
                    })}

                setNewmessagesCount(0);

                console.log("new messages",newMessagesCount);
                setNotifs(prevArray => [...prevArray, ... a]);
                heightIndex=a.length-1;
                console.log("fetched notifs: ", a);
                return a;


              }
            })
            .catch((error) => {
              console.log(error);
              if(error.response.data.statusCode===401)
                navigate("/login")
            });
              
          

            setLazyLoading(false);
            
            

          

        
          }
        });

      }




    }

    if (props.type === "notification" && updateNotifs) {
      fetchNotif();
      setUpdateNotifs(false);
    }
  }, [props, updateNotifs]);

  //fetch messages:
  useEffect(() => {
    async function fetchMessages() {
      const result = await axios
        .get(
          process.env.REACT_APP_BACK +
            "/notifications/all-messages/" +
            user._id,
          config
        )
        .then((result) => {
          if (result.status == 200) {
            setMessages(result.data);
            console.log("fetched messages: ", result.data);
            return result.data;
          }
        })
        .catch((error) => {
          console.log(error);
          if(error.response.data.statusCode===401)
              navigate("/login")
        });

      return result.data;
    }

    if (downloadMessages) {
      fetchMessages();
      setDownloadMessages(false);
    }
  }, [downloadMessages]);

  const closePageForm = () => {
    setDetailedPageDialog(false);
    setUpdatePages(true);
  };
  const closeGroupForm = () => {
    setCreateGroupDialog(false);
    setUpdatePages(true);
  };

  const openDetailedPage = (type) => {
    setNewPageType(type);
    setCreatePageDialog(false);
    setDetailedPageDialog(true);
  };

  //fetching user pages:
  useEffect(() => {
    async function fetchUserPages() {
      await axios
        .get(process.env.REACT_APP_BACK + "/pages/creator/" + user._id, config)
        .then((result) => {
          if (result.status == 200) {
            setUserPages(result.data.filter((item) => item.type !== "group"));
            console.log("fetched user pages: ", result.data);
            return result.data;
          }
        })
        .catch((error) => {
          console.log(error);
          if(error.response.data.statusCode===401)
              navigate("/login")
        });
    }
    async function fetchUserGroups() {
      await axios
        .get(process.env.REACT_APP_BACK+"/notifications/userGroups/" + user._id, config)
        .then((result) => {
          if (result.status == 200) {
            setUserGroups(result.data);
            console.log("fetched user groups: ", result.data);
            return result.data;
          }
        })
        .catch((error) => {
          console.log(error);
          if(error.response.data.statusCode===401)
              navigate("/login")
        });
    }

    if (props.type == "feed" && updatePages) {
      fetchUserPages();
      fetchUserGroups();
      setUpdatePages(false);
    }
  }, [props, updatePages]);

  //fetching user products:
  useEffect(() => {
    async function fetchUserProducts() {
      await axios
        .get(
          process.env.REACT_APP_BACK + "/posts/products_of/" + user._id,
          config
        )
        .then((result) => {
          if (result.status == 200) {
            setUserProducts(result.data);
            console.log("fetched user products: ", result.data);
            return result.data;
          }
        })
        .catch((error) => {
          console.log(error);
          if(error.response.data.statusCode===401)
              navigate("/login")
        });
    }

    if (props.type == "shop") {
      fetchUserProducts();
    }
  }, [props]);

 //***************************************  top search box   ******************************************************************/
 const handleSearch = (event) => {
  let { value } = event.target;
  
  setSearchValue(value);
  setShowSearchbox(true);
  if(value==="")
  {
    setAllPosts([]);
  }
};

// Create a debounced version of the API request
useEffect(() => {
  
  const delayedApiRequest = setTimeout(() => {
    if (searchValue) {
      fetchPosts(searchValue.replace(/\s+/g,"_"));
    }
  }, 500);

  
  return () => clearTimeout(delayedApiRequest);
}, [searchValue]);


async function fetchPosts(searchValue) {

  let heightIndex = 1;
  let offset = 10;


  await setAllPosts([]);
  await setSearchPostsLoading(true);
  
  await axios.get(process.env.REACT_APP_BACK+"/posts/search/"+searchValue+"/"+offset,config)

    .then((result) => {

      if (result.status == 200) {
        setAllPosts(result.data);
        setSearchPostsLoading(false);
        console.log("fetched searched posts: ", result.data);
        return result.data;
      }
    })
    .catch((error) => {
      console.log(error);
      if(error.response.data.statusCode===401)
             navigate("/login")
    });



    const dialogElement = document.querySelector('#searchbox');
    if (dialogElement) {
     

      dialogElement.addEventListener('scroll', async function() {

       
    
      let scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;  
      let visibleHeight = document.documentElement.clientHeight;    // Get the visible height of the screen
      let distanceScrolled = scrollPosition + visibleHeight;        // Calculate the distance the user has scrolled from the top of the page


      if (distanceScrolled >= (66.6*heightIndex) ) {                 // Check if the user has scrolled down a certain amount
          
        
        heightIndex += 1;
          
    //fetch next:
    if(heightIndex === (offset))
    {
      offset += 10;
      await setSearchPostsLoading(true);

      await axios
      .get(process.env.REACT_APP_BACK+"/posts/search/"+searchValue+"/"+offset, config)
      .then((result) => {
        if (result.status == 200) {

          setAllPosts(prevArray => [...prevArray, ...randomise(result.data)]); 
          console.log("fetched searched posts: ", result.data);
          
          
          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
              navigate("/login")
      });
        
    

      setSearchPostsLoading(false);
      
      

    }

   
    }
  });
    }

}
/*********************************************************************************************************************** */

  //make notifs as seen:
  useEffect(() => {

    async function makeNotifsAsSeen() {

      
     
    
      const result = await axios.post(process.env.REACT_APP_BACK+"/notifications/count/" +notifCount, config)
      .then((result) => {
        if (result.status == 200) {
          console.log("seen",result.data);
          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
              navigate("/login")
      });

      
      
      return result.data;
  
    }

    if( notifCount != undefined && notifCount.length > 0 && props.type==="notification")
      makeNotifsAsSeen();
      
  },[notifCount]);

  useEffect(() => {
    setNotifCount(props.notifCount);

  }, [props]);


  return (
    <div id="nav-panel">


    {/* show badge animation */}
    <div onClick={()=> setShowBadge(false)}>
    {showBadge && 
    <div style={{position:"absolute" , height:"100vh",zIndex:"1000"}} >
    <Lottie     options={fireworkslottieOptions}  />
    
      <div className="popup">
        <div className="popup-content">
          <img src={require("../images/rosette.png")} alt="Popup Image" className="zoom-in" style={{width:"50%",height:"auto"}}/>
        </div>
      </div>
    </div>
    }
    </div>
    

      <Dialog
        className="new-post-dialog"
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        position="top"
        header="محصول جدید"
        visible={showNewPostDialog}
        style={{ width: "40vw" }}
        onHide={() => setShowNewPostDialog(false)}
      >
        <NewPost type="product" user={user} close={closeNewPost} />
      </Dialog>

      <Dialog
        id="create-page-dialog"
        header="ایجاد صفحه جدید"
        visible={CreatePageDialog}
        style={{ width: "50vw" }}
        onHide={() => setCreatePageDialog(false)}
      >
        <NewPage close={openDetailedPage} />
      </Dialog>

      <Dialog
        className="detailed-page-dialog"
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        header="صفحه جدید"
        visible={detailedPageDialog}
        style={{ width: "40vw" }}
        onHide={() => setDetailedPageDialog(false)}
      >
        <PageForm
          type={newPageType}
          user_id={user._id}
          creatorName={user.name}
          user_avatarPic={user.avatarPic}
          close={closePageForm}
          setShowBadge={setShowBadge}
        ></PageForm>
      </Dialog>

      <Dialog
        id="create-group-dialog"
        header="ایجاد گروه جدید"
        visible={createGroupDialog}
        style={{ width: "40vw" }}
        onHide={() => setCreateGroupDialog(false)}
      >
        <NewGruop
          type={"group"}
          user_id={user._id}
          creatorName={user.name}
          close={closeGroupForm}
        />
      </Dialog>

      {/* Feed Pannel /***************************************************************************************/}
      {props.type === "feed" ? (
        <div id="feed-panel">
          <span className="p-input-icon-right search-box-wraper">
            <i className="pi pi-search" />
            <InputText
              className="search-box"
              placeholder="اینجا جستجو کنید ..."
              value={searchValue}
              onChange={handleSearch}
            />
          </span>

          {/*show search results: */}

      <div id="searchbox" style={{display: searchValue!=="" ?  "block" : "none", marginTop:"0px"}}>

         {/* load animation */}
         {searchPostsLoading ? 
            <div className="spinner" style={{marginBottom:"50px"}}></div>
        : null}

      
{allPosts.length>0 && showSearchbox && (

<ul>

        
        {allPosts.map((post, index) => {
            return (
              <div className={"user" + index} key={index}>
                <div id="suggestion-box" className="search-row">
                  <SuggestionRow
                    key={index}
                    username={post.creator!==undefined ? post.creator.username : post.creatorPage.username}
                    name={post.creator!==undefined ? post.creator.name : post.creatorPage.name}
                    job={post.creator!==undefined  ? post.creator.job : post.creatorPage.job }
                    image={post.file}
                    fileType={post.fileType}
                    description={post.description}
                    _id={post._id}
                    isPost={true}
                    type="searchbox"
                  ></SuggestionRow>
                </div>
              </div>

              
            );
            
          })}

          

          

</ul>


)}

{allPosts.length===0 && showSearchbox ?<span style={{marginTop:"20px"}}>  نتیجه ای یافت نشد </span>: null}

  </div>


          <p>صفحه های من</p>

          {showAllPages ? (
            <div>
              {userPages.map((page, index) => (
                <div
                  className="panel-row"
                  key={index}
                  onClick={() =>
                    navigate("/" + page.username + "/about", {
                      state: { page: page, isPage: true, isUserPage: true },
                    })
                  }
                >
                  <span className="col-two">{page.name}</span>
                  <span className="col-one">
                    <img
                      src={
                        process.env.REACT_APP_BACK +
                        `/avatars/${page.avatarPic}`
                      }
                    ></img>
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <div>
              {userPages.slice(0, 4).map((page, index) =>
                page.type != "group" ? (
                  <div
                    className="panel-row"
                    key={index}
                    onClick={() =>
                      navigate("/" + page.username + "/about", {
                        state: { page: page, isPage: true, isUserPage: true },
                      })
                    }
                  >
                    <span className="col-two">{page.name}</span>
                    <span className="col-one">
                      <img
                        src={
                          process.env.REACT_APP_BACK +
                          `/avatars/${page.avatarPic}`
                        }
                      ></img>
                    </span>
                  </div>
                ) : null
              )}
            </div>
          )}

          {userPages.length > 4 && !showAllPages && (
            <div>
              {" "}
              <Button
                className="view-more-button"
                onClick={() => setShowAllPages(!showAllPages)}
              >
                بیشتر ...
              </Button>
            </div>
          )}
          {userPages.length > 4 && showAllPages && (
            <div>
              {" "}
              <Button
                className="view-more-button"
                onClick={() => setShowAllPages(!showAllPages)}
              >
                {" "}
                کمتر ...
              </Button>
            </div>
          )}

          <div className="panel-row" onClick={() => setCreatePageDialog(true)}>
            <span className="col-two" style={{ color: "#af734f" }}>
              ایجاد صفحه جدید
            </span>
            <span className="col-one">
              <i className="pi pi-plus-circle" style={{ color: "#af734f" }}></i>
            </span>
          </div>

          <p>گروه های من</p>

          {showAllGroups ? (
            <div>
              {userGroups.map((page, index) => (
                <div
                  className="panel-row"
                  key={index}
                  onClick={() =>
                    navigate("/" + page.username + "/about", {
                      state: { page: page, isPage: true, isUserPage: true },
                    })
                  }
                >
                  <span className="col-two">{page.name}</span>
                  <span className="col-one">
                    <img
                      src={
                        process.env.REACT_APP_BACK +
                        `/avatars/${page.avatarPic}`
                      }
                    ></img>
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <div>
              {userGroups.slice(0, 4).map((page, index) => (
                <div
                  className="panel-row"
                  key={index}
                  onClick={() =>
                    navigate("/" + page.username + "/about", {
                      state: { page: page, isPage: true, isUserPage: true },
                    })
                  }
                >
                  <span className="col-two">{page.name}</span>
                  <span className="col-one">
                    <img
                      src={
                        process.env.REACT_APP_BACK +
                        `/avatars/${page.avatarPic}`
                      }
                    ></img>
                  </span>
                </div>
              ))}
            </div>
          )}

          {userGroups.length > 4 && !showAllGroups && (
            <div>
              {" "}
              <Button
                className="view-more-button"
                onClick={() => setShowAllGroups(!showAllGroups)}
              >
                بیشتر ...
              </Button>
            </div>
          )}
          {userGroups.length > 4 && showAllGroups && (
            <div>
              {" "}
              <Button
                className="view-more-button"
                onClick={() => setShowAllGroups(!showAllGroups)}
              >
                {" "}
                کمتر ...
              </Button>
            </div>
          )}

          <div className="panel-row" onClick={() => setCreateGroupDialog(true)}>
            <div className="col-two" style={{ color: "#af734f" }}>
              ایجاد گروه جدید
            </div>
            <div className="col-one">
              <i className="pi pi-plus-circle" style={{ color: "#af734f" }}></i>
            </div>
          </div>

          <br />
          <br />

          <div
            className="panel-row2"
            onClick={() => navigate("/search/posts", { state: { user: user } })}
          >
            <div className="col-two">پست های پیشنهادی</div>
            <div className="col-one">
              <i className="pi pi-folder"></i>
            </div>
          </div>

          <div
            className="panel-row2"
            onClick={() => navigate("/search/users", { state: { user: user } })}
          >
            <div className="col-two">پیشنهاد افراد</div>
            <div className="col-one">
              <i className="pi pi-users"></i>
            </div>
          </div>

          <div
            className="panel-row2"
            onClick={() => navigate("/notification", { state: { user: user } })}
          >
            <div className="col-two">پست های ذخیره شده</div>
            <div className="col-one">
              <i className="pi pi-bookmark"></i>
            </div>
          </div>
        </div>
      ) : null}

      {/* Search Pannel /***************************************************************************************/}
      {props.type === "search" ? (
        <div id="search-panel">
          <p>جستجو بر اساس</p>
          <br />
          <Link
            to="/search/users"
            onClick={() => document.getElementById("search-button").focus()}
          >
            <div className="panel-row3">
              <div className="col-two">کاربر</div>
              <div className="col-one">
                <i className="pi pi-user"></i>
              </div>
            </div>
          </Link>

          

          <Link
            to="/search/horses"
            onClick={() => document.getElementById("search-button").focus()}
          >
            <div className="panel-row3">
              <div className="col-two">اسب</div>
              <div className="col-one">
                <i className="pi pi-star"></i>
              </div>
            </div>
          </Link>
          <Link
            to="/search/shops"
            onClick={() => document.getElementById("search-button").focus()}
          >
            <div className="panel-row3">
              <div className="col-two">فروشگاه</div>
              <div className="col-one">
                <i className="pi pi-shopping-bag"></i>
              </div>
            </div>
          </Link>

          <Link
            to="/search/farms"
            onClick={() => document.getElementById("search-button").focus()}
          >
            <div className="panel-row3">
              <div className="col-two">باشگاه</div>
              <div className="col-one">
                <i className="pi pi-home"></i>
              </div>
            </div>
          </Link>

          <Link
            to="/search/events"
            onClick={() => document.getElementById("search-button").focus()}
          >
            <div className="panel-row3">
              <div className="col-two">مسابقه</div>
              <div className="col-one">
                <i className="pi pi-video"></i>
              </div>
            </div>
          </Link>

          
          {/* <Link
            to="/search/posts"
            onClick={() => document.getElementById("search-button").focus()}
          >
            <div className="panel-row3">
              <div className="col-two">پست</div>
              <div className="col-one">
                <i className="pi pi-pencil"></i>
              </div>
            </div>
          </Link> */}

          {/* <Link
            to="/search/pages"
            onClick={() => document.getElementById("search-button").focus()}
          >
            <div className="panel-row3">
              <div className="col-two">همه صفحه ها</div>
              <div className="col-one">
                <i className="pi pi-clone"></i>
              </div>
            </div>
          </Link> */}
        </div>
      ) : null}

      {/* Notification Pannel /***************************************************************************************/}
      {props.type === "notification" ? (
        <div id="notification-panel">
          <TabView
            id="notif-tab"
            style={{ direction: "rtl" }}
            activeIndex={notifTabIndex}
            onTabChange={(e) => notifTabHandler(e.index)}
          >
            {/* بروزرسانی ها**************************************************************************/}

            <TabPanel header="بروزرسانی ها">
              {notifs.length == 0 ? (
                <div>
                  <img src={require("../images/nonews.png")}></img>
                  <p>!اینجا هیچ خبری نیست</p>
                </div>
              ) : null}
              {notifs.length != 0 ? (
                <div>

                  {notifs.map((notif, index) =>
                   
                      ((notif.sender_id !== null && notif.sender_id !== undefined && notif.sender_id._id !== user._id) || (notif.sender_id === null && notif.sender_id === undefined) ) && <div
                        key={index}
                        className={
                          notifCount.includes(notif._id)
                            ? "not-seen"
                            : "seen"
                        }
                      >
                        <Notification notif={notif} />
                      </div>
                    
                  )}


            

                </div>
              ) : null}


              {/* load animation */}
          {lazyLoading ? 
            <div className="spinner" style={{marginBottom:"50px"}}></div>
        : null}


            </TabPanel>

            <TabPanel header="پیام ها" id="message-panel">
              {messages.length != 0 ? (
                <div>
                  {messages.map((message, index) => (
                    <div key={index}>
                      <span className="notif-row">
                        {/* link to user profile: */}

                        <span className="notif-col1">
                          <img
                            src={
                              process.env.REACT_APP_BACK +
                              `/avatars/${message.partner_avatarPic}`
                            }
                          ></img>
                        </span>

                        <span className="notif-col2">
                          <div style={{ width: "100%", textAlign: "right" }}>
                            <span
                              style={{ fontWeight: "bold", marginLeft: "3px" }}
                            >
                              {message.partner_name}
                            </span>

                            <span>: {message.messageType!=="image" && message.messageType!=="video" ? message.content : null}</span>
                          </div>
                        </span>

                        {/* link to message */}

                        <span className="notif-col3">
                          <span>
                            <p
                              onClick={() =>
                                navigate(
                                  "/" + message.partner_username + "/about",
                                  {
                                    state: {
                                      otherUser: {
                                        _id: message.partner_id,
                                        username: message.partner_username,
                                        name: message.partner_name,
                                        job: "",
                                        avatarPic: message.partner_avatarPic,
                                      },
                                      profileOfOther: true,
                                      message: true,
                                    },
                                  }
                                )
                              }
                            >
                              {" "}
                              مشاهده پیام
                            </p>
                          </span>
                        </span>
                      </span>
                    </div>
                  ))}
                </div>
              ) : null}
            </TabPanel>
          </TabView>
        </div>
      ) : null}

      {/* Shop Pannel /***************************************************************************************/}
      {props.type === "shop" ? (
        <div id="shop-panel">
         


          <p> محصولات من</p>

          {showAllProducts ? (
            <div>
              {userProducts.map((post, index) => {
                return (
                  <div className={"user" + index} key={index}>
                    <div id="suggestion-box" className="search-row">
                      <span
                        className="row"
                        onClick={() => navigate("/posts/" + post._id)}
                      >
                        <span
                          className="col-one"
                          style={{ width: "70%", fontSize: "12px" }}
                        >
                          {post.description}
                        </span>
                        <span
                          className="col-three"
                          style={{
                            width: "30%",
                            cursor: "pointer",
                            marginRight: "0px",
                          }}
                        >
                          <Link to={"/posts/" + post._id}>
                            {post.fileType === "image" ? (
                              <img
                                src={`${process.env.REACT_APP_BACK}/posts/${post.file}`}
                                style={{ borderRadius: "0%" }}
                              ></img>
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_BACK}/posts/video-post-index.png`}
                                style={{ borderRadius: "0%" }}
                              ></img>
                            )}
                          </Link>
                        </span>
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              {userProducts.slice(0, 4).map((post, index) => {
                return (
                  <div className={"user" + index} key={index}>
                    <div id="suggestion-box" className="search-row">
                      <span
                        className="row"
                        onClick={() => navigate("/posts/" + post._id)}
                      >
                        <span
                          className="col-one"
                          style={{ width: "70%", fontSize: "12px" }}
                        >
                          {post.description}
                        </span>
                        <span
                          className="col-three"
                          style={{
                            width: "30%",
                            cursor: "pointer",
                            marginRight: "0px",
                          }}
                        >
                          <Link to={"/posts/" + post._id}>
                            {post.fileType === "image" ? (
                              <img
                                src={`${process.env.REACT_APP_BACK}/posts/${post.file}`}
                                style={{ borderRadius: "0%" }}
                              ></img>
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_BACK}/posts/video-post-index.png`}
                                style={{ borderRadius: "0%" }}
                              ></img>
                            )}
                          </Link>
                        </span>
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {userProducts.length > 4 && !showAllProducts && (
            <div>
              {" "}
              <Button
                className="view-more-button"
                onClick={() => setShowAllProducts(!showAllProducts)}
              >
                بیشتر ...
              </Button>
            </div>
          )}
          {userProducts.length > 4 && showAllProducts && (
            <div>
              {" "}
              <Button
                className="view-more-button"
                onClick={() => setShowAllProducts(!showAllProducts)}
              >
                {" "}
                کمتر ...
              </Button>
            </div>
          )}

          <p>جستجو در محصولات</p>
          <div className="panel-row2" onClick={() => navigate("../shop/rider")}>
            <div className="col-two">لوازم سوار</div>
            <div className="col-one">
              <i className="pi pi-user"></i>
            </div>
          </div>

          <div className="panel-row2" onClick={() => navigate("../shop/horse")}>
            <div className="col-two">لوازم اسب</div>
            <div className="col-one">
              <i className="pi pi-user"></i>
            </div>
          </div>

          <div className="panel-row2" onClick={() => navigate("../shop/horsesForSale")}>
            <div className="col-two">اسب</div>
            <div className="col-one">
              <i className="pi pi-user"></i>
            </div>
          </div>
        </div>
      ) : null}

      {/* Profile & Settings Pannel /***************************************************************************************/}
      {props.type === "profile" || props.type == "settings" ? (
        <div id="search-panel">
          <p>تنظیمات</p>
          <br />
          <Link to="/settings/account">
            <div className="panel-row3">
              <div className="col-two">حساب کاربری</div>
              <div className="col-one">
                <i className="pi pi-user"></i>
              </div>
            </div>
          </Link>

          <Link to="/settings/manage-pages">
            <div className="panel-row3">
              <div className="col-two">مدیریت صفحه ها و گروه ها</div>
              <div className="col-one">
                <i className="pi pi-clone"></i>
              </div>
            </div>
          </Link>

          <Link to="/settings/notifications">
            <div className="panel-row3">
              <div className="col-two">اعلان ها</div>
              <div className="col-one">
                <i className="pi pi-bell"></i>
              </div>
            </div>
          </Link>
        </div>
      ) : null}
    </div>
  );
}

export default NavPanel;
