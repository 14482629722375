import { useNavigate } from "react-router-dom";
import AllHorseProducts from "./AllHorseProducts";
import AllRiderProducts from "./AllRiderProducts";
import { useRef } from "react";

function AllProducts()
{

    const navigate = useNavigate();
    const horseRef=useRef();
    const riderRef=useRef();

    return(
        <div id="posts-section">

            <div>
            <h2 style={{marginBottom:"0px"}}>آخرین محصولات</h2>
            <p>تمامی محصولات اسب و سوار از برترین برندهای خارجی و ایرانی را می توانید اینجا پیدا کنید</p>
            </div>

            <div>
                <span id="all-products-box">

                    <div 
                    style={{marginRight:"60px",marginLeft:"30px"}}
                    onClick={()=>horseRef.current?.scrollIntoView({ behavior: 'smooth'})} >
                    <img src={require("../images/horseProductsIndex.jpg")}></img>
                    <h2 className="fade-in">لوازم اسب</h2>
                    </div>

                    <div 
                    style={{marginRight:"30px",marginLeft:"60px"}} 
                    onClick={()=>riderRef.current?.scrollIntoView({ behavior: 'smooth'})}>
                    <img src={require("../images/riderProductsIndex.jpg")}></img>
                    <h2 className="fade-in" style={{animationDelay:"1.5s"}}>لوازم سوار</h2>
                    </div>
                </span>
            </div>

            
            

            <div ref={riderRef}><AllRiderProducts></AllRiderProducts></div>
            <hr class="solid divider"></hr>
            <div ref={horseRef}><AllHorseProducts ></AllHorseProducts></div>

        </div>
    );
}

export default AllProducts;