import { useFormik } from "formik";
import "../Css/PageForm.css";
import "../Css/Register.css";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import HorseDropdown from "./HorseDropdown";
import { useState } from "react";



function PageForm(props)
{
  const horseGender = ["مادیان", "سیلمی","اخته"];
  const horseActivity = ["پرش", "درساژ","زیبایی","کورس","سایر"];
  const pageDescrption = {horse:"افتخارات و ...",event:"تاریخ برگزاری و ...",shop:"نوع محصولات و ...",farm:"زمینه فعالیت ، متراژ، تعداد مانژ ها و ..."}
  const navigate = useNavigate();
  const [horseMother,setHorseMother]=useState("");
  const [horseFother,setHorseFother]=useState("");
  const token = JSON.parse(localStorage.getItem('userToken'));
  const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
  const handleHorseMotherChange = (selectedValue) => {
    setHorseMother(selectedValue.value);
  };
  const handleHorseFotherChange = (selectedValue) => {
    setHorseFother(selectedValue.value);
  };
  const pageForm = useFormik({
        initialValues: {
          name: "",
          username:"",
          description: "",
          country: "",
          city:"",
          //for horse
          age:"",
          gender:"",
          race:"",
          farm:"",
          activity:""

          
          
        },

        validate: async (values)=> 
        {

          let errors={};

          if(!values.name)
            errors.name = "نام صفحه الزامی است";

          if(! (await uniqueUsername(values.username)))
            errors.username = "این نام کاربری موجود نیست"

          if(!values.username)
            errors.username = "نام کاربری الزامی است";




          return errors;

          
       
      },
    
        onSubmit: (values) => {

          values.username = values.username.trim();
          values.username = values.username.toLowerCase();
          values.username = values.username.replace(/\s+/g,"_");
          var data = {type:props.type ,creatorName:props.creatorName , creator_id:props.user_id ,username: values.username, name:values.name ,description:values.description, country:values.country,city: values.city};
          
          if(props.type == "horse")
          {
            console.log(values.age);
          data["age"]=values.age;
          data["gender"]=values.gender;
          data["race"] = values.race;
          data["mother"]=horseMother;
          data["father"]=horseFother;
          data["farm"]=values.farm;
          data["activity"]=values.activity;
          }

          data=JSON.stringify(data);
     
          //creating user in database
     
       
        axios.post(process.env.REACT_APP_BACK+"/pages/create", data, config)
            .then(response => 
              {

                console.log("response",response);

                if (response.status == 202)
                {
                  

                  //Page is followed automatically by it's creator:
                  const data2 = JSON.stringify({ sender_id:props.user_id, sender_avatar:props.user_avatarPic, reciever_id:response.data.result._id, type:"follow"});
                  axios
                    .post(process.env.REACT_APP_BACK+"/notifications/follow-page", data2, config)
                    .catch((error) =>
                    {
                      if(error.response.data.statusCode===401)
                        navigate("/login")
                    })


                  props.close();
                  navigate("/" + values.username + "/about", { state: {page: response.data.result, isPage: true , isUserPage:true , justCreated:true,  showBadge: response.data.roset1 ? true : false} });

                 
                }
            })
            .catch(error => {
               console.log(error);
               if(error.response.data.statusCode===401)
                  navigate("/login")
            });
      
          
        },
      });
    
      const isFormFieldValid = (phone) =>
          !!(pageForm.touched[phone] && pageForm.errors[phone]);
        const getFormErrorMessage = (phone) => {
          return (
            isFormFieldValid(phone) && (
              <small className="p-error" style={{float: "right"}}>{pageForm.errors[phone]}</small>
            )
          );
        };


        const uniqueUsername = async (value) =>
        {
          

          let result;
          
          value=value.replace(/\s+/g,"_");
          
          await axios.get(process.env.REACT_APP_BACK+"/pages/check-username/"+value, config)
                    .then((res) => {
                      if(res.data)
                        result = true;
                      else
                        result =false;
                    })
                    .catch((error) => {
                        if (error.response.data.content === "The username has already been taken.") {
                            result=false;

                            if(error.response.data.statusCode===401)
                            navigate("/login")
                        }
                    })
          return result;

        }

    return(
        <div>

            


   
            <form onSubmit={pageForm.handleSubmit} className="p-fluid">

            <div className="two-columns">
              <div className="field col-one">
              <div>
              <label htmlFor="username">  نام‌کاربری (به انگلیسی)</label>
            </div>
            <InputText
            className={classNames({
              "p-invalid": isFormFieldValid("username"),
            })}
              id="username"
              name="username"
              value={pageForm.values.username}
              onChange={pageForm.handleChange}
              autoFocus
            />
            {getFormErrorMessage("username")}
              </div>

              <div className="field col-two">
              <div>
              <label htmlFor="name">نام </label>
            </div>
            <InputText
            className={classNames({
              "p-invalid": isFormFieldValid("name"),
            })}
              id="name"
              name="name"
              value={pageForm.values.name}
              onChange={pageForm.handleChange}
            />
            {getFormErrorMessage("name")}
              </div>
            </div>

       

            {/* for horse ************************************************************************/}
            { props.type == "horse" ?
            <div>
                <div className="two-columns">
              <div className="field col-one">
              <div>
              <label htmlFor="age">سال تولد (میلادی)
              
              </label>
            </div>
            <InputText
            className={classNames({
              "p-invalid": isFormFieldValid("age"),
            })}
              id="age"
              name="age"
              value={pageForm.values.age}
              onChange={pageForm.handleChange}
              
            />
            {getFormErrorMessage("age")}
              </div>

              <div className="field col-two">
              <div>
              <label htmlFor="gender">جنسیت </label>
            </div>

            <Dropdown
                    id="gender"
                    name="gender"
                    onChange={pageForm.handleChange}
                    value={pageForm.values.gender}
                    options={horseGender}
                    placeholder={"انتخاب کنید"}

                  />
        
            {getFormErrorMessage("gender")}
              </div>
            </div>

            <div className="two-columns">
              <div className="field col-one">
              <div>
              <label htmlFor="mother">نام مادر</label>
            </div>
            <HorseDropdown 
            className={classNames({
              "p-invalid": isFormFieldValid("mother"),
            })}
            onChange={handleHorseMotherChange}></HorseDropdown>
            {getFormErrorMessage("mother")}
              </div>

              <div className="field col-two">
              <div>
              <label htmlFor="father">نام پدر </label>
            </div>
            <HorseDropdown 
             className={classNames({
              "p-invalid": isFormFieldValid("father"),
            })}
            onChange={handleHorseFotherChange}></HorseDropdown>
            {getFormErrorMessage("father")}
              </div>
            </div>


            <div className="two-columns">
              <div className="field col-one">
              <div>
              <label htmlFor="race">نژاد</label>
            </div>
            <InputText
            className={classNames({
              "p-invalid": isFormFieldValid("race"),
            })}
              id="race"
              name="race"
              value={pageForm.values.race}
              onChange={pageForm.handleChange}
            />
            {getFormErrorMessage("race")}
              </div>

              <div className="field col-two">
              <div>
              <label htmlFor="farm">باشگاه </label>
            </div>
            <InputText
            className={classNames({
              "p-invalid": isFormFieldValid("farm"),
            })}
              id="farm"
              name="farm"
              value={pageForm.values.farm}
              onChange={pageForm.handleChange}
            />
            {getFormErrorMessage("farm")}
              </div>
            </div>

            <div className="field">
            <div>
              <label htmlFor="activity">زمینه فعالیت </label>
            </div>
            <Dropdown
                    id="activity"
                    name="activity"
                    value={pageForm.values.activity}
                    onChange={pageForm.handleChange}
                    options={horseActivity}
                    placeholder={"انتخاب کنید"}

                  />
          
          </div>

            </div>
            : null }
            {/* **********************************************************************************/}

          <div className="field">
            <div>
              <label htmlFor="description">توضیح </label>
            </div>
            <div style={{marginTop:"10px"}}>
            <InputTextarea
              id="description"
              name="description"
              value={pageForm.values.description}
              onChange={pageForm.handleChange}
              placeholder={pageDescrption[props.type]}
              
            />
            </div>
          </div>

          <div className="two-columns">
              <div className="field col-one">
                <span>
                  <label htmlFor="country">کشور</label>
                  <InputText
                    id="country"
                    name="country"
                    value={pageForm.values.country}
                    onChange={pageForm.handleChange}
                    
                  
                  />
                </span>
              </div>

              <div className="field col-two">
                <span>
                  <label htmlFor="city">شهر</label>
                  <InputText
                    id="city"
                    name="city"
                    value={pageForm.values.city}
                    onChange={pageForm.handleChange}
                    
                    
                  />
                </span>
              </div>
            </div>

      

            <div className="field">
              
                <span>
                  <Button type="submit" label="ذخیره" className="mt-2" />
                </span>
             

             
            </div>

        </form>
        </div>
        
    );
}

export default PageForm;