

function IntroItem(props)
{

    const image = "../images/" + props.icon;

    return(
        <div className="intro-item">
                <img className="pi" src={require("../images/" + props.icon)}></img>
                <h4 className="title">{props.title}</h4>
                <p className="subtitle">{props.subtitle}</p>
            </div>
    );
}

export default IntroItem;