import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Css/Post.css";
import { ConfirmDialog } from "primereact/confirmdialog";

function Comment(props) {
    const onlineUser = JSON.parse(localStorage.getItem('flickerNetwork') || "{}") || { username: "", name: "", avatarPic: "profile-index.png" };
    const navigate = useNavigate();
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` } };
    const [showViewMore, setShowViewMore] = useState(props.showViewMore);
    const [showConfirmDeleteComment, setShowConfirmDeleteComment] = useState(false);
    const [theme, setTheme] = useState(props.theme);

    const deleteComment = async (_id, post_id) => {
        try {
            const result = await axios.delete(`${process.env.REACT_APP_BACK}/notifications/comment/${_id}/${post_id}`, config);
            if (result.status === 200) {
                console.log(result.data);
                props.decrementCommentsCount();
                props.setShowComments(true);
            }
        } catch (error) {
            console.log(error);
            if (error.response.data.statusCode === 401) {
                navigate("/login");
            }
        }
    };

    const deleteArticleComment = async () => {
        try {
            const result = await axios.delete(`${process.env.REACT_APP_BACK}/articles/comment/${props.comment.content}/${props.comment.article_id}`, config);
            if (result.status === 200) {
                console.log("result", result.data);
                props.decrementCommentsCount(props.comment.content);
                props.setShowComments(true);
            }
        } catch (error) {
            console.log(error);
            if (error.response.data.statusCode === 401) {
                navigate("/login");
            }
        }
    };

    return (
        <div>
            <ConfirmDialog
                className={theme}
                visible={showConfirmDeleteComment}
                onHide={() => setShowConfirmDeleteComment(false)}
                message="آیا از حذف این نظر مطمئنید؟"
                header="تایید حذف"
                icon="pi pi-info-circle"
                acceptLabel="بله"
                rejectLabel="نه"
                accept={() => deleteArticleComment()}
                reject={() => setShowConfirmDeleteComment(false)}
            />

            <div id="prev-comments">
                {!props.isArticle ? (
                    <span className="row1" 
                        onClick={props.comment.sender_id ? 
                        () => navigate("/"+props.comment.sender_id.username+"/about", {
                            otherUser: {
                                _id: props.comment.sender_id._id,
                                username: props.comment.sender_id.username,
                                name: props.comment.sender_id.name,
                                job: props.comment.sender_id.job,
                                avatarPic: props.comment.sender_id.avatarPic
                            }, 
                            profileOfOther: props.comment.sender_id.username !== onlineUser.username 
                        })
                        : null
                        }
                    >
                        <img src={props.comment.sender_id ? 
                            `${process.env.REACT_APP_BACK}/avatars/${props.comment.sender_id.avatarPic}`
                            : `${process.env.REACT_APP_BACK}/avatars/deletedAcount-index.png`
                        } 
                        alt={props.comment.sender_id ? `فلیکا - ${props.comment.sender_id.name}`: "فلیکا- شبکه اجتماعی سوارکاری"}
                        />
                    </span>
                ) : (
                    <span className="row1">
                        <img src={`${process.env.REACT_APP_BACK}/avatars/profile-index.png`} />
                    </span>
                )}

                <span className="row2 search-box-wraper" >
                    <span style={{ display: "flex", width: props.isArticle ? "5%" : null, direction: props.isArticle ? "ltr" : null }}>
                        {!props.isArticle ? (
                            <h6 style={{ direction: "rtl", display: "flex", alignItems: "center", width: "50%" }} >
                                {props.comment.sender_id ? props.comment.sender_id.name : "کاربر حذف شده"}
                            </h6>
                        ) : null}

                        {!props.isArticle ? (
                            <span style={{ direction: "ltr", display: "flex", alignItems: "center", width: "50%" }}>
                                <i className="pi pi-reply"
                                    onClick={props.comment.sender_id ? () => props.replyClicked({ name: props.comment.sender_id.name, comment_id: props.comment._id, user_id: props.comment.sender_id._id }) : null}
                                    style={{ cursor: "pointer", marginRight: "15px" }}
                                />

                                {props.comment.sender_id && ((onlineUser._id === props.comment.sender_id._id) || (onlineUser._id === props.comment.reciever_id)) ? (
                                    <i className="pi pi-trash"
                                        onClick={() => { deleteComment(props.comment._id, props.comment.post_id); }}
                                        style={{ cursor: "pointer" }}
                                    />
                                ) : null}
                            </span>
                        ) : (
                            onlineUser.role === "manager" && (
                                <span>
                                    <i className="pi pi-trash"
                                        onClick={() => setShowConfirmDeleteComment(true)}
                                        style={{ cursor: "pointer", width: "100%", textAlign: "left" }}
                                    />
                                </span>
                            )
                        )}
                    </span>

                    <span className="at-text" style={{ marginBottom: "10px", marginTop: "10px", wordWrap: 'break-word', width: props.isArticle ? "95%" : null }}>
                        {props.comment.reply_to_user !== undefined ? "@" + props.comment.reply_to_user.name + ": " : null}
                        {props.comment.content}
                    </span>

                    {!props.isArticle ? (
                        <span id="comment-date">
                            {new Date(props.comment.date).toLocaleDateString('fa-IR')}
                        </span>
                    ) : null}
                </span>
            </div>

            {props.replies[props.comment._id] !== undefined && props.replies[props.comment._id] !== null ? (
                <div id="reply-comments">
                    {props.replies[props.comment._id].map((reply, index2) => (
                        <div key={index2}>
                            {showViewMore && !props.nestedReply && index2 === 0 && (
                                <p className="view-more-button"
                                    onClick={() => setShowViewMore(!showViewMore)}
                                    style={{ cursor: "pointer", textAlign: "right", paddingRight: "12%" }}
                                >
                                    {"بیشتر >"}
                                </p>
                            )}

                            {!showViewMore && (
                                <Comment
                                    comment={reply}
                                    replies={props.replies}
                                    decrementCommentsCount={props.decrementCommentsCount}
                                    setShowComments={props.setShowComments}
                                    replyClicked={(replyToData) => props.replyClicked(replyToData)}
                                    nestedReply={true}
                                    showViewMore={showViewMore}
                                />
                            )}
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
}

export default Comment;
