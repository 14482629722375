import axios from "axios";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ContextMenu } from "primereact/contextmenu";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Mention } from "primereact/mention";
import { Toast } from "primereact/toast";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Css/NewPost.css";
import { Dropdown } from "primereact/dropdown";

function ManagePosts(props)
{

    const [posts,setPosts] = useState([]);
    const [updatePosts,setUpdatePosts] = useState(0);
    const [selectedPost, setSelectedPost] = useState({file:"",creator:"",description:"",type:"",tags:"",date:""});
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showEditPost, setShowEditPost] = useState(false);
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const navigate = useNavigate();
    const toast = useRef(null);
    const cm = useRef(null);
    const [suggestions,setSuggestions] = useState();
    const [fetchedUsers,setFetchedUsers] = useState([]);
    const [editedCreator,setEditedCreator] = useState(selectedPost.creator!==null && selectedPost.creator!==undefined ?  selectedPost.creator._id : null);

    const customCellTemplate = (rowData, column)  => 
    {
      const maxLines = 4;
      const text = rowData[column.field] || '';
  
      // Split text by lines and get the first 4 lines
      const lines = text.split('\n').slice(0, maxLines);
  
      // Join the truncated lines and add ellipsis if there are more lines
      const truncatedText = lines.join('\n');
      const hasMoreLines = lines.length < text.split('\n').length;
      const ellipsis = hasMoreLines ? '...' : '';
  
      return (
        <>
          <span style={{textAlign:"right",marginRight:"10px"}} title={text}>{truncatedText}</span>
          {hasMoreLines && ellipsis}
        </>
      );
    }

    const handleUsernameChange = (e) =>
    {
        setEditedCreator(e.value);
    }

    //fetch all usernames:
    useEffect(() => {

      async function fetchAllUsers() {
        
        const result = await axios.get(process.env.REACT_APP_BACK+"/users/allUsernames/", config);

        const r = result.data;
        var rr = [];

        for(const i in r)
        {
          var temp={_id:r[i]._id, username: r[i].username };
          rr[i] = temp;
        }
    
        console.log("fetched all usernames:",rr);
        setFetchedUsers(rr);
    
        return rr;
    
      }

      if(showEditPost && fetchedUsers.length===0)
      {
        fetchAllUsers();
      }
    
    },[showEditPost]);


    const onSearch = (event) => {

        const fetchedTags = selectedPost.tags;
        //in a real application, make a request to a remote url with the query and return suggestions, for demo we filter at client side
        setTimeout(() => {
            const query = event.query;
            let suggestions;
    
            if (!query.trim().length) {
                suggestions = [...fetchedTags];
            }
            else {
                suggestions = fetchedTags.filter((fetchedTags) => {
                    return fetchedTags.toLowerCase().startsWith(query.toLowerCase());
                });
                //
              }
    
            setSuggestions(suggestions);
        }, 250);
    }

    const imageBodyTemplate = (rowData) => {

        return <Link to={"/posts/"+rowData._id} >
        {rowData.fileType==="image" ?
        <img src={`${process.env.REACT_APP_BACK}/posts/${rowData.file}`}
              style={{height:"50px",width:"50px",borderRadius:"0%"}}></img>
            :
        <img src={`${process.env.REACT_APP_BACK}/posts/video-post-index.png`}
            style={{height:"50px",width:"50px",borderRadius:"0%"}}></img>
      }
    
      </Link>
        
    }
    const dateBodyTemplate = (rowData) => {
        return new Date(rowData.date).toLocaleDateString('fa-IR');
    }
    const typeBodyTemplate = (rowData) => {
        if(rowData.type==="normal")
            return "معمولی";
        else if(rowData.type==="product")
            return "محصول";
        else
            return "";
    }
    const creatorBodyTemplate = (rowData) => {

        var result = "____";
        if(rowData.creator !== undefined && rowData.creator !== null)
        { 
        
        result = <Link to={"/"+  rowData.creator.username +"/about"}
            state={{otherUser: {_id:rowData.creator._id, username:rowData.creator.username, name:rowData.creator.name , job:rowData.creator.job , avatarPic:rowData.creator.avatarPic} 
            , profileOfOther:true}}>
      
        {rowData.creator.username}
        </Link>
        }
        else if(rowData.creatorPage !== undefined && rowData.creatorPage !== null)
        {
          result =
          <Link to={"/"+  rowData.creatorPage.username +"/about"}
          state={{otherUser: {_id:rowData.creatorPage._id, username:rowData.creatorPage.username, name:rowData.creatorPage.name , job:rowData.creatorPage.job , avatarPic:rowData.creatorPage.avatarPic} 
              , profileOfOther:true}}>
          {rowData.creatorPage.username}
          
          </Link>
        }
      
        
        return result;
    
    }
    const menuModel = [
        {label: 'ویرایش', icon: 'pi pi-fw pi-pencil', command: () => setShowEditPost(true)},
        {label: 'حذف', icon: 'pi pi-fw pi-trash', command: () => setShowConfirmDelete(true)}
    ];

    const deletePostFromDB = async (_id) => 
    {
      await axios.delete(process.env.REACT_APP_BACK+"/posts/" + _id, config)
      .then(response => 
        {
          if (response.status == 200)
          {
            console.log(response);      
            toast.current.show({severity: 'info', summary: 'پست حذف شد'});
            setUpdatePosts(updatePosts+1);    
            
          }

      })
      .catch(error => {
         console.log(error);
         if(error.response.data.statusCode===401)
              navigate("/login")
      });
    }

    //get all posts from database:
  useEffect(() => {
    async function getAllPosts() {

      
      const result = await axios.get(process.env.REACT_APP_BACK+"/posts/all")
      .then((result) => {
        if (result.status == 200) {
          setPosts(result.data);
          console.log("all posts",result.data);
          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
              navigate("/login")
      });

      
      return result.data;

    }
    getAllPosts();
  },[updatePosts]);

  //initialize formik:
  useEffect(() => {
    async function initForm() {

      editPostForm.values.description = selectedPost.description;
      editPostForm.values.tags = selectedPost.tags;
      editPostForm.values.likesCount = selectedPost.likesCount;
      editPostForm.values.savesCount = selectedPost.savesCount;
      editPostForm.values.commentsCount = selectedPost.commentsCount;
      setEditedCreator(selectedPost.creator._id);
      

    }
    initForm();
  },[selectedPost]);

  const editPostForm = useFormik({
    initialValues: {
      description: selectedPost.description,
      tags: selectedPost.tags,
      likesCount: selectedPost.likesCount,
      savesCount: selectedPost.savesCount,
      commentsCount: selectedPost.commentsCount
      
    },

    onSubmit: (values) => {


      const obj = {};

      for (const key in values) {
          obj[key] = values[key];
        }
        obj["creator"] = editedCreator;


      const data = JSON.stringify(obj);
 
      
   
    axios.patch(process.env.REACT_APP_BACK+"/posts/"+selectedPost._id, data, config)
        .then(response => 
          {




          if(response.status == 200)
          {
            console.log(response);
            setShowEditPost(false);
            setUpdatePosts(updatePosts+1);
      
          }

        })
        .catch(error => {
           console.log(error);
           if(error.response.data.statusCode===401)
              navigate("/login")
        });

        
      
    },
  });


    return(
        <div id="manage-posts">

            <h2>مدیریت پست ها</h2>

            <Toast ref={toast}></Toast>

            <ContextMenu model={menuModel} ref={cm} />

            <DataTable size="normal" value={posts} paginator rows={20} className="manager-table"
            contextMenuSelection={selectedPost}
            onContextMenuSelectionChange={e => setSelectedPost(e.value)}
            onContextMenu={e => cm.current.show(e.originalEvent)}>


            
            
            <Column filed="file" body={imageBodyTemplate} ></Column>
            <Column alignHeader="center" align="center" field="description" header="متن" body={customCellTemplate} sortable filter ></Column>
            <Column alignHeader="center" columnKey="creator" align="center"  header="سازنده" body={creatorBodyTemplate} sortable filter></Column>
            <Column alignHeader="center" field="tags" align="center" header="برچسب ها" sortable filter></Column>
            <Column alignHeader="center" field="type" align="center"  header="نوع" body={typeBodyTemplate} sortable filter ></Column>
            <Column alignHeader="center" field="likesCount" align="center"  header="لایکها" sortable></Column>
            <Column alignHeader="center" field="savesCount" align="center"  header="سیوها" sortable></Column>
            <Column alignHeader="center" field="commentsCount" align="center"  header="کامنت ها" sortable ></Column>
            <Column alignHeader="center" field="date" align="center" header="تاریخ ساخت" body={dateBodyTemplate} sortable></Column>
            
            
            </DataTable>

            <ConfirmDialog
            className={props.theme}
            visible={showConfirmDelete} 
            onHide={() => setShowConfirmDelete(false)} 
            message="آیا از حذف این کاربر مطمئنید؟"
            header="تایید حذف"
            icon="pi pi-info-circle"
            acceptLabel="بله"
            rejectLabel="نه"
            acceptClassName="accept"
            rejectClassName="reject"
            accept={() => deletePostFromDB(selectedPost._id)} 
            reject={() => setShowConfirmDelete(false)} />

            <Dialog
              className={props.theme}
                id="edit-user-dialog"
                header="ویرایش پست"
                visible={showEditPost}
                onHide={() => setShowEditPost(false)}
                style={{ width: "40vw" }}
                >
            
          <form onSubmit={editPostForm.handleSubmit} className="p-fluid">


          {selectedPost.fileType=="image" && (
        <img
          className="VideoInput_video"
          width="100%"
          height="fit-content"
          borderRadiu="5px"
          src={process.env.REACT_APP_BACK+`/posts/${selectedPost.file}`}
        />
      )}

      
    {selectedPost.fileType=="video" && (
        <video
          className="VideoInput_video"
          width="100%"
          height="fit-content"
          controls
          src={process.env.REACT_APP_BACK+`/posts/${selectedPost.file}`}
        />
      )}

        <div className="field">
          <InputTextarea
          id="description"
          name="description"
          value={editPostForm.values.description}
          onChange={editPostForm.handleChange}
            rows={5}
            cols={30}
            autoResize
            
          />

          </div>


          <div className="field">
          <h6 style={{direction:"rtl", textAlign:"right", marginTop:"15px"}}>برچسب ها:</h6>

          <Mention
          id="tags"
          name="tags"
          value={editPostForm.values.tags}
          placeholder={selectedPost.tags}
          onChange={editPostForm.handleChange}
            rows={1}
            cols={1}
            autoResize
            trigger={['#']} 
          suggestions={suggestions} 
          onSearch={onSearch}  
          />
          </div>


            <div className="two-columns">
              <div className="field col-one">
              <label htmlFor="bio">لایک ها</label>
              <label htmlFor="bio" style={{fontSize:"12px"}}>دقت کنید که کسانیکه لایک کرده اند را باید دستی اصلاح کنید، اینجا فقط عدد اصلاح می شود</label>
                <span>
                <InputText
                  id="likesCount"
                  name="likesCount"
                  value={editPostForm.values.likesCount}
                  onChange={editPostForm.handleChange}
                />
                </span>
              </div>

              <div className="field col-two">
              <label htmlFor="bio">سیو ها</label>
              <label htmlFor="bio" style={{fontSize:"12px"}}>دقت کنید که کسانیکه سیو کرده اند را باید دستی اصلاح کنید، اینجا فقط عدد اصلاح می شود</label>
                <span>
                <InputText
                  id="savesCount"
                  name="savesCount"
                  value={editPostForm.values.savesCount}
                  onChange={editPostForm.handleChange}
                />
                </span>
              </div>
            </div>


          <div className="field">
          <label htmlFor="bio">کامنت ها</label>
              <label htmlFor="bio" style={{fontSize:"12px"}}>دقت کنید که کسانیکه کامنت کرده اند را باید دستی اصلاح کنید، اینجا فقط عدد اصلاح می شود</label>
                <span>
                <InputText
                  id="commentsCount"
                  name="commentsCount"
                  value={editPostForm.values.commentsCount}
                  onChange={editPostForm.handleChange}
                />
                </span>
          </div>
          
            <div className="field">
          <h6 style={{direction:"rtl", textAlign:"right", marginTop:"15px"}}>سازنده:</h6>         

                    <Dropdown
                    id="creator"
                    name="creator"
                    value={editedCreator}
                    onChange={handleUsernameChange}
                    placeholder={selectedPost.creator.username}
                    options={fetchedUsers}
                    optionLabel="username"
                    optionValue="_id"
                    

                  />
          </div>


            <div className="two-columns">
              <div className="field col-one">
                <span>
                  <Button type="submit" label="ذخیره" className="mt-2" />
                </span>
              </div>

              <div className="field col-two">
                <span>
                  <Button
                    className="outline-button mt-2"
                    type="button"
                    label="انصراف"
                    onClick={() => setShowEditPost(false)}
                  />
                </span>
              </div>
            </div>

            
          </form>
        

        
            </Dialog>
        </div>
    );
}

export default ManagePosts;