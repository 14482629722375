import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Css/HomePage.css";

function AllPages()
{
    const navigate = useNavigate();
    const [fetchedPages, setFetchedPages] = useState([]);
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    
    //go to top of the page:
    useEffect(() => {
      window.scrollTo(0, 0)
    }, []);
  

    //fetching suggested pages:
   useEffect(() => {
    async function fetchPages() {
      
      
        axios
          .get(process.env.REACT_APP_BACK+"/pages/suggest/" + 1000000, config)
          .then((result) => {
            if (result.status == 200) {
              setFetchedPages(result.data);
              console.log("fetched suggested pages: ", result.data);
              return result.data;
            }
          })
          .catch((error) => {
            console.log(error);
            if(error.response.data.statusCode===401)
                  navigate("/login")
          });
      
    }

    fetchPages();
  }, []);


    return (

        <div id="pages-section" style={{marginTop:"0px"}}>

            <h2>همه صفحه های فلیکا</h2>

            {fetchedPages.map((page, index) => (
            
            page.type != "group" ? 

            <div className="intro-item" 
            key={index}
            onClick={() => {
              navigate("/" + page.username + "/about", { state: {page: page, isPage: true , isUserPage:false } });
              }}>
                <img className="pi pi-images" src={`${process.env.REACT_APP_BACK}/avatars/${page.avatarPic}`} alt={`فلیکا - ${page.name}`}></img>
                <h4 className="title">{page.name}</h4>
                <p className="subtitle">{page.description}</p>
            </div>
            
            : null

          ))}


        </div>
    );
}

export default AllPages;