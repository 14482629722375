import axios from "axios";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useNavigate } from "react-router-dom";

function EditProfileForm(props)
{
    const gender = ["زن", "مرد"];
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const navigate = useNavigate();
    const profileForm = useFormik({
        initialValues: {
          name: props.user.name,
          job: props.user.job,
          bio: props.user.bio,
          awards: props.user.awards,
          country: props.user.country,
          city: props.user.city,
          gender: props.user.gender,
          birthday: props.user.birthday,
        },
    
        onSubmit: (values) => {



          const obj = {};

          for (const key in values) {

            if(key=="name" || key == "gender")
            {
              if(values[key] != "")
              obj[key] = values[key];
            }
            else
            {
              obj[key] = values[key];
            }
            
        }

        

          const data = JSON.stringify(obj);
     
        
        axios.patch(process.env.REACT_APP_BACK+"/users/update-profile/"+props.user._id, data, config)
            .then(response => 
              {
                console.log("response",response);
              if(response.status == 202)
              {
                if(response.data.roset1)
                  props.setShowBadge(true);

                props.updateProfile();
                props.close();

                //update user data in local storage:
                const u = JSON.parse(localStorage.getItem('flickerNetwork'));
                if(values["name"] != "")
                  u.name = values["name"];
                if(values["job"] != "")
                  u.job = values["job"];
                
                localStorage.setItem("flickerNetwork", JSON.stringify(u));
                
          
              }

            })
            .catch(error => {
               console.log(error);
               if(error.response.data.statusCode===401)
               navigate("/login")
            });

            
          
        },
      });
    
    return(
        <div>
          <form onSubmit={profileForm.handleSubmit} className="p-fluid">
          <div className="field">
                <span>
                  <label htmlFor="name">نام و نام خانوادگی</label>
                  <InputText
                    id="name"
                    name="name"
                    value={profileForm.values.name}
                    onChange={profileForm.handleChange}
                    className={props.user.name != null ? "has-value" : "no-value"}
                    maxLength={30} 
                  />
                </span>
  
            
            </div>

            <div className="field">
              <label htmlFor="job">معرفی کوتاه</label>

              <span className=" p-input-icon-left">
                <InputText
                  id="job"
                  name="job"
                  value={profileForm.values.job}
                  onChange={profileForm.handleChange}
                  placeholder={"مثلاً: سوارکار یا مربی درساژ"}
                  className={props.user.job != null ? "has-value" : "no-value"}
                  maxLength={40} 
                />
              </span>
            </div>

            <div className="field">
              <label htmlFor="bio">بیوگرافی</label>

              <span className=" p-input-icon-left">
                <InputTextarea
                  id="bio"
                  name="bio"
                  value={profileForm.values.bio}
                  onChange={profileForm.handleChange}
                  placeholder="توضیح مختصری از خود"
                  className={props.user.bio != null ? "has-value" : "no-value"}
                  maxLength={250} 
                  
                />
              </span>
            </div>

            <div className="field">
              <label htmlFor="prises">جایزه ها</label>

              <span className=" p-input-icon-left">
                <InputTextarea
                  id="awards"
                  name="awards"
                  value={profileForm.values.prises}
                  onChange={profileForm.handleChange}
                  placeholder="مثلاً: نایب قهرمانی جام مالکین"
                  style={{ direction: "rtl" }}
                  className={props.user.awards != null ? "has-value" : "no-value"}
                  maxLength={250} 
                />
              </span>
            </div>

            <div className="two-columns">
              <div className="field col-one">
                <span>
                  <label htmlFor="country">کشور</label>
                  <InputText
                    id="country"
                    name="country"
                    value={profileForm.values.country}
                    onChange={profileForm.handleChange}
                    className={props.user.country != null ? "has-value" : "no-value"}
                    maxLength={30} 
                  />
                </span>
              </div>

              <div className="field col-two">
                <span>
                  <label htmlFor="city">شهر</label>
                  <InputText
                    id="city"
                    name="city"
                    value={profileForm.values.city}
                    onChange={profileForm.handleChange}
                    className={props.user.city != null ? "has-value" : "no-value"}
                    maxLength={30} 
                  />
                </span>
              </div>
            </div>

            <div className="two-columns">
              <div className="field col-one">
                <span>
                  <label htmlFor="gender">جنسیت</label>
                  <Dropdown
                    id="gender"
                    name="gender"
                    onChange={profileForm.handleChange}
                    value={profileForm.values.gender}
                    options={gender}
                    placeholder={props.user.gender}
                    

                  />
                </span>
              </div>

              <div className="field col-two">
                <span>
                  <label htmlFor="birthday">تاریخ تولد</label>
                  
                  <InputMask 
                  id="birthday"
                  name="birthday"
                  style={{ direction: "ltr", textAlign: "left" }}
                  mask="99/99/9999" 
                  slotChar="dd/mm/yyyy" 
                  value={profileForm.values.birthday}
                  onChange={profileForm.handleChange}
                  className={props.user.birthday != null ? "has-value" : "no-value"}
                   />

                  
 
                </span>
              </div>
            </div>

            <div className="two-columns">
              <div className="field col-one">
                <span>
                  <Button type="submit" label="ذخیره" className="mt-2" />
                </span>
              </div>

              <div className="field col-two">
                <span>
                  <Button
                    className="outline-button mt-2"
                    type="submit"
                    label="انصراف"
                    onClick={() => props.close()}
                  />
                </span>
              </div>
            </div>
          </form>
        </div>
    );
    
}

export default EditProfileForm;