import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import "../Css/Manager.css";
import '../Css/Dashboard.css';
import ManageUsers from "./ManageUsers";
import ManageFiles from "./ManageFiles";
import ManagePosts from "./ManagePosts";
import { useEffect, useState } from "react";
import axios from "axios";
import ManagePages from "./ManagePages";
import ManageTags from "./ManageTags";
import ManageNotifs from "./ManageNotifs";
import ManageLogins from "./ManageLogins";
import ManageActivities from "./ManageActivities";
import ManageArticles from "./ManageArticles";


function Manager(props)
{

  const user = JSON.parse(localStorage.getItem('flickerNetwork'));
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem('userToken'));
  const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
  const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
  const logout = () => 
  {
    console.log("atemped to log out");
  
    axios.get(process.env.REACT_APP_BACK+"/users/logout", config)
    .then((response) => {
      if (response.status == 200)
        navigate("/");
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
      if(error.response.data.statusCode===401)
              navigate("/login")
    });

    //delete user cache:
    localStorage.removeItem('flickerNetwork');

  
  }

  //if user cache is lost or user is not a manager, go to home page:
  useEffect(() => 
  {

    
      if( user === null)
          navigate("/login");
      
          //check role:
          axios.get(process.env.REACT_APP_BACK+"/users/role/"+user._id, config)
          .then((response) => {
            if (response.status == 200)
            {
                if(response.data != "manager")
                  navigate("/404");
            }
              
          })
          .catch((error) => {
            console.log(error);
            if(error.response.data.statusCode===401)
              navigate("/login")
          });

  });

  //check desktop or laptop:
  useEffect(() => {
    window
    .matchMedia("(min-width: 768px)")
    .addEventListener('change', e => setDesktop( e.matches ));
  }, []);

    return(

        <div id="manager">

    {desktop ? 

    <div id="nav-bar-wrap" style={{width:"6%"}}>
      <div id="logo-icon">
      <Link to="/manager-of-flicka">
          <img src={require("../images/logo.png")} />
      </Link>
      </div>

      <div id="nav-icons">

      <div className="nav-bar-item">

      <Link to="/manager-of-flicka/weblog">
      <Button tooltip="وبلاگ" tooltipOptions={{position:"left"}}>
        <i className="pi pi-book"></i>
      </Button>
      </Link>
      </div>


        <div className="nav-bar-item">

          <Link to="/manager-of-flicka/users">
          <Button tooltip="مدیریت کاربران" tooltipOptions={{position:"left"}}>
            <i className="pi pi-users"></i>
          </Button>
          </Link>
        </div>

        <div className="nav-bar-item">
        <Link to="/manager-of-flicka/posts">
          <Button id="search-button" tooltip="مدیریت پست ها" tooltipOptions={{position:"left"}}>
            <i className="pi pi-images"></i>
          </Button>
          </Link>
        </div>

        <div className="nav-bar-item">

          <Link to="/manager-of-flicka/pages">
          <Button tooltip="مدیریت صفحه ها" tooltipOptions={{position:"left"}}>
            <i className="pi pi-file"></i>
          </Button>
          </Link>
        </div>

        <div className="nav-bar-item">
       
        <Link to="/manager-of-flicka/files">
          <Button tooltip="مدیریت فایل ها" tooltipOptions={{position:"left"}}>
            <i className="pi pi-server"></i>
          </Button>
          </Link>

          </div>

          <div className="nav-bar-item">
      <Link to="/manager-of-flicka/tags">
          <Button id="search-button">
            <i className="pi pi-tags"></i>
          </Button>
      </Link>
      </div>

      <div className="nav-bar-item">
      <Link to="/manager-of-flicka/notifications">
          <Button id="search-button">
            <i className="pi pi-bell"></i>
          </Button>
      </Link>
      </div>


      <div className="nav-bar-item">

          <Link to="/manager-of-flicka/activities">
          <Button tooltip="مدیریت اکتیویتی ها" tooltipOptions={{position:"left"}}>
            <i className="pi pi-history"></i>
          </Button>
          </Link>
        </div>


      <div className="nav-bar-item">
          
          <Button onClick={logout} tooltip="خروج" tooltipOptions={{position:"left"}}>
            <i className="pi pi-sign-out"></i>
          </Button>
          
          
          
        </div>

       
      </div>

     
      </div>

      :null}

      <div id="manager-panel">
        
        {props.type === "weblog" ? <ManageArticles/>: null}
        {props.type === "users" ? <ManageUsers/>: null}
        {props.type === "posts" ? <ManagePosts/>: null}
        {props.type === "files" ? <ManageFiles/>: null}
        {props.type === "pages" ? <ManagePages/>: null}
        {props.type === "tags" ? <ManageTags/>: null}
        {props.type === "logins" ? <ManageLogins/>: null}
        {props.type === "notifs" ? <ManageNotifs/>: null}
        {props.type === "activities" ? <ManageActivities/>: null}
        {props.type === "dashboard" ? 
        <div style={{paddingBottom: "100px"}}>
          <div >
            <h3>سلام 
              {" "+user.name+" "}
              عزیز،
            </h3>
            <h3>به پنل مدیریت خوش آمدید</h3>
            <Link id="manager-visit-site-text"  style={{color:"#000000" , fontWeight:"bolder"}} to="/feed">بازدید از سایت</Link>
            </div>



            <div className="intro-container">

            <div className="intro-item" onClick={()=>{navigate("/manager-of-flicka/weblog")}}>
                    <i className="pi pi-book"></i>
                    <h4 className="title">وبلاگ</h4>
                    <p className="subtitle">انتشار و ادیت مقاله</p>
                </div>
    
            <div className="intro-item" onClick={()=>{navigate("/manager-of-flicka/login_as_others")}}>
                <i className="pi pi-sign-in"></i>
                <h4 className="title">لاگین با حساب سایرین</h4>
                <p className="subtitle">به جای بقیه لاگین کنید</p>
            </div>


            <div className="intro-item" onClick={()=>{navigate("/manager-of-flicka/users")}}>
                <i className="pi pi-users"></i>
                <h4 className="title">مدیریت کاربران</h4>
                <p className="subtitle">ویرایش و حذف  کاربران</p>
            </div>

            <div className="intro-item" onClick={()=>{navigate("/manager-of-flicka/pages")}}>
                <i className="pi pi-file"></i>
                <h4 className="title">مدیریت صفحه ها</h4>
                <p className="subtitle">ویرایش و حذف صفحه ها</p>
            </div>
            

            
            <div className="intro-item" onClick={()=>{navigate("/manager-of-flicka/posts")}}>
                <i className="pi pi-images"></i>
                <h4 className="title">مدیریت پست ها</h4>
                <p className="subtitle">ویرایش و حذف پست ها</p>
            </div>

            
            
            

      </div>
      

      <div className="intro-container" style={{marginTop:"10px"}}>
    
            <div className="intro-item" onClick={()=>{navigate("/manager-of-flicka/files")}}>
                <i className="pi pi-server"></i>
                <h4 className="title">مدیریت فایل ها</h4>
                <p className="subtitle">عکس و ویدیو پست ها، آواتارها و کاورها</p>
            </div>

            
            <div className="intro-item" onClick={()=>{navigate("/manager-of-flicka/tags")}}>
                <i className="pi pi-tags"></i>
                <h4 className="title">مدیریت برچسب ها</h4>
                <p className="subtitle">ویرایش و حذف  برچسب ها</p>
            </div>

            <div className="intro-item" onClick={()=>{navigate("/manager-of-flicka/notifications")}}>
                <i className="pi pi-bell"></i>
                <h4 className="title">مدیریت اعلان ها</h4>
                <p className="subtitle">ویرایش و حذف اعلان ها</p>
            </div>

            <div className="intro-item" onClick={()=>{navigate("/manager-of-flicka/activities")}}>
                <i className="pi pi-history"></i>
                <h4 className="title">مدیریت اکتیویتی ها</h4>
                <p className="subtitle">ویرایش و حذف اکتیویتی ها</p>
            </div>
            

         
            

      </div>
        </div>

        
        
        : null}

      </div>


    {/* mobile nav ********************************************************************************/}
    {!desktop ? 

    <div 
    id="mobile-manager-nav"
    style={{position: "fixed",bottom:"0",direction:"rtl",width:"100%",background:"#ffffff",boxShadow: "rgba(0, 0, 0, 0.81) 0px 1px 4px"}}>

    <span style={{display:"flex"}} id="nav-icons">

    <span style={{display:"flex",direction:"rtl",width:"85%"}}>

      <div className="nav-bar-item">
       <div id="logo-icon">
      <Link to="/manager-of-flicka">
          <img src={require("../images/logo.png")} />
      </Link>
      </div>
      </div>

      <div className="nav-bar-item">
      <Link to="/manager-of-flicka/users">
          <Button>
            <i className="pi pi-users"></i>
          </Button>
      </Link>
      </div>

      <div className="nav-bar-item">
      <Link to="/manager-of-flicka/pages">
          <Button id="search-button">
            <i className="pi pi-file"></i>
          </Button>
      </Link>
      </div>


      <div className="nav-bar-item">
      <Link to="/manager-of-flicka/posts">
          <Button id="search-button">
            <i className="pi pi-images"></i>
          </Button>
      </Link>
      </div>

      

      <div className="nav-bar-item">
      <Link to="/manager-of-flicka/files">
          <Button>
            <i className="pi pi-server"></i>
          </Button>
      </Link>
      </div>

      <div className="nav-bar-item">
      <Link to="/manager-of-flicka/tags">
          <Button id="search-button">
            <i className="pi pi-tags"></i>
          </Button>
      </Link>
      </div>

      <div className="nav-bar-item">
      <Link to="/manager-of-flicka/notifications">
          <Button id="search-button">
            <i className="pi pi-bell"></i>
          </Button>
      </Link>
      </div>

      <div className="nav-bar-item">
      <Link to="/manager-of-flicka/activities">
          <Button id="search-button">
            <i className="pi pi-history"></i>
          </Button>
      </Link>
      </div>

      <div className="nav-bar-item">
      
      <Button onClick={logout}>
            <i className="pi pi-sign-out"></i>
          </Button>
      
      </div>

      </span>
      
   

    </span>
    </div>
    : null}

{/********************************************************************************************/}


    </div>

            

    );

}

export default Manager;