import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';

const HorseDropdown = ({ onChange ,value}) => {

    const [allHorses,setAllHorses] = useState([]);
    const [selectedOption, setSelectedOption] = useState(value);
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const navigate = useNavigate();
    

    const handleInputChange = (inputValue, { action }) => {
        if (action === 'input-change') {
          setSelectedOption({ value: inputValue, label: inputValue });
        }
      };
    
      const handleOptionChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        onChange(selectedOption); // Call the onChange callback with the selected option
      };
  
    
      const isValidNewOption = (inputValue, selectValue, selectOptions) => {
        return !inputValue || selectOptions.some((option) => option.value === inputValue);
      };
    
      const formatCreateLabel = (inputValue) => {
        return `Create option: "${inputValue}"`;
      };



    useEffect(() => {
    async function fetchHorses() 
    {
    
        
        await axios
        .get(process.env.REACT_APP_BACK+"/pages/search/horse/"+0, config)
        .then((result) => {
          if (result.status == 200) {
    
    
            let temp=[];
            let r = result.data;
            for(let i=0; i<r.length; i++)
            {
                temp.push({ value: r[i]._id, label: r[i].name });
            }

    
            setAllHorses(temp);
            console.log("fetched searched horses: ", temp);
            return result.data;
          }
        })
        .catch((error) => {
          console.log(error);
          if(error.response.data.statusCode===401)
              navigate("/login")
        });
        
   
    }

    fetchHorses();

}, []);

  
    return (
      <div>
       <Select
        value={selectedOption}
        options={allHorses}
        isClearable={true}
        isSearchable={true}
        onChange={handleOptionChange}
        onInputChange={handleInputChange}
        allowCreateWhileLoading={false}
        createOptionPosition="last"
        isValidNewOption={isValidNewOption}
        formatCreateLabel={formatCreateLabel}
        placeholder="انتخاب کنید..."
        
        
      />
      </div>
    );
  };
  
  export default HorseDropdown;
  