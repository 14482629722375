import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Link, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { ContextMenu } from 'primereact/contextmenu';
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import { InputSwitch } from "primereact/inputswitch";

function ManageUsers(props)
{
    const cm = useRef(null);
    const toast = useRef(null);
    const [users,setUsers] = useState([]);
    const [updateUsers,setUpdateUsers] = useState(0);
    const [selectedUser, setSelectedUser] = useState({name:"",email:"",phone:"",role:""});
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showEditUser, setShowEditUser] = useState(false);
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const navigate = useNavigate();
    const imageBodyTemplate = (rowData) => {
        return  <Link to={"/"+rowData.username+"/about"}
            state={{otherUser: {_id:rowData._id, username:rowData.username, name:rowData.name , job:rowData.job , avatarPic:rowData.creatorAvatar} 
            , profileOfOther:true}}>
        <img 
        src={`${process.env.REACT_APP_BACK}/avatars/${rowData.avatarPic}`}
        style={{height:"50px",width:"50px",borderRadius:"50%"}}></img>
        </Link>
        ;
    }
    const regDateBodyTemplate = (rowData) => {
        return new Date(rowData.regDate).toLocaleDateString('fa-IR');
    }
    
    const roleBodyTemplate = (rowData) => {
        if(rowData.role === "subscriber")
            return "مشترک";
        else if(rowData.role === "manager")
            return "مدیر کل";
        else 
            return "-";
        
    }

    
    const menuModel = [
        {label: 'ویرایش', icon: 'pi pi-fw pi-pencil', command: () => setShowEditUser(true)},
        {label: 'حذف', icon: 'pi pi-fw pi-trash', command: () => setShowConfirmDelete(true)}
    ];


    const deleteUserFromDB = async (_id) => 
    {

      //delete from followings and followers:
      await axios.delete(process.env.REACT_APP_BACK+"/notifications/delete-from-follower-and-following/" + _id + "/user", config)
      .catch((error)=>
      {
        if(error.response.data.statusCode===401)
              navigate("/login")
      });
      
      //delete user and her posts and settings:
      await axios.delete(process.env.REACT_APP_BACK+"/users/" + _id, config)
      .then(response => 
        {
          if (response.status == 200)
          {
            console.log(response);      
            toast.current.show({severity: 'info', summary: 'کاربر حذف شد'});
            setUpdateUsers(updateUsers+1);    
            
          }

      })
      .catch(error => {
         console.log(error);
         if(error.response.data.statusCode===401)
              navigate("/login")
      });
    }

   

    //get all users from database:
  useEffect(() => {
    async function getAllUsers() {

      
      const result = await axios.get(process.env.REACT_APP_BACK+"/users/all")
      .then((result) => {
        if (result.status == 200) {
          setUsers(result.data);
          console.log("all users",result.data);
          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
              navigate("/login")
      });

      
      return result.data;

    }
    getAllUsers();
  },[updateUsers]);


   //initialize formik:
   useEffect(() => {
    async function initForm() {

      editUserForm.values.name = selectedUser.name;
      editUserForm.values.email = selectedUser.email;
      editUserForm.values.phone = selectedUser.phone;
      editUserForm.values.followersCount = selectedUser.followersCount;
      editUserForm.values.followingsCount = selectedUser.followingsCount;
      editUserForm.values.roset1 = selectedUser.roset1 ? selectedUser.roset1 : 0;
      editUserForm.values.blueTick = selectedUser.blueTick ? selectedUser.blueTick : false;

      if(selectedUser.role === "manager")
        editUserForm.values.role = "مدیر کل";
      else
        editUserForm.values.role = "مشترک";
      

    }
    initForm();
  },[selectedUser]);

  const editUserForm = useFormik({
    initialValues: {
      name: selectedUser.name,
      email: selectedUser.email,
      phone: selectedUser.phone,
      role: selectedUser.role,
      followingsCount: selectedUser.followingsCount,
      followersCount: selectedUser.followersCount,
      roset1:selectedUser.roset1 ? selectedUser.roset1 : 0,
      blueTick: selectedUser.blueTick ? selectedUser.blueTick : false
    },

    onSubmit: (values) => {


      const obj = {};

      for (const key in values) {

        if(key === "role")
        {
          if(values[key] === "مشترک")
            obj[key] = "subscriber";
          else if(values[key]==="مدیر کل")
            obj[key] = "manager";
        }
        else
        {
          obj[key] = values[key];
        }
        
    }


      const data = JSON.stringify(obj);
   
    axios.patch(process.env.REACT_APP_BACK+"/users/manage-user/"+selectedUser.username, data, config)
        .then(response => 
          {

          if(response.status == 200)
          {
            console.log(response);
            setShowEditUser(false);
            setUpdateUsers(updateUsers+1);
      
          }

        })
        .catch(error => {
           console.log(error);
           if(error.response.data.statusCode===401)
              navigate("/login")
        });

        
      
    },
  });

    return(
        <div id="manage-users">
            
            {console.log("selected user",selectedUser)}

            <h2>مدیریت کاربران</h2>

            <div className="manager-table">

            <Toast ref={toast}></Toast>

            <ContextMenu model={menuModel} ref={cm} />
            
            <DataTable size="normal" value={users} paginator rows={20} editMode="row"
            contextMenuSelection={selectedUser}
            onContextMenuSelectionChange={e => setSelectedUser(e.value)}
            onContextMenu={e => cm.current.show(e.originalEvent)}>


            
            
            <Column filed="avatarPic" body={imageBodyTemplate} ></Column>
            <Column alignHeader="center" columnKey="username" align="center" field="username" header="نام کاربری" sortable filter ></Column>
            <Column alignHeader="center" columnKey="name" align="center" field="name" header="نام و نام خانوادگی" sortable filter></Column>
            <Column alignHeader="center" columnKey="email" align="center" field="email" header="ایمیل" sortable filter ></Column>
            <Column alignHeader="center" columnKey="phone" align="center" field="phone" header="شماره تلفن" sortable filter ></Column>
            <Column alignHeader="center" columnKey="followingsCount" align="center" field="followingsCount" header="دنبال می کند" sortable  ></Column>
            <Column alignHeader="center" columnKey="followersCount" align="center" field="followersCount" header="دنبال کننده" sortable  ></Column>
            <Column alignHeader="center" columnKey="roset1" align="center" field="roset1" header="روزت 1" sortable  ></Column>
            <Column alignHeader="center" columnKey="role" align="center" field="role" header="نقش" body={roleBodyTemplate} sortable></Column>
            <Column alignHeader="center" columnKey="regDate" align="center" field="regDate" header="تاریخ عضویت" body={regDateBodyTemplate} sortable></Column>
            <Column alignHeader="center" columnKey="accountType" align="center" field="accountType" header="نوع حساب" sortable  ></Column>
            
            </DataTable>
            </div>

            <ConfirmDialog 
            className={props.theme}
            visible={showConfirmDelete} 
            onHide={() => setShowConfirmDelete(false)} 
            message="آیا از حذف این کاربر مطمئنید؟"
            header="تایید حذف"
            icon="pi pi-info-circle"
            acceptLabel="بله"
            rejectLabel="نه"
            acceptClassName="accept"
            rejectClassName="reject"
            accept={() => deleteUserFromDB(selectedUser._id)} 
            reject={() => setShowConfirmDelete(false)} />

            <Dialog
            className={props.theme}
                id="edit-user-dialog"
                header="ویرایش کاربر"
                visible={showEditUser}
                onHide={() => setShowEditUser(false)}
                >
            
          <form onSubmit={editUserForm.handleSubmit} className="p-fluid">
          <div className="field">
                <span>
                  <label htmlFor="name">نام و نام خانوادگی</label>
                  <InputText
                    id="name"
                    name="name"
                    value={editUserForm.values.name}
                    onChange={editUserForm.handleChange}
                  />
                </span>
  
            
            </div>

            <div className="field">
              <label htmlFor="bio">ایمیل</label>
              <span className=" p-input-icon-left">
                <InputText
                  id="email"
                  name="email"
                  value={editUserForm.values.email}
                  onChange={editUserForm.handleChange}
                />
              </span>
            </div>

            <div className="field">
              <label htmlFor="prises">شماره تلفن</label>
              <span className=" p-input-icon-left">
                <InputText
                  id="phone"
                  name="phone"
                  value={editUserForm.values.phone}
                  onChange={editUserForm.handleChange}
                  
                />
              </span>
            </div>

            <div className="field">
              <label htmlFor="followingsCount">دنبال می کند</label>
              <label htmlFor="followingsCount" style={{fontSize:"12px"}}>دقت کنید، پیج ها را باید دستی آنفالو کنید</label>
              <span className=" p-input-icon-left">
                <InputText
                  id="followingsCount"
                  name="followingsCount"
                  value={editUserForm.values.followingsCount}
                  onChange={editUserForm.handleChange}
                />
              </span>
            </div>

            <div className="field">
              <label htmlFor="followersCount">دنبال کننده</label>
              <label htmlFor="followingsCount" style={{fontSize:"12px"}}>دقت کنید، پیج ها را باید دستی آنفالو کنید</label>
              <span className=" p-input-icon-left">
                <InputText
                  id="followersCount"
                  name="followersCount"
                  value={editUserForm.values.followersCount}
                  onChange={editUserForm.handleChange}
                />
              </span>
            </div>

            <div className="field">
              <label htmlFor="roset1">روزت 1</label>
              <span className=" p-input-icon-left">
                <InputText
                  id="roset1"
                  name="roset1"
                  value={editUserForm.values.roset1}
                  onChange={editUserForm.handleChange}
                />
              </span>
            </div>

            <div className="field">
              <label htmlFor="prises">نقش</label>
              <span className=" p-input-icon-left">
              <Dropdown
                    id="role"
                    name="role"
                    onChange={editUserForm.handleChange}
                    value={editUserForm.values.role}
                    options={["مشترک","مدیر کل"]}

                  />
              </span>
            </div>

            <div className="field">
              <label htmlFor="prises">تیک آبی</label>
              <span className=" p-input-icon-left">
              <InputSwitch
                    id="blueTick"
                    name="blueTick"
                    onChange={editUserForm.handleChange}
                    value={editUserForm.values.blueTick}
                    checked={editUserForm.values.blueTick}

                  />
              
              </span>
            </div>
        

          

            <div className="two-columns">
              <div className="field col-one">
                <span>
                  <Button type="submit" label="ذخیره" className="mt-2" />
                </span>
              </div>

              <div className="field col-two">
                <span>
                  <Button
                    className="outline-button mt-2"
                    type="button"
                    label="انصراف"
                    onClick={() => setShowEditUser(false)}
                  />
                </span>
              </div>
            </div>
          </form>
        

        
            </Dialog>

        </div>
    );
}

export default ManageUsers;