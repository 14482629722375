import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function SocialForm(props)
{

  const token = JSON.parse(localStorage.getItem('userToken'));
  const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
  const navigate = useNavigate();
  const socialForm = useFormik({
        initialValues: {
          phone: props.user.phone,
          email: props.user.email,
          website: props.user.website,
          instagram: props.user.instagram,
          telegram: props.user.telegram,
          whatsapp: props.user.whatsapp,
          linkedin: props.user.linkedin,
          twitter: props.user.twitter,
          facebook: props.user.facebook,
          youtube: props.user.youtube,
          tell1:props.user.tell1,
          tell2:props.user.tell2,
          address:props.user.address
        },
    
        onSubmit: (values) => {

          const obj = {};

          for (const key in values) {

            
              if(key == "phone" || key == "email" || key=="tell1" || key=="tell2")
              {
                if(values[key] != "" && values[key] )
                {
                  if(key == "phone")
                    obj[key] = values.phone.replace(/[^0-9]+/g, "");
                  else if(key=="tell1")
                    obj[key] = values.tell1.replace(/[^0-9]+/g, "");
                  else if(key=="tell2")
                    obj[key] = values.tell2.replace(/[^0-9]+/g, "");
                  else
                  obj[key] = values[key];

                }
              }
              else
                obj[key] = values[key];
            
            
            
        }


    console.log("obj",obj)
          const data = JSON.stringify(obj);
     

          if(!props.isPage)
          {
            axios.patch(process.env.REACT_APP_BACK+"/users/update-profile/"+props.user._id, data, config)
            .then(response => {

              if(response.status == 200)
              console.log(response)
              props.updateProfile();
                props.close();
            })
            .catch(error => {
               console.log(error);
               if(error.response.data.statusCode===401)
                    navigate("/login")
            });

          }
          else
          {
            axios.patch(process.env.REACT_APP_BACK+"/pages/update-social/"+props.user._id, data, config)
            .then(response => {

              if(response.status == 200)
              console.log(response)
              props.updatePage();
                props.close();
            })
            .catch(error => {
               console.log(error);
               if(error.response.data.statusCode===401)
              navigate("/login")
            }); 
          }
          
        },
      });

    return(
        <div>
          <form onSubmit={socialForm.handleSubmit} className="p-fluid">
            <div className="field">
              <div>
                <label htmlFor="phone">تلفن همراه</label>
              </div>
              
              <InputMask 
              id="phone"
              readOnly= {!props.isPage ? "readonly" : null }
              name="phone"
              mask="(0999) 999-99-99" 
              value={socialForm.values.phone}
              onChange={socialForm.handleChange}
              style={{ direction: "ltr", textAlign: "left" }}
              placeholder={props.user.phone != null && props.user.phone != ""? null : "(0919)999-99-99"}
              className={props.user.phone != null ? "has-value" : "no-value"}

              ></InputMask>
 
            </div>

             {props.isPage && <div className="field">
              <div>
                <label htmlFor="tell1">تلفن ثابت 1</label>
              </div>
              
              <InputMask 
              id="tell1"
              name="tell1"
              mask="(099) 99999999" 
              value={socialForm.values.tell1}
              onChange={socialForm.handleChange}
              style={{ direction: "ltr", textAlign: "left" }}
              placeholder={props.user.telll1 != null && props.user.tell1 != ""? null : "(021) 99999999"}
              className={props.user.tell1 != null ? "has-value" : "no-value"}

              ></InputMask>
 
            </div>}

            {props.isPage && <div className="field">
              <div>
                <label htmlFor="tell2">تلفن ثابت 2</label>
              </div>
              
              <InputMask 
              id="tell2"
              name="tell2"
              mask="(099) 99999999" 
              value={socialForm.values.tell2}
              onChange={socialForm.handleChange}
              style={{ direction: "ltr", textAlign: "left" }}
              placeholder={props.user.tell2 != null && props.user.tell2 != ""? null : "(021) 99999999"}
              className={props.user.tell2 != null ? "has-value" : "no-value"}

              ></InputMask>
 
            </div> }

            {props.isPage && <div className="field">
              <div>
                <label htmlFor="address">آدرس</label>
              </div>
              
              <InputText
              id="address"
              name="address"
              value={socialForm.values.address}
              onChange={socialForm.handleChange}
              placeholder={props.user.address != null && props.user.address != ""? null : "تهران، خیابان آزادی، ..."}
              className={props.user.address != null ? "has-value" : "no-value"}

              ></InputText>
 
            </div> }

            <div className="field">
              <div>
                <label htmlFor="email">ایمیل</label>
              </div>
              <InputText
                id="email"
                name="email"
                value={socialForm.values.email}
                onChange={socialForm.handleChange}
                placeholder={props.user.email != null && props.user.email != "" ? null : "john.black@gmail.com"}
                style={{ direction: "ltr", textAlign: "left" }}
                className={props.user.email != null ? "has-value" : "no-value"}
              />
            </div>

            <div className="field">
              <div>
                <label htmlFor="site">وبسایت</label>
              </div>
              <InputText
                id="website"
                name="website"
                value={socialForm.values.website}
                onChange={socialForm.handleChange}
                placeholder={props.user.website != null && props.user.website != ""? null : "www.something.com"}
                style={{ direction: "ltr", textAlign: "left" }}
                className={props.user.website != null ? "has-value" : "no-value"}
              />
            </div>
            <div className="field">
              <div>
                <label htmlFor="instagram">اینستاگرام</label>
              </div>
              <InputText
                id="instagram"
                name="instagram"
                value={socialForm.values.instagram}
                onChange={socialForm.handleChange}
                placeholder={props.user.instagram != null && props.user.instagram != ""? null : "john.black"}
                style={{ direction: "ltr", textAlign: "left" }}
                className={props.user.instagram != null ? "has-value" : "no-value"}
              />
            </div>
            <div className="field">
              <div>
                <label htmlFor="telegram">تلگرام</label>
              </div>
              <InputText
                id="telegram"
                name="telegram"
                value={socialForm.values.telegram}
                onChange={socialForm.handleChange}
                placeholder={props.user.telegram != null && props.user.telegram != ""? null : "john.black"}
                style={{ direction: "ltr", textAlign: "left" }}
                className={props.user.telegram != null ? "has-value" : "no-value"}
              />
            </div>
            <div className="field">
              <div>
                <label htmlFor="whatsapp">واتساپ</label>
              </div>
              <InputText
                id="whatsapp"
                name="whatsapp"
                value={socialForm.values.whatsapp}
                onChange={socialForm.handleChange}
                placeholder={props.user.whatsapp != null && props.user.whatsapp != ""? null : "989129999999"}
                style={{ direction: "ltr", textAlign: "left" }}
                className={props.user.whatsapp != null ? "has-value" : "no-value"}
              />
            </div>

            <div className="field">
              <div>
                <label htmlFor="twitter">توییتر</label>
              </div>
              <InputText
                id="twitter"
                name="twitter"
                value={socialForm.values.twitter}
                onChange={socialForm.handleChange}
                placeholder={props.user.twitter != null && props.user.twitter != "" ? null : "john.black"}
                style={{ direction: "ltr", textAlign: "left" }}
                className={props.user.twitter != null ? "has-value" : "no-value"}
              />
            </div>

            <div className="field">
              <div>
                <label htmlFor="facebook">فیس بوک</label>
              </div>
              <InputText
                id="facebook"
                name="facebook"
                value={socialForm.values.facebook}
                onChange={socialForm.handleChange}
                placeholder={props.user.facebook != null && props.user.facebook != ""? null : "john.black"}
                style={{ direction: "ltr", textAlign: "left" }}
                className={props.user.facebook != null ? "has-value" : "no-value"}
              />
            </div>

            <div className="field">
              <div>
                <label htmlFor="linkedin">لینکدین</label>
              </div>
              <InputText
                id="linkedin"
                name="linkedin"
                value={socialForm.values.linkedin}
                onChange={socialForm.handleChange}
                placeholder={props.user.linkedin != null && props.user.linkedin != ""? null : "john.black"}
                style={{ direction: "ltr", textAlign: "left" }}
                className={props.user.linkedin != null ? "has-value" : "no-value"}
              />
            </div>

            <div className="field">
              <div>
                <label htmlFor="youtube">یوتیوب</label>
              </div>
              <InputText
                id="youtube"
                name="youtube"
                value={socialForm.values.youtube}
                onChange={socialForm.handleChange}
                placeholder={props.user.youtube != null && props.user.youtube != ""? null : "john.black"}
                style={{ direction: "ltr", textAlign: "left" }}
                className={props.user.youtube != null ? "has-value" : "no-value"}
              />
            </div>

            <div className="two-columns">
              <div className="field col-one">
                <span>
                  <Button type="submit" label="ذخیره" className="mt-2" />
                </span>
              </div>

              <div className="field col-two">
                <span>
                  <Button
                    className="outline-button mt-2"
                    type="submit"
                    label="انصراف"
                    onClick={() => props.close()}
                  />
                </span>
              </div>
            </div>
          </form>
        </div>
    );
}

export default SocialForm;