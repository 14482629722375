import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link, useHref, useNavigate, useParams } from "react-router-dom";
import "../Css/Weblog.css";
import ProductsSidebar from "./ProductsSidebar";
import { Toast } from "primereact/toast";
import { Helmet } from "react-helmet";
import Post from "./Post";
import Pagination from "../components/Pagination";
import { SplitButton } from "primereact/splitbutton";
import { FloatingWhatsApp } from "@carlos8a/react-whatsapp-floating-button";

function Products(props)
{

    const {tag_value} = useParams();
    const user = JSON.parse(localStorage.getItem('flickerNetwork') != null) ? JSON.parse(localStorage.getItem('flickerNetwork')) : {username:"" , name:"", avatarPic:"profile-index.png"};
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const navigate = useNavigate();
    const [taggedPosts,setTaggedPosts] = useState([]);
    const toast = useRef(null);
    const [postsRows,setPostRows] = useState([]);
    const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
    const { page } = useParams();
    const [currentPage, setCurrentPage] = useState(parseInt(page) || 1);
    const [totalPages, setTotalPages] = useState(1);
    const [tagInfo,setTagInfo] = useState();
    const [theme,setTheme] = useState(props.theme);
    const [themeSwitch,setThemeSwitch] = useState(props.theme==="light" ? true : false);

   

    //fetch tag's information:
    useEffect(() => {

      async function fetchTagInformation() {
    
    
        
        await axios.get(process.env.REACT_APP_BACK+"/posts/tagsinfo/"+tag_value, config)
              .then((result) => {
                if (result.status == 200) {

                    
                    setTagInfo(result.data);
        
                  
                  
                  return result.data;
                }
              })
              .catch((error) => {
                console.log(error);
                if(error.response.data.statusCode===401)
                  navigate("/login")
              })
    
    
      }
  
            
        
        
      fetchTagInformation();
        
     
      
    
    },[]);
    
    
    //fetch tagged posts:
    useEffect(() => {

    async function fetchPostsOfTags() {
  
  
      //fetch first group:
      await axios.get(process.env.REACT_APP_BACK+"/posts/contain-tag/"+tag_value+"/"+user._id+"/"+(currentPage*10), config)
            .then((result) => {
              if (result.status == 200) {

                const { posts, totalCount } = result.data;

                
                setTaggedPosts(posts);
                setTotalPages(Math.ceil(totalCount/10));

                const groupedPosts = [];

                // Group posts into pairs
                for (let i = 0; i < posts.length; i += 2) {
                  groupedPosts.push(posts.slice(i, i + 2)); // Slice posts in groups of 2
                }
                setPostRows(groupedPosts);
                      
      
                
                
                return result.data;
              }
            })
            .catch((error) => {
              console.log(error);
              if(error.response.data.statusCode===401)
                navigate("/login")
            })
  
  
    }

          
      
      
        fetchPostsOfTags();
      
   
    
  
  },[]);



    return(

      
        <span id="weblog" className="product_page_container" >

        {/* Floating order button: */}
        <FloatingWhatsApp
                        phoneNumber='989352333881' // Required
                        accountName='فلیکا' // Optional
                        avatar={`${process.env.REACT_APP_BACK}/avatars/flicka_logo.png`}
                        initialMessageByServer='سلام سوارکار عزیز، اگر قصد سفارش محصولی را دارید، به واتساپ ما پیام دهید. به شما مشاوره می دهیم.' // Optional
                        initialMessageByClient='سلام' // Optional
                        statusMessage='آنلاین' // Optional
                        startChatText='پیام در واتساپ' // Optional
                        tooltipText='سلام سوارکار عزیز، سفارشی دارید؟' // Optional
                        allowEsc={true} // Optional
                        darkMode={ theme!=="light" ? true : false}
                        allowClickAway={true}
                        
                      />

        {/* meta tags: ********************************************************************************************************/}
                
                <Helmet>
                
                <title>{tagInfo?.seoTitle}</title>
                <meta name="description" content={tagInfo?.metaDescription}/>
                <meta name="robots" content="index, follow"/>
                <meta name="author" content={"flicka"}/>
                <link rel="canonical" href={`${process.env.REACT_APP_SITE_URL}/tags/${tagInfo?.value}`}/>
                <meta property="og:title" content={tagInfo?.seoTitle}/>
                <meta property="og:description" content={tagInfo?.metaDescription}/>
                <meta property="og:image" content={`${process.env.REACT_APP_BACK}/posts/${taggedPosts[0]?.file}`}/>
                <meta property="og:url" content={`${process.env.REACT_APP_SITE_URL}/tags/${tagInfo?.value}`}/>
                <meta property="og:type" content="product"/>
                <meta property="article:published_time" content={`${taggedPosts[0]?.date}`}/>
                <meta property="article:author" content={"flicka"}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <script type="application/ld+json">
                  {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "Article",
                    "headline": tagInfo?.value.replaceAll("_", " "),
                    "description": tagInfo?.metaDescription,
                    "author": {
                      "@type": "Person",
                      "name": "flicka"
                    },
                    "publisher": {
                      "@type": "Organization",
                      "name": "Example Publisher",
                      "logo": {
                        "@type": "ImageObject",
                        "url": "https://www.example.com/logo.jpg"
                      }
                    },
                    "datePublished": `${taggedPosts[0]?.date}`
                  })}
                </script>
                </Helmet>

{/**********************************************************************************************************************/}
     
        <Toast ref={toast}></Toast>

            
            <span id="weblogMainPanel">

            <div id="weblog-container">


{/* navigation: *************************/}
            <span id="weblog-nav" style={{width:"100%",flexDirection:'row',display:"flex" , direction:'rtl'}}>
              <Link to="/"><p>خانه</p></Link>
              <p style={{marginRight:'10px',marginLeft:'10px'}}> >> </p>
              <Link to="/tags"><p>برچسب ها</p></Link>
              
           
              <span style={{display:'flex'}}>
              <p style={{marginRight:'10px',marginLeft:'10px'}}> >> </p>
              <p style={{marginLeft:'10px',color:'#af734f'}}> {tag_value} </p>
              </span>

            </span>
{/* ************************************ */}
          

          <h2 className="mainTitle" style={{marginBottom:"50px"}}>{tag_value.replaceAll("_", " ")}</h2>
                     
          
            <div style={{ width: '100%' }}>


                  {postsRows.map((row, rowIndex) => (
                    <div key={rowIndex} style={{ display: desktop ? 'flex' : 'block', width: '100%' , gap: '30px'}}
                    className="multiple_article">
                      {row.map((post, index) => (
                        <div key={index} style={{ width: desktop ? '50%' : "100%", display: 'flex'}}>
                          <Post
                            creatorPage={post.creatorPage}
                            creatorUsername={post.creator!==undefined ? post.creator.username : post.creatorPage.username}
                            creator_id={post.creator!==undefined ? post.creator._id : post.creatorPage._id}
                            creatorName={post.creator!==undefined ? post.creator.name : post.creatorPage.name}
                            creatorAvatar={post.creator!==undefined ? post.creator.avatarPic : post.creatorPage.avatarPic}
                            creatorJob={post.creator!==undefined ? post.creator.job : post.creatorPage.job}
                            file={post.file}
                            fileType={post.fileType}
                            category={post.category}
                            tags={post.tags}
                            taggedUsers={post.taggedUsers}
                            date={post.date}
                            description={post.description}
                            likesCount={post.likesCount}
                            commentsCount={post.commentsCount}
                            postOfOther={post.creator===undefined ? (post.creatorPage.creator_id !== user._id && post.groupUser!==user._id) : post.creator._id !== user._id}
                            _id={post._id}
                            // updatePost={closeNewPost}
                            //online user data used for like & save & comment:
                            userAvatar={user.avatarPic}
                            user_id={user._id}
                            user_name={user.name}
                            user_username={user.username}
                            theme={props.theme}
                            blueTick={post.creator!==undefined ? post.creator.blueTick : post.creatorPage.blueTick}
                          />
                        </div>
                      ))}
                    </div>
                  ))}

            

                
                </div>



              
{/* pagination: ***********************************************************/}
              { (totalPages > 1) &&
              (<div id="paging-section" style={{marginBottom:'50px'}}>
              
              <Pagination currentPage={currentPage} totalPages={totalPages} type={"tag"} tagValue={tag_value}/>
              
              </div>)}

{/* /************************************************************************/}


            { !desktop && <ProductsSidebar/>}
              

            { (currentPage===1) && (tagInfo?.content) && (
              <div id="content-section" className="product_page">

              {user.role === "manager" && 
                <div style={{float:'left', cursor:'pointer'}}><i className="pi pi-ellipsis-v" onClick={() => navigate("/edit-tag/"+tag_value)}></i></div>}
                <div id="article">

            
             <div id="post-content">
                <h1 id="product-header">{tagInfo.seoTitle}</h1>
                
                <div
                  
                       dangerouslySetInnerHTML={{
                            __html:tagInfo?.content
                        }}
                    />   
             </div>
                </div>
                </div>
                
                )}

                
            
            </div>

            </span>

            
   
                { desktop && <ProductsSidebar/>}



             

                    

                  

        </span>
    );
}

export default Products;