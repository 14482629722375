import "../Css/NewPost.css";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import "../Css/VideoImageUploader.css"
import Chip  from "./Chip";
import { useNavigate } from "react-router-dom";
import { Editor } from 'primereact/editor';




function NewPost(props) {


  const [uploadFile, setUploadFile] = useState("");
  const [selectedTags,setSelectedTags] = useState([]);
  const [selectedUsers,setSelectedUsers] = useState([]);
  const [fileType,setFileType] = useState();
  const inputRef = React.useRef();
  const [source, setSource] = useState();
  const [showTags,setShowTags] = useState(false);
  const [showTagUsers,setShowTagUsers] = useState(false);
  const dialogRef = useRef(null);
  const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
  const navigate = useNavigate();
  const editorRef = useRef(null);

    const colors = [
        'rgb(0, 0, 0)', 'rgb(230, 0, 0)', 'rgb(255, 153, 0)', 'rgb(255, 255, 0)', 'rgb(0, 138, 0)', 
        'rgb(0, 102, 204)', 'rgb(153, 51, 255)', 'rgb(255, 255, 255)', 'rgb(250, 204, 204)', 
        'rgb(255, 235, 204)', 'rgb(255, 255, 204)', 'rgb(204, 232, 204)', 'rgb(204, 224, 245)', 
        'rgb(235, 214, 255)', 'rgb(187, 187, 187)', 'rgb(240, 102, 102)', 'rgb(255, 194, 102)', 
        'rgb(255, 255, 102)', 'rgb(102, 185, 102)', 'rgb(102, 163, 224)', 'rgb(194, 133, 255)', 
        'rgb(136, 136, 136)', 'rgb(161, 0, 0)', 'rgb(178, 107, 0)', 'rgb(178, 178, 0)', 'rgb(0, 97, 0)', 
        'rgb(0, 71, 178)', 'rgb(107, 36, 178)', 'rgb(68, 68, 68)', 'rgb(92, 0, 0)', 'rgb(102, 61, 0)', 
        'rgb(102, 102, 0)', 'rgb(0, 55, 0)', 'rgb(0, 41, 102)', 'rgb(61, 20, 102)'
    ];

    const createColorOptions = (colors) => colors.map(color => (
        <option key={color} value={color} style={{ backgroundColor: color }}></option>
    ));

    const toolbarOptions = (
        <div id="toolbar">
            <select className="ql-header" defaultValue="">
                <option value="1">h1</option>
                <option value="2">h2</option>
                <option value="3">h3</option>
                <option value="">p</option>
            </select>
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <button className="ql-strike"></button>
            <button className="ql-list" value="ordered"></button>
            <button className="ql-list" value="bullet"></button>
            <button className="ql-link"></button>
            <button className="ql-image"></button>
            <select className="ql-color">
                {createColorOptions(colors)}
            </select>
            <select className="ql-background">
                {createColorOptions(colors)}
            </select>
        </div>
    );
    
    const buttonStyle = {
      background: `linear-gradient(to right, #af734f ${props.progress}%, #e0e0e0 ${props.progress}%)`,
    };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploadFile(file);

    if(file.type.includes("image"))
        setFileType("image");
    else if(file.type.includes("video"))
        setFileType("video");
    
    const url = URL.createObjectURL(file);
    setSource(url);
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };
  


  const userCats = ["لوازم سوار", "لوازم اسب"];

  const formik = useFormik({
      initialValues: {
      description:"",
      category: "",
      file:"",
      tags:"",
      taggedUsers:""
      
    },
    validate: (values) => {
      let errors = {};


      if (!values.category && props.type == "product") {
        errors.category = "لطفا نوع محصول را انتخاب کنید";
      }

      


      return errors;
    },
    onSubmit: (values) => {


      values["tags"]=selectedTags.map(element => element.value.trim());
      values["taggedUsers"]=selectedUsers.map(element => element._id.trim());
      

      
    
    

      props.close(uploadFile,fileType,values,props.type);
      return;
      

      
    },
  });

  const isFormFieldValid = (phone) =>
  !!(formik.touched[phone] && formik.errors[phone]);
const getFormErrorMessage = (phone) => {
  return (
    isFormFieldValid(phone) && (
      <small className="p-error">{formik.errors[phone]}</small>
    )
  );
};









  return (
    <div ref={dialogRef}>

      
      <form onSubmit={formik.handleSubmit} className="new-post-form">


{/* Upload *************************************************************************************************** */}
        
        <div id="new-post-header">
        <span>
          <span  style={{display:"flex",width:"40%"}}>
        <i className="pi pi-times-circle"
          onClick={()=>navigate("/feed")}
        ></i>
        {props.type ==="product" ? <h4>محصول جدید</h4> :<h4>پست جدید</h4>}
        </span>
        
        {props.type == "product" ?
          <span id="new-post-category" style={{width:"60%",float:"left",direction:"ltr"}}>

            
            <div >
            <Dropdown
              id="category"
              name="category"
              className={props.theme+" "+classNames({
                "p-invalid": isFormFieldValid("category"),
              })}
              value={formik.values.category}
              options={userCats}
              onChange={formik.handleChange}
              placeholder="نوع محصول را انتخاب کنید"
            />
            {getFormErrorMessage("category")}
            </div>
          
            
          </span>
            : null}
        </span>


        
        </div>

        {getFormErrorMessage("file")}

        <div className="media-upload">
         
      <div className="VideoInput">
      <input
        id="file"
        name="file"
        value={formik.values.file}
        hidden={true}
        ref={inputRef}
        className="VideoInput_input"
        type="file"
        onChange={handleFileChange}
        accept=".mov,.mp4, .jpg, .png, .jpeg , .webp"
        
      />
      
  
      
      
      <span id="upload-buttons">
        <span style={{display:"flex",width:"60%",alignItems: "center"}}>
        <i className="pi pi-image"></i>
      <p onClick={handleChoose}>آپلود عکس یا فیلم</p>
      </span>
      <span style={{display:"flex",width:"40%",direction:"ltr",alignItems: "center"}}>
      <p id="delete-button" onClick={()=> setSource(null)}><i className="pi pi-times"/>حذف</p>
      </span>
      </span>

      
      
      
          
      
      
      {source && fileType=="image" && (
        <img
          className="VideoInput_video"
          width="100%"
          height="100%"
          src={source}
        />
      )}

      
    {source && fileType=="video" && (
        <video
          className="VideoInput_video"
          width="100%"
          height="100%"
          controls
          src={source}
        />
      )}
      
    </div>


        </div>

{/*********************************************************************************************************/}

        <span id="content">
          

         <InputTextarea
          id="description"
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
            rows={1}
            cols={3}
            autoResize
            placeholder="متن خود را اینجا بنویسید..."
          />



        <div className="tag-user" style={{marginTop:"20px",direction:"rtl",textAlign:"right",display:"flex",alignItems:"center"}}>

          <span style={{display:"flex",width:"15%"}}onClick={()=>{  setShowTagUsers(false);  setShowTags(!showTags); }}><i className="pi pi-tag"></i></span>
          <span style={{display:"flex",width:"15%"}} onClick={()=>{ setShowTags(false); setShowTagUsers(!showTagUsers); }} ><i className="pi pi-user"></i></span>
          <span style={{display:"flex",direction:"ltr",width:"60%"}}>
          <span>
            <Button id={ ((props.progress===0 || !props.progress) && !desktop) || desktop ? "is-not-uploading" : "is-uploading"} 
            disabled={(props.progress!==0 && props.progress) && !desktop ? true : false} 
            type="submit" 
            style={buttonStyle}>انتشار</Button>
          </span>
          
        </span>
          
          </div>

        <div style={{marginBottom:"100px"}}>


          {/* tags */}
          <div id="tags-area" style={{height:!showTags?"0px":null}}>
      
          <h6 style={{height:"20%",textAlign:"right",marginBottom:"0px",marginTop:"20px"}}>:برچسب ها</h6>
       

        
        <Chip
          id="tags"
          inputId="tags"
          name="tags"
          type="tags"
          setSelections={setSelectedTags}
        >

        </Chip>




          </div>


          {/* tag users */}
          <div id="tag-users-area" style={{height:!showTagUsers?"0px":null}}>

          <h6 style={{height:"20%",textAlign:"right",marginBottom:"0px",marginTop:"20px"}}>:کاربر یا صفحه ای را تگ کنید</h6>

         
        <Chip
        id="taggedUsers"
        inputId="taggedUsers"
        name="taggedUsers"
        type="taggedUsers"
        setSelections={setSelectedUsers}
        >
        </Chip>
     
      
          
          </div>


          

          </div>

       

        
 
        </span>

        
      </form>
    </div>
  );
}

export default NewPost;
