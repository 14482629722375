import "../Css/UserSettings.css";
import { TabView, TabPanel } from "primereact/tabview";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { createRef, useEffect, useState } from "react";
import { Divider } from "primereact/divider";
import { InputSwitch } from "primereact/inputswitch";
import { Dialog } from "primereact/dialog";
import NewGruop from "./NewGroup";
import NewPage from "./NewPage";
import PageForm from "./PageForm";
import axios from "axios";
import { useFormik } from "formik";
import { Email } from "@material-ui/icons";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils"
import { InputMask } from "primereact/inputmask";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { SelectButton } from 'primereact/selectbutton';
import { ConfirmDialog } from 'primereact/confirmdialog';



function UserSettings(props) {

  const user = JSON.parse(localStorage.getItem('flickerNetwork') != null) ? JSON.parse(localStorage.getItem('flickerNetwork')) : {username:"" , name:"", avatarPic:"profile-index.png"};
  const [showEmail, setShowEmail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [showUsername, setShowUsername] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showAccountType,setShowAccountType] = useState(false);
  const [createGroupDialog, setCreateGroupDialog] = useState(false);
  const [CreatePageDialog, setCreatePageDialog] = useState(false);
  const [detailedPageDialog,setDetailedPageDialog] = useState(false);
  const [profileUpdated, setProfileUpdated] = useState(0);
  const [fetchedUser, setFetchedUser] = useState(user);
  const [passwordsDoesnotMatch,setPasswordsDoesnotMatch] = useState(false);
  const [changePassResponse,setChangePassResponse] = useState("");
  const [updateEmailSettings,setupdateEmailSettings] = useState(true);
  const [updateAppSettings,setupdateAppSettings] = useState(true);
  const toast = createRef();
  const token = JSON.parse(localStorage.getItem('userToken'));
  const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
  const [updateTime,setUpdateTime] = useState(0);
  const [userPages, setUserPages] = useState([]);
  const [updatePages, setUpdatePages] = useState(true);
  const [newPageType,setNewPageType] = useState();
  const [showConfirmDelete,setShowConfirmDelete] = useState(false);
  const [accountType,setAccountType]=useState();

  //email settings
  const [profileSetForEmail,setProfileSetForEmail] = useState();
  const [followedSetForEmail,setFollowedSetForEmail] = useState();
  const [postLikedSetForEmail,setPostLikedSetForEmail] = useState();
  const [postCommentedSetForEmail,setPostCommentedSetForEmail] = useState();
  const [commentsLikedSetForEmail,setCommentLikedSetForEmail] = useState();
  const [mentionedSetForEmail,setMentionedSetForEmail] = useState();
  const [postRepublishedSetForEmail,setPostRepublishedSetForEmail] = useState();

  //app settings
  const [profileSetForApp,setProfileSetForApp] = useState();
  const [followedSetForApp,setFollowedSetForApp] = useState();
  const [postLikedSetForApp,setPostLikedSetForApp] = useState();
  const [postCommentedSetForApp,setPostCommentedSetForApp] = useState();
  const [commentsLikedSetForApp,setCommentLikedSetForApp] = useState();
  const [mentionedSetForApp,setMentionedSetForApp] = useState();
  const [postRepublishedSetForApp,setPostRepublishedSetForApp] = useState();
  const [messageSetForApp,setMessageSetForApp] = useState();


  const deleteAccount = async (_id) => 
    {



      //delete from followings and followers:
      await axios.delete(process.env.REACT_APP_BACK+"/notifications/delete-from-follower-and-following/" + _id + "/user", config)
      .catch((error) =>
      {
        if(error.response.data.statusCode===401)
              navigate("/login")
      });
      
      //delete user and her posts and settings:
      await axios.delete(process.env.REACT_APP_BACK+"/users/" + _id, config)
      .then(response => 
        {
          if (response.status == 200)
          {
            console.log(response);          
            logout();
          }

      })
      .catch(error => {
         console.log(error);
         if(error.response.data.statusCode===401)
              navigate("/login")
      });
    }

  function logout()
  {
    console.log("atemped to log out");
 
    axios.get(process.env.REACT_APP_BACK+"/users/logout", config)
    .then((response) => {
      if (response.status == 200)
        navigate("/");
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
      if(error.response.data.statusCode===401)
              navigate("/login")
    });

    //delete user cache:
    localStorage.removeItem('flickerNetwork');

  
  }
  

  const closePageForm = () => {
    setDetailedPageDialog(false);
    toast.current.show({
      severity: "success",

      detail: "صفحه با موفقیت ساخته شد",
    });
    setUpdatePages(true);
  };

  const closeGroupForm = () => {
    setCreateGroupDialog(false);
    setUpdatePages(true);
  };

  //fetching user pages:
  useEffect(() => {
    async function fetchUserPages() {
      await axios
        .get(process.env.REACT_APP_BACK+"/pages/creator/" + user._id, config)
        .then((result) => {
          if (result.status == 200) {
            setUserPages(result.data);
            console.log("fetched user pages: ", result.data);
            return result.data;
          }
        })
        .catch((error) => {
          console.log(error);
          if(error.response.data.statusCode===401)
              navigate("/login")
        });
    }

    if (props.settingsTab == 1 && updatePages) {
      fetchUserPages();
      setUpdatePages(false);
    }
  }, [props, updatePages]);


  const updateEmailNow = async (name) => {
     if(name=="profile")
      {
        await setProfileSetForEmail(!profileSetForEmail);
        setUpdateTime(updateTime+1);
      }
      else if(name=="followed")
      {
        await setFollowedSetForEmail(!followedSetForEmail);
        setUpdateTime(updateTime+1);
      }
      else if(name=="postLiked")
      {
        await setPostLikedSetForEmail(!postLikedSetForEmail);
        setUpdateTime(updateTime+1);
      }
      else if(name=="postCommented")
      {
        await setPostCommentedSetForEmail(!postCommentedSetForEmail);
        setUpdateTime(updateTime+1);
      }
      else if(name=="commentLiked")
      {
        await setCommentLikedSetForEmail(!commentsLikedSetForEmail);
        setUpdateTime(updateTime+1);
      }
      else if(name=="mentioned")
      {
        await setMentionedSetForEmail(!mentionedSetForEmail);
        setUpdateTime(updateTime+1);
      }
      else if(name=="postRepublished")
      {
        await setPostRepublishedSetForEmail(!postRepublishedSetForEmail);
        setUpdateTime(updateTime+1);
      }
      
  }

  const updateAppNow = async (name) => {
    if(name=="profile")
     {
       await setProfileSetForApp(!profileSetForApp);
       setUpdateTime(updateTime+1);
     }
     else if(name=="followed")
     {
       await setFollowedSetForApp(!followedSetForApp);
       setUpdateTime(updateTime+1);
     }
     else if(name=="postLiked")
     {
       await setPostLikedSetForApp(!postLikedSetForApp);
       setUpdateTime(updateTime+1);
     }
     else if(name=="postCommented")
     {
       await setPostCommentedSetForApp(!postCommentedSetForApp);
       setUpdateTime(updateTime+1);
     }
     else if(name=="commentLiked")
     {
       await setCommentLikedSetForApp(!commentsLikedSetForApp);
       setUpdateTime(updateTime+1);
     }
     else if(name=="mentioned")
     {
       await setMentionedSetForApp(!mentionedSetForApp);
       setUpdateTime(updateTime+1);
     }
     else if(name=="postRepublished")
     {
       await setPostRepublishedSetForApp(!postRepublishedSetForApp);
       setUpdateTime(updateTime+1);
     }
     else if(name=="message")
     {
      await setMessageSetForApp(!messageSetForApp);
      setUpdateTime(updateTime+1);

     }
     
 }

  // useEffect(() => {
  //   async function fetchEmailSettings() {




  //     const result = await axios.get(process.env.REACT_APP_BACK+"/settings/emailSettings/" + fetchedUser["_id"], config);


  //     console.log("fetched settings: ",result.data);
  //     console.log(result);

  //     setProfileSetForEmail(result.data.profile);
  //     setFollowedSetForEmail(result.data.followed);
  //     setPostLikedSetForEmail(result.data.postLiked);
  //     setPostCommentedSetForEmail(result.data.postCommented);
  //     setCommentLikedSetForEmail(result.data.commentLiked);
  //     setMentionedSetForEmail(result.data.mentioned);
  //     setPostRepublishedSetForEmail(result.data.postRepublished);

  //     return result.data;

  //   }
   
  //   if(updateEmailSettings&&props.fetchSettings)
  //   {
  //     setupdateEmailSettings(false);
  //     fetchEmailSettings();
  //   }
  // });

  useEffect(() => {
    async function fetchAppSettings() {

      const result = await axios.get(process.env.REACT_APP_BACK+"/settings/appSettings/" + fetchedUser["_id"], config);

      

      console.log("fetched settings: ",result.data);
      console.log(result);

      setProfileSetForApp(result.data.profile);
      setFollowedSetForApp(result.data.followed);
      setPostLikedSetForApp(result.data.postLiked);
      setPostCommentedSetForApp(result.data.postCommented);
      setCommentLikedSetForApp(result.data.commentLiked);
      setMentionedSetForApp(result.data.mentioned);
      setPostRepublishedSetForApp(result.data.postRepublished);
      setMessageSetForApp(result.data.message);

      return result.data;

    }
   
    if(updateAppSettings&&props.fetchSettings)
    {
      setupdateAppSettings(false);
      fetchAppSettings();
    }
  });

  useEffect(() => {

    async function updateEmailSettings() {
    
      if (updateTime==0) 
      return;
    
   
      const obj={};
      obj["profile"] = profileSetForEmail;
      obj["followed"] = followedSetForEmail;
      obj["postLiked"] = postLikedSetForEmail;
      obj["postCommented"] = postCommentedSetForEmail;
      obj["commentLiked"] = commentsLikedSetForEmail;
      obj["mentioned"] = mentionedSetForEmail;
      obj["postRepublished"] = postRepublishedSetForEmail;
      const data=obj;

    

    axios.patch(process.env.REACT_APP_BACK+"/settings/updateEmailSettings/"+fetchedUser["_id"], data, config)
            .then(response => {

              if(response.status == 200)
              {
              
              }
              
            })
            .catch(error => {
               console.log(error);
               if(error.response.data.statusCode===401)
              navigate("/login")
            });
  }

  updateEmailSettings();

},[updateTime]);


useEffect(() => {

  async function updateAppSettings() {
  
    if (updateTime==0) 
    return;
  
 
    const obj={};
    obj["profile"] = profileSetForApp;
    obj["followed"] = followedSetForApp;
    obj["postLiked"] = postLikedSetForApp;
    obj["postCommented"] = postCommentedSetForApp;
    obj["commentLiked"] = commentsLikedSetForApp;
    obj["mentioned"] = mentionedSetForApp;
    obj["postRepublished"] = postRepublishedSetForApp;
    obj["message"] = messageSetForApp;
    const data=obj;

  

  axios.patch(process.env.REACT_APP_BACK+"/settings/updateAppSettings/"+fetchedUser["_id"], data, config)
          .then(response => {

            if(response.status == 200)
            {
            
            }
            
          })
          .catch(error => {
             console.log(error);
             if(error.response.data.statusCode===401)
              navigate("/login")
          });
}

updateAppSettings();

},[updateTime]);



  const openDetailedPage = (type) => {
    setNewPageType(type);
    setCreatePageDialog(false);
    setDetailedPageDialog(true)
  };


  const navigate = useNavigate();
  const tabHandler = (e) => {
    if (e === 0) navigate("/settings/account",{state:{user:user}});
    if (e === 1) navigate("/settings/manage-pages",{state:{user:user}});
    if (e === 2) {
      setupdateEmailSettings(true);
      setupdateAppSettings(true);
      navigate("/settings/notifications",{state:{user:user}})};
  };



  useEffect(() => {
    async function fetchUser() {

      const result = await axios.get(process.env.REACT_APP_BACK+"/users/profile/" + user["username"], config);
      setFetchedUser(result.data);
      setAccountType(result.data.accountType);
    

      console.log("fetched user: ");
      console.log(result);
      return result.data;

    }
    setFetchedUser(fetchUser());
  }, [profileUpdated]);

  const emailForm = useFormik({
    initialValues: {
      email: "",
    },

    validate: (values) => {
      let errors = {};

      if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "فرمت ایمیل نادرست است";
      }


      return errors;
    },
    onSubmit: (values) => {


      const obj = {};

      for (const key in values) {

        if(values[key] != "")
          obj[key] = values[key];
        
    }


      const data = JSON.stringify(obj);
   
    axios.patch(process.env.REACT_APP_BACK+"/users/update-email/"+user["username"], data, config)
        .then(response => {

          if(response.status == 200)
          console.log(response)
          setProfileUpdated(profileUpdated+1)
        })
        .catch(error => {
           console.log(error);
           if(error.response.data.statusCode===401)
              navigate("/login")
        });

        setShowEmail(false)
      
    },
  });
  const isFormFieldValid = (name) =>
  !!(emailForm.touched[name] && emailForm.errors[name]);
const getFormErrorMessage = (name) => {
  return (
    isFormFieldValid(name) && (
      <small  style={{float:"right"}} className="p-error">{emailForm.errors[name]}</small>
    )
  );
};


const submitAccountType = (e) => {

  console.log("e",e.value);
  setAccountType(e.value);

    const data = JSON.stringify({accountType:e.value});
 
  axios.patch(process.env.REACT_APP_BACK+"/users/update-accountType/"+fetchedUser["username"], data, config)
      .then(response => {

        if(response.status == 200)
        console.log(response)
        setProfileUpdated(profileUpdated+1);
        
        //update accountype in local storage:
          const user = JSON.parse(localStorage.getItem('flickerNetwork'));
          user.accountType = e.value;
          localStorage.setItem("flickerNetwork", JSON.stringify(user));
          
        
      })
      .catch(error => {
         console.log(error);
         if(error.response.data.statusCode===401)
              navigate("/login")
      });

  
    
  };


const phoneForm = useFormik({
  initialValues: {
    phone: "",
  },

  onSubmit: (values) => {


    const obj = {};

    for (const key in values) {

      if(values[key] != "")
        obj[key] = values.phone.replace(/[^0-9]+/g, "")

      
  }


    const data = JSON.stringify(obj);
 
  axios.patch(process.env.REACT_APP_BACK+"/users/update-phone/"+fetchedUser["username"], data, config)
      .then(response => {

        if(response.status == 200)
        console.log(response)
        setProfileUpdated(profileUpdated+1)
      })
      .catch(error => {
         console.log(error);
         if(error.response.data.statusCode===401)
              navigate("/login")
      });

      setShowPhone(false)
    
  },
});

const usernameForm = useFormik({
  initialValues: {
    username: "",
  },

  onSubmit: (values) => {


    const obj = {};

    for (const key in values) {

      if(values[key] != "")
        obj[key] = values[key];
      
  }


    const data = JSON.stringify(obj);
 
  axios.patch(process.env.REACT_APP_BACK+"/users/update-username/"+fetchedUser["username"], data, config)
      .then(response => {

        if(response.status == 200)
        console.log(response)
        setProfileUpdated(profileUpdated+1)
      })
      .catch(error => {
         console.log(error);
         if(error.response.data.statusCode===401)
              navigate("/login")
      });

      setShowUsername(false)
    
  },
});

const passForm = useFormik({
  initialValues: {
    password: "",
    passConfirm:""
  },

  onSubmit: (values) => {

    if(values["password"] != values["passConfirm"])
    {
      setPasswordsDoesnotMatch(true);
    }
    else
    {
      setPasswordsDoesnotMatch(false);

    const obj = {};

    for (const key in values) {

      if(values[key] != "")
      {
        if(key == "password" || key == "oldPass")
          obj[key] = values[key];

      }
      
  }


    const data = JSON.stringify(obj);
    setChangePassResponse("");

  axios.patch(process.env.REACT_APP_BACK+"/users/update-pass/"+fetchedUser["username"], data, config)
      .then(response => {

        

        console.log("response:");
        console.log(response);

        if(response.status == 200)
        {
          if(response.data.status == 403)
          {
            setChangePassResponse(response.data.message);
          }
          else{
            toast.current.show({
              severity: "success",
              
              detail: "رمز با موفقیت تغییر یافت",
            });
        setProfileUpdated(profileUpdated+1);
        setShowPass(false)
          }
        }
        
      })
      .catch(error => {
        console.log(error);
        if(error.response.data.statusCode===401)
              navigate("/login")
      });

    
    }
  },
});

  return (
    <div id="settings">
 


       <Toast ref={toast} life="3000" position="center center"/>
      <Dialog
        id="create-page-dialog"
        header="ایجاد صفحه جدید"
        visible={CreatePageDialog}
        style={{ width: "50vw" }}
        onHide={() => setCreatePageDialog(false)}
      >
        <NewPage close = {openDetailedPage}/>
      </Dialog>

      <Dialog
        className="detailed-page-dialog"
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        header="صفحه جدید"
        visible={detailedPageDialog}
        style={{ width: "40vw" }}
        onHide={() => setDetailedPageDialog(false)}
      >

      <PageForm type={newPageType} user_id={user._id} creatorName={user.name} close={closePageForm}></PageForm>
        
      </Dialog>

      <Dialog
        id="create-group-dialog"
        header="ایجاد گروه جدید"
        visible={createGroupDialog}
        style={{ width: "40vw" }}
        onHide={() => setCreateGroupDialog(false)}
      >
        <NewGruop type={"group"} user_id={user._id} creatorName={user.name} close={closeGroupForm} />
      </Dialog>

      <TabView
        id="account-tab"
        style={{ direction: "rtl" }}
        activeIndex={props.settingsTab}
        onTabChange={(e) => tabHandler(e.index)}
      >
        <TabPanel header="حساب کاربری">
          <h6>اطلاعات کاربری</h6>
          <div className="settings-box">
            <div>
              <span
                className="settings-row"
                onClick={() => setShowEmail(!showEmail)}
              >
                <span className="settings-col1">
                  <i className="pi pi-envelope"></i>
                </span>
                <span className="settings-col2">آدرس ایمیل</span>
                <span className="settings-col3">
                  {!showEmail ? fetchedUser["email"] : ""}
                </span>
                <span className="settings-col4">
                  <i className="pi pi-angle-down"></i>
                </span>
              </span>

              <span
                id="row1-detail"
                style={{
               
                  overflow: "hidden",   
                  transition: "all .5s ease-in-out",
                  padding: showEmail ? "20px 0px 10px 0px" : "0px",
                  direction: "rtl",
                  height: showEmail ? "120px" : "0px",
                  display: "flex",
                }}
              >
                <span style={{ display: "flex", width: "5%" }}></span>
                <span style={{ display: "flex", width: "90%" }}>
                  
                  <form  onSubmit={emailForm.handleSubmit} style={{ width: "100%" }}>
                    <div>
                      <InputText
                      id="email"
                      name="email"
                      value={emailForm.values.email}
                      onChange={emailForm.handleChange}
                        style={{ width: "100%", textAlign: "left" }}
                        placeholder= {fetchedUser["email"]}
                        className={classNames({
                          "p-invalid": isFormFieldValid("email"),
                        })}
                        
                      ></InputText>
                      {getFormErrorMessage("email")}
                    </div>
                    <div
                      style={{ textAlign: "left", padding: "20px 0px 0px 0px" }}
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowEmail(false)}
                      >
                        انصراف
                      </span>
                      <span>
                        <button
                          type="submit"
                          style={{ marginRight: "10px", cursor: "pointer" }}
                        >
                          تایید
                        </button>
                      </span>
                    </div>
                  </form>
                </span>
              </span>

              <Divider></Divider>

              <span
                className="settings-row"
                onClick={() => setShowPhone(!showPhone)}
              >
                <span className="settings-col1">
                  <i className="pi pi-phone"></i>
                </span>
                <span className="settings-col2">شماره تلفن همراه</span>
                <span className="settings-col3">
                  {!showPhone ? fetchedUser["phone"] : ""}
                </span>
                <span className="settings-col4">
                  <i className="pi pi-angle-down"></i>
                </span>
              </span>

              <span
                id="row2-detail"
                style={{
                  overflow: "hidden",   
                  transition: "all .5s ease-in-out",
                  padding: showPhone ? "20px 0px 10px 0px" : "0px",
                  direction: "rtl",
                  height: showPhone ? "80px" : "0px",
                  display: "flex",
                }}
              >
                <span style={{ display: "flex", width: "5%" }}></span>
                <span style={{ display: "flex", width: "90%" }}>
                  
                  <form onSubmit={phoneForm.handleSubmit} style={{ width: "100%" }}>
                    <div>
                      <InputMask
                      id="phone"
                      name="phone"
                      mask="(0999) 999-99-99"
                      readOnly="readonly"
                      value={phoneForm.values["phone"]}
                      onChange={phoneForm.handleChange}
                        style={{ width: "100%", direction: "ltr", textAlign: "left"}}
                        placeholder= {fetchedUser["phone"]}
                      ></InputMask>

                    </div>
                    {/* <div
                      style={{ textAlign: "left", padding: "20px 0px 0px 0px" }}
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowPhone(false)}
                      >
                        انصراف
                      </span>
                      <span>
                        <button
                          type="submit"
                          style={{ marginRight: "10px", cursor: "pointer" }}
                        >
                          تایید
                        </button>
                      </span>
                    </div> */}
                  </form>
                </span>
              </span>

              <Divider></Divider>

              <span
                className="settings-row"
                onClick={() => setShowUsername(!showUsername)}
              >
                <span className="settings-col1">
                  <i className="pi pi-at"></i>
                </span>
                <span className="settings-col2">نام کاربری</span>
                <span className="settings-col3">
                  {!showUsername ? fetchedUser["username"] : ""}
                </span>
                <span className="settings-col4">
                  <i className="pi pi-angle-down"></i>
                </span>
              </span>
              <span
                id="row3-detail"
                style={{
                  overflow: "hidden",   
                  transition: "all .5s ease-in-out",
                  padding: showUsername ? "20px 0px 10px 0px" : "0px",
                  direction: "rtl",
                  height: showUsername ? "70px" : "0px",
                  display: "flex",
                }}
              >
                <span style={{ display: "flex", width: "5%" }}></span>
                <span style={{ display: "flex", width: "90%" }}>
                  
                  <form onSubmit={usernameForm.handleSubmit} style={{ width: "100%" }}>
                    <div>
                      <InputText
                      id="username"
                      name="username"
                      value={usernameForm.values["username"]}
                      onChange={usernameForm.handleChange}
                        style={{ width: "100%", textAlign: "left" }}
                        placeholder= {fetchedUser["username"]}
                        readOnly="readonly"
                      ></InputText>
                    </div>
                    {/* <div
                      style={{ textAlign: "left", padding: "20px 0px 0px 0px" }}
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowUsername(false)}
                      >
                        انصراف
                      </span>
                      <span>
                        <button
                          type="submit"
                          style={{ marginRight: "10px", cursor: "pointer" }}
                        >
                          تایید
                        </button>
                      </span>
                    </div> */}
                  </form>
                </span>
              </span>
            </div>
          </div>

          <h6>رمز عبور</h6>
          <div className="settings-box">
            <div>
              <span
                className="settings-row"
                onClick={() => setShowPass(!showPass)}
              >
                <span className="settings-col1">
                  <i className="pi pi-lock"></i>
                </span>
                <span className="settings-col2">تغییر رمز عبور</span>
                <span className="settings-col3"></span>
                <span className="settings-col4">
                  <i className="pi pi-angle-down"></i>
                </span>
              </span>
            </div>

            <span
              id="row4-detail"
              style={{
                overflow: "hidden",   
                  transition: "all .5s ease-in-out",
                  padding: showPass ? "20px 0px 10px 0px" : "0px",
                  direction: "rtl",
                  height: showPass ? "250px" : "0px",
                  display: "flex",
              }}
            >
              <span style={{ display: "flex", width: "5%" }}></span>
              <span style={{ display: "flex", width: "90%" }}>
              
                <form onSubmit={passForm.handleSubmit} style={{ width: "100%" }}>
                
                  <div>
                    <Password
                    id="password"
                    name="password"
                    value={passForm.values["password"]}
                    onChange={passForm.handleChange}
                      style={{
                        width: "100%",
                        textAlign: "right",
                        marginBottom: "15px",
                      }}
                      placeholder="رمز عبور جدید"
                    ></Password>
                    
                  </div>
                  <div>
                    <Password
                    id="passConfirm"
                    name="passConfirm"
                    value={passForm.values["passConfirm"]}
                    onChange={passForm.handleChange}
                      style={{
                        width: "100%",
                        textAlign: "right",
                        marginBottom: "15px",
                      }}
                      placeholder="تکرار رمز عبور جدید"
                    ></Password>
                    { passwordsDoesnotMatch ? <small  style={{float:"right"}} className="p-error">رمزهای جدید وارد شده یکسان نیستند</small>: null}
                    { changePassResponse != "" ? <small  style={{float:"right"}} className="p-error">{changePassResponse}</small>: null}
                  </div>
                  <div
                    style={{ textAlign: "left", padding: "20px 0px 0px 0px" }}
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPass(false)}
                    >
                      انصراف
                    </span>
                    <span>
                      <button
                        type="submit"
                        style={{ marginRight: "10px", cursor: "pointer" }}
                      >
                        تایید
                      </button>
                    </span>
                  </div>
                </form>
              </span>
            </span>
          </div>

          <h6>حساب کاربری</h6>
          <div className="settings-box">
            <div>
            

              <span
                className="settings-row"
                onClick={() => setShowAccountType(!showAccountType)}
              >
                <span className="settings-col1">
                  <i className="pi pi-ban"></i>
                </span>
                <span className="settings-col2">نوع حساب</span>
                <span className="settings-col3">
                  {!showAccountType ? fetchedUser["accountType"] : ""}
                </span>
                <span className="settings-col4">
                  <i className="pi pi-angle-down"></i>
                </span>
              </span>
              <span
                id="row1-detail"
                style={{
               
                  overflow: "hidden",   
                  transition: "all .5s ease-in-out",
                  padding: showAccountType ? "20px 0px 10px 0px" : "0px",
                  direction: "rtl",
                  height: showAccountType ? "180px" : "0px",
                  display: "flex",
                }}
              >
                <span style={{ display: "flex", width: "5%" }}></span>
                <span style={{ display: "block", width: "90%" }}>
                  
                  
                    
                    <div style={{textAlign:"right",marginBottom:"10px"}}>در حساب عمومی، پست ها به همه نمایش داده می شود. در حساب خصوصی، پست ها فقط به دنبال کننده هایتان نمایش داده می شود.</div>
                    <div>
                    <SelectButton name="accountType" id="accountType" className="sigle-select" value={accountType} options={["عمومی","خصوصی"]} onChange={(e) => submitAccountType(e) } ></SelectButton>
                    </div>
                    
                  
                </span>
              </span>
              <Divider></Divider>
              <span
                className="settings-row"
                onClick={() => setShowDelete(!showDelete)}
              >
                <span className="settings-col1">
                  <i className="pi pi-times-circle"></i>
                </span>
                <span className="settings-col2">حذف حساب کاربری</span>
                <span className="settings-col3"></span>
                <span className="settings-col4">
                  <i className="pi pi-angle-down"></i>
                </span>
              </span>
            </div>
            <span
              id="row5-detail"
              style={{
                overflow: "hidden",   
                  transition: "all .5s ease-in-out",
                  padding: showDelete ? "20px 0px 10px 0px" : "0px",
                  direction: "rtl",
                  height: showDelete ? "220px" : "0px",
                  display: "flex",
              }}
            >
              <span style={{ display: "flex", width: "5%" }}></span>
              <span style={{ display: "flex", width: "90%" }}>
                <div style={{ width: "100%" }}>
                  <div>
                    <p style={{ width: "100%", textAlign: "right" }}>
                      با حذف کردن حساب کاربری خود تمامی اطلاعات و مطالب شما برای
                      همیشه از پروفایل حذف خواهد شد. پس از حذف حساب کاربری امکان
                      فعال کردن مجدد حساب قبلی وجود نخواهد داشت.
                    </p>
                  </div>
                  <div
                    style={{ textAlign: "left", padding: "20px 0px 0px 0px" }}
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowDelete(false)}
                    ></span>
                    <span>
                      <button
                        onClick={() => setShowConfirmDelete(true)}
                        style={{ marginRight: "10px", cursor: "pointer" }}
                      >
                        حذف حساب کاربری
                      </button>
                    </span>
                  </div>
                </div>
              </span>
            </span>
            <ConfirmDialog 
            visible={showConfirmDelete} 
            onHide={() => setShowConfirmDelete(false)} 
            message="آیا از حذف حسابتان مطمئنید؟"
            header="تایید حذف"
            icon="pi pi-info-circle"
            acceptLabel="بله"
            rejectLabel="نه"
            acceptClassName="accept"
            rejectClassName="reject"
            accept={() => deleteAccount(user._id)} 
            reject={() => setShowConfirmDelete(false)} />
            <Toast ref={toast} />
          </div>
        </TabPanel>

        <TabPanel header="مدیریت صفحه ها و گروه ها">
          <h6>صفحه های عمومی</h6>
          <div className="settings-box" style={{ cursor: "auto" }}>
            <div>
              <span className="settings-row"
              style={{marginBottom:"20px"}}>
                <span className="settings-col1">
                  <i className="pi pi-clone"></i>
                </span>
                <span className="settings-col2">مدیریت صفحه های من</span>
              </span>


              {userPages.map((page, index) => (

            page.type != "group" ? 
                
            <span
              className="settings-row"
              key={index}
              onClick={() => navigate("/" + page.username + "/about", { state: {page: page, isPage: true , isUserPage:true } })}
            >
              

              <span className="settings-col1" style={{ marginLeft: "10px" }}>

              <img src={`${process.env.REACT_APP_BACK}/avatars/${page.avatarPic}`}></img>
               
              </span>
              <span className="settings-col2">{page.name}</span>
              
              
            </span>
            : null 
          ))}


              <span
                className="new-page-insettings settings-row"
                style={{
                  padding: "20px 0px 0px 0px",
                  color: "#af734f",
                  cursor: "pointer",
                }}
                onClick={() => setCreatePageDialog(true)}
              >
                <span className="settings-col1" style={{ marginLeft: "10px" }}>
                  <i
                    className="pi pi-plus-circle"
                    style={{
                      fontSize: "25px",
                      background: "#af734f1c",
                      color: "#af734f",
                      borderRadius: "50%",
                    }}
                  ></i>
                </span>
                <span className="settings-col2">ایجاد صفحه جدید</span>
              </span>
            </div>
          </div>

          <h6>گروه ها</h6>

          <div className="settings-box" style={{ cursor: "auto" }}>
            <div>
              <span className="settings-row"
              style={{marginBottom:"20px"}}>
                <span className="settings-col1">
                  <i className="pi pi-users"></i>
                </span>
                <span className="settings-col2">مدیریت گروه های من</span>
              </span>

              {userPages.map((page, index) => (

              page.type == "group" ? 
                  
              <span
                className="settings-row"
                key={index}
                onClick={() => navigate("/" + page.username + "/about", { state: {page: page, isPage: true , isUserPage:true } })}
              >
                

                <span className="settings-col1" style={{ marginLeft: "10px" }}>

                <img src={`${process.env.REACT_APP_BACK}/avatars/${page.avatarPic}`}></img>
                    
                
                </span>
                <span className="settings-col2">{page.name}</span>
                
                
              </span>
              : null 
))} 




              <div
                style={{
                  padding: "20px 20px 20px 20px",
                  color: "#af734f",
                  textAlign: "center",
                }}
              >
                <i style={{ fontSize: "35px" }} className="pi pi-users"></i>
                <p>
                  با ایجاد گروه و دعوت از دوستان و همکاران، فضایی برای به اشتراک
                  گذاری محتوی و دانش تخصصی فراهم کنید. همچنین با ایجاد گروه‌های
                  خصوصی می‌توانید جلسات و کلاس‌های آنلاین با امکانات پیشرفته،
                  برگزار کنید.
                </p>
                <button
                  style={{ cursor: "pointer" }}
                  onClick={() => setCreateGroupDialog(true)}
                >
                  ایجاد گروه جدید
                </button>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel header="تنظیمات اعلان ها">
          {/* <h6>اعلان های ایمیل</h6>
          <p style={{textAlign:"right" , fontSize:"12px",marginRight:"150px"}}>  تنظیم کنید که می خواهید چه اعلان هایی برایتان ایمیل شود</p>


          <div className="settings-box" style={{ cursor: "auto" }}>
            <div>
              <span className="settings-row">
                <span className="settings-col1">
                  <i className="pi pi-envelope"></i>
                </span>
                <span className="settings-col2">ایمیل</span>
              </span>

              <span className="settings-row" style={{ paddingTop: "30px" }}>
                <span style={{ textAlign: "right", width: "50%" }}>
                  اطلاع رسانی های پروفایل
                </span>
               
                <span style={{ textAlign: "left", width: "50%" }}>
                  <InputSwitch
                    checked={profileSetForEmail}
                    onChange={(e) => {
                      updateEmailNow("profile");
                      
                    }}
                  />
                </span>
              </span>

              <span className="settings-row" style={{ paddingTop: "10px" }}>
                <span style={{ textAlign: "right", width: "50%" }}>
                  دنبال شدن
                </span>
                <span style={{ textAlign: "left", width: "50%" }}>
                  <InputSwitch
                    checked={followedSetForEmail}
                    onChange={(e) => {
                    
                      updateEmailNow("followed");
                    }}
                  />
                </span>
              </span>

              <span className="settings-row" style={{ paddingTop: "10px" }}>
                <span style={{ textAlign: "right", width: "50%" }}>
                  لایک شدن پست
                </span>
                <span style={{ textAlign: "left", width: "50%" }}>
                  <InputSwitch
                    checked={postLikedSetForEmail}
                    onChange={(e) => {
                      updateEmailNow("postLiked");
                    }}
                  />
                </span>
              </span>

              <span className="settings-row" style={{ paddingTop: "10px" }}>
                <span style={{ textAlign: "right", width: "50%" }}>
                  نظر جدید روی پست
                </span>
                <span style={{ textAlign: "left", width: "50%" }}>
                  <InputSwitch
                    checked={postCommentedSetForEmail}
                    onChange={(e) => {
                      updateEmailNow("postCommented");
                    }}
                  />
                </span>
              </span>

              <span className="settings-row" style={{ paddingTop: "10px" }}>
                <span style={{ textAlign: "right", width: "50%" }}>
                  لایک شدن نظر
                </span>
                <span style={{ textAlign: "left", width: "50%" }}>
                  <InputSwitch
                    checked={commentsLikedSetForEmail}
                    onChange={(e) => {
                      updateEmailNow("commentLiked");
                    }}
                  />
                </span>
              </span>

              <span className="settings-row" style={{ paddingTop: "10px" }}>
                <span style={{ textAlign: "right", width: "50%" }}>
                  منشن شدن
                </span>
                <span style={{ textAlign: "left", width: "50%" }}>
                  <InputSwitch
                    checked={mentionedSetForEmail}
                    onChange={(e) => {
                      updateEmailNow("mentioned");
                    }}
                  />
                </span>
              </span>

              <span className="settings-row" style={{ paddingTop: "10px" }}>
                <span style={{ textAlign: "right", width: "50%" }}>
                  باز نشر پست
                </span>
                <span style={{ textAlign: "left", width: "50%" }}>
                  <InputSwitch
                    checked={postRepublishedSetForEmail}
                    onChange={(e) => {
                      updateEmailNow("postRepublished");
                    }}
                  />
                </span>
              </span>
            </div>
          </div> */}


          <div style={{display:"table" , margin:"auto"}}><h6 style={{marginRight:"0px"}}>اعلان های اپلیکیشن</h6></div>
          <p style={{textAlign:"right" , fontSize:"12px",marginRight:"150px"}}>  تنظیم کنید که می خواهید چه اعلان هایی برایتان ارسال شود</p>
          <div className="settings-box" style={{ cursor: "auto" }}>
            <div>
              

              <span className="settings-row" style={{ paddingTop: "30px" }}>
                <span style={{ textAlign: "right", width: "50%" }}>
                  اطلاع رسانی های کلی
                </span>
                <span style={{ textAlign: "left", width: "50%" }}>
                  <InputSwitch
                    checked={profileSetForApp}
                    onChange={(e) => {
                      updateAppNow("profile");
                    }}
                  />
                </span>
              </span>

              <span className="settings-row" style={{ paddingTop: "10px" }}>
                <span style={{ textAlign: "right", width: "50%" }}>
                  دنبال شدن
                </span>
                <span style={{ textAlign: "left", width: "50%" }}>
                  <InputSwitch
                    checked={followedSetForApp}
                    onChange={(e) => {
                    
                      updateAppNow("followed");
                    }}
                  />
                </span>
              </span>

              <span className="settings-row" style={{ paddingTop: "10px" }}>
                <span style={{ textAlign: "right", width: "50%" }}>
                  لایک شدن پست
                </span>
                <span style={{ textAlign: "left", width: "50%" }}>
                  <InputSwitch
                    checked={postLikedSetForApp}
                    onChange={(e) => {
                      updateAppNow("postLiked");
                    }}
                  />
                </span>
              </span>

              <span className="settings-row" style={{ paddingTop: "10px" }}>
                <span style={{ textAlign: "right", width: "50%" }}>
                  نظر جدید 
                </span>
                <span style={{ textAlign: "left", width: "50%" }}>
                  <InputSwitch
                    checked={postCommentedSetForApp}
                    onChange={(e) => {
                      updateAppNow("postCommented");
                    }}
                  />
                </span>
              </span>

              <span className="settings-row" style={{ paddingTop: "10px" }}>
                <span style={{ textAlign: "right", width: "50%" }}>
                  پیام جدید 
                </span>
                <span style={{ textAlign: "left", width: "50%" }}>
                  <InputSwitch
                    checked={messageSetForApp}
                    onChange={(e) => {
                      updateAppNow("message");
                    }}
                  />
                </span>
              </span>

              {/* <span className="settings-row" style={{ paddingTop: "10px" }}>
                <span style={{ textAlign: "right", width: "50%" }}>
                  لایک شدن نظر
                </span>
                <span style={{ textAlign: "left", width: "50%" }}>
                  <InputSwitch
                    checked={commentsLikedSetForApp}
                    onChange={(e) => {
                      updateAppNow("commentLiked");
                    }}
                  />
                </span>
              </span> */}

              {/* <span className="settings-row" style={{ paddingTop: "10px" }}>
                <span style={{ textAlign: "right", width: "50%" }}>
                  منشن شدن
                </span>
                <span style={{ textAlign: "left", width: "50%" }}>
                  <InputSwitch
                    checked={mentionedSetForApp}
                    onChange={(e) => {
                      updateAppNow("mentioned");
                    }}
                  />
                </span>
              </span> */}

              <span className="settings-row" style={{ paddingTop: "10px" }}>
                <span style={{ textAlign: "right", width: "50%" }}>
                  باز نشر پست
                </span>
                <span style={{ textAlign: "left", width: "50%" }}>
                  <InputSwitch
                    checked={postRepublishedSetForApp}
                    onChange={(e) => {
                      updateAppNow("postRepublished");
                    }}
                  />
                </span>
              </span>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
}

export default UserSettings;
