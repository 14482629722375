import "../Css/NewPost.css";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Chip  from "./Chip";
import { useNavigate } from "react-router-dom";
import { Editor } from "primereact/editor";

function EditPost(props)
{

    const [content, setContent] = useState('jjh'); // Static value for testing
    const [selectedTags,setSelectedTags] = useState(props.post.tags.map((value, index) => ({value: value})));
    const [selectedUsers,setSelectedUsers] = useState(props.post.taggedUsers);
    const [showTags,setShowTags] = useState(false);
    const [showTagUsers,setShowTagUsers] = useState(false);
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const navigate = useNavigate();
    const avatarPic =
    props.user["avatarPic"] != null
      ? props.user["avatarPic"]
      : "profile-index.png";


    
  useEffect( ()=>
  {


    setSelectedUsers(props.post.taggedUsers);
  }
    
    ,[props]);
    
    const editorRef = useRef(null);

    const colors = [
        'rgb(0, 0, 0)', 'rgb(230, 0, 0)', 'rgb(255, 153, 0)', 'rgb(255, 255, 0)', 'rgb(0, 138, 0)', 
        'rgb(0, 102, 204)', 'rgb(153, 51, 255)', 'rgb(255, 255, 255)', 'rgb(250, 204, 204)', 
        'rgb(255, 235, 204)', 'rgb(255, 255, 204)', 'rgb(204, 232, 204)', 'rgb(204, 224, 245)', 
        'rgb(235, 214, 255)', 'rgb(187, 187, 187)', 'rgb(240, 102, 102)', 'rgb(255, 194, 102)', 
        'rgb(255, 255, 102)', 'rgb(102, 185, 102)', 'rgb(102, 163, 224)', 'rgb(194, 133, 255)', 
        'rgb(136, 136, 136)', 'rgb(161, 0, 0)', 'rgb(178, 107, 0)', 'rgb(178, 178, 0)', 'rgb(0, 97, 0)', 
        'rgb(0, 71, 178)', 'rgb(107, 36, 178)', 'rgb(68, 68, 68)', 'rgb(92, 0, 0)', 'rgb(102, 61, 0)', 
        'rgb(102, 102, 0)', 'rgb(0, 55, 0)', 'rgb(0, 41, 102)', 'rgb(61, 20, 102)'
    ];

    const createColorOptions = (colors) => colors.map(color => (
        <option key={color} value={color} style={{ backgroundColor: color }}></option>
    ));

    const toolbarOptions = (
        <div id="toolbar">
            <select className="ql-header" defaultValue="">
                <option value="1">h1</option>
                <option value="2">h2</option>
                <option value="3">h3</option>
                <option value="">p</option>
            </select>
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <button className="ql-strike"></button>
            <button className="ql-list" value="ordered"></button>
            <button className="ql-list" value="bullet"></button>
            <button className="ql-link"></button>
            <button className="ql-image"></button>
            <select className="ql-color">
                {createColorOptions(colors)}
            </select>
            <select className="ql-background">
                {createColorOptions(colors)}
            </select>
        </div>
    );
  
    
    const formik = useFormik({
        initialValues: {
          description:props.post.description,
          category: props.post.category,
         
        },
        onSubmit: (values) => {
          
      
          

            const obj={};
            for(const key in values)
            {
                if(values[key] != "")
                    obj[key] = values[key];
            }


            obj["tags"]=selectedTags.map(element => element.value.trim());
            obj["taggedUsers"]=selectedUsers.map(element => element._id.trim());
            
          

              
    
              const data = JSON.stringify(obj);
    
              
        
    
              axios
                .patch(process.env.REACT_APP_BACK+"/posts/"+props.post._id, data, config)
                .then((response) => {

                if(response.status == 200)
                {
                  console.log(response);
                  props.close();
                }
                })
                .catch((error) => {
                  console.log("error",error);
                  if(error.response.data.statusCode===401)
                  navigate("/login");
                });
    
        },
      });
    


    return(
        
        <div className="edit-post" >


          
            <form onSubmit={formik.handleSubmit} className="new-post-form">
        <span className="create-new-post-dialog">
          <span id="new-post-profile">
          <img src={process.env.REACT_APP_BACK+`/avatars/${avatarPic}`}></img>
          </span>
          <span id="new-post-name">{props.user["name"]}</span>
          <span id="new-post-category">
            
            {/* <Dropdown
              id="category"
              name="category"
              value={formik.values.category}
              options={userCats}
              onChange={formik.handleChange}
            /> */}
          </span>
        </span>

        <div className="media-upload">
          
        

        {props.post.fileType=="image" && (
        <img
          className="VideoInput_video"
          style={{width:"100%",height:"fit-content",borderRadius:"5px"}}
          
          src={process.env.REACT_APP_BACK+`/posts/${props.post.file}`}
        />
      )}

      
    {props.post.fileType=="video" && (
        <video
          className="VideoInput_video"
          width="100%"
          height="fit-content"
          controls
          src={process.env.REACT_APP_BACK+`/posts/${props.post.file}`}
        />
      )}


        </div>

        <span id="content">
           <InputTextarea
          id="description"
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
            rows={5}
            cols={30}
            autoResize
      
          /> 




          <div className="tag-user" style={{marginTop:"20px",direction:"rtl",textAlign:"right",display:"flex",alignItems:"center"}}>

          <span style={{display:"flex",width:"15%"}}onClick={()=>{  setShowTagUsers(false);  setShowTags(!showTags); }}><i className="pi pi-tag"></i></span>
          <span style={{display:"flex",width:"15%"}} onClick={()=>{ setShowTags(false); setShowTagUsers(!showTagUsers); }} ><i className="pi pi-user"></i></span>
          <span style={{display:"flex",direction:"ltr",width:"60%"}}>
          <span>
            <Button type="submit">ثبت</Button>
          </span>

          </span>

          </div>


          <div>


          {/* tags */}
          <div id="tags-area" style={{height:!showTags?"0px":null}}>
      
          <h6 style={{height:"20%",textAlign:"right",marginBottom:"0px",marginTop:"20px"}}>:برچسب ها</h6>
       

        
        <Chip
          id="tags"
          inputId="tags"
          name="tags"
          type="tags"
          setSelections={setSelectedTags}
          selections={selectedTags}
        >

        </Chip>




          </div>


          {/* tag users */}
          <div id="tag-users-area" style={{height:!showTagUsers?"0px":null}}>

          <h6 style={{height:"20%",textAlign:"right",marginBottom:"0px",marginTop:"20px"}}>:کاربر یا صفحه ای را تگ کنید</h6>

         
        <Chip
        id="taggedUsers"
        inputId="taggedUsers"
        name="taggedUsers"
        type="taggedUsers"
        setSelections={setSelectedUsers}
        selections={selectedUsers}
        >
        </Chip>
     
      
          
          </div>


          

          </div>

      
        </span>

        <span>
          
        </span>
      </form>

        </div>
    );

}

export default EditPost;