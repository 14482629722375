import axios from "axios";
import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../Css/Notification.css";

function Notification(props) {
  const action = {
    like: "پست شما را لایک کرد",
    follow: "شما را دنبال می کند",
    comment: "نظری برای پست شما نوشت",
    message:"پیام جدیدی ارسال کرد",
    addToGroup:"شما را در یک گروه جدید عضو کرد ",
    repost:"پست شما را بازنشر کرد",
    taggedUsers:"شما را تگ کرد"
  };
  const onlineUser = JSON.parse(localStorage.getItem("flickerNetwork") != null)
    ? JSON.parse(localStorage.getItem("flickerNetwork"))
    : { username: "", name: "", avatarPic: "profile-index.png" };
  const [isFollowed, setIsFollowed] = useState(false);
  const token = JSON.parse(localStorage.getItem('userToken'));
  const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
  const navigate = useNavigate();
  const follow = () => {
    //follow:
    if (!isFollowed) {
      const data = JSON.stringify({
        sender_id: onlineUser._id,
        sender_username:onlineUser.username,
        sender_name:onlineUser.name,
        sender_avatar: onlineUser.avatarPic,
        reciever_id: props.notif.sender_id._id,
        type: "follow",
      });
      axios
        .post(process.env.REACT_APP_BACK+"/notifications/follow", data, config)
        .then((response) => {
          if (response.status == 201) {
            console.log(response);
            setIsFollowed(true);
          }
        })
        .catch((error) => {
          console.log("error", error);
          if(error.response.data.statusCode===401)
              navigate("/login")
        });
    }
    //unFollow:
    else {
      axios
        .delete(
          process.env.REACT_APP_BACK+"/notifications/follow/" +
            onlineUser._id +
            "/" +
            props.notif.sender_id._id,
          config
        )
        .then((response) => {
          if (response.status == 200) {
            console.log(response);
            setIsFollowed(false);
          }
        })
        .catch((error) => {
          console.log("error", error);
          if(error.response.data.statusCode===401)
              navigate("/login")
        });
    }
  };

  //is user followed?:
  useEffect(() => {
    async function isUserFollowed() {

      if(props.notif.sender_id!==null && props.notif.sender_id !== undefined)
      {
      const result = await axios.get(
        process.env.REACT_APP_BACK+"/notifications/follow/" +
          onlineUser._id +
          "/" +
          props.notif.sender_id._id,
        config
      );
      setIsFollowed(result.data);
      return result.data;
      }
      else
        return false;
    }

    isUserFollowed();
  });

  return (
    <div>

      {/* if sender and reciever are the same, do not show notif */}

      { props.notif.sender_id === undefined || props.notif.sender_id === null || (props.notif.sender_id!==undefined && props.notif.sender_id._id !== onlineUser._id) ?

      <span className="notif-row">
        {/* link to user profile: */}

        
        {/* sender avatar */}
        {props.notif.sender_id !== null && props.notif.sender_id !== undefined ?
        <Link to={"/"+props.notif.sender_id.username+"/about"}
            state={{otherUser: {_id:props.notif.sender_id._id, username:props.notif.sender_id.username, name:props.notif.sender_id.name , job:"" , avatarPic:props.notif.sender_id.avatarPic} 
            , profileOfOther:true}}
                >
        <span className="notif-col1">
        <img src={process.env.REACT_APP_BACK+`/avatars/${props.notif.sender_id.avatarPic}`}></img>
        </span>
        </Link>
        :
        <span className="notif-col1">
        <img src={process.env.REACT_APP_BACK+`/avatars/deletedAcount-index.png`}></img>
        </span>
        }


        {/* sender name */}
        <span className="notif-col2">
          <div style={{ width: "100%", textAlign: "right" }}>

            {props.notif.sender_id!==null && props.notif.sender_id !== undefined ?
            <span style={{ fontWeight: "bold", marginLeft: "3px" }}>
              {props.notif.sender_id.name+" "}
              {(props.notif.type==="like" && props.notif["manyLikes"]) ? " و " + props.notif["manyLikes"]+" نفر دیگر": null}
      
            </span>
            :
            <span style={{ fontWeight: "bold", marginLeft: "3px" }}>
              {"کاربر حذف شده "}
            </span>
            }


              
             {props.notif.reply_to_comment!==undefined ?<span>"به کامنت شما پاسخ داد" </span> : <span>{action[props.notif.type]}</span> }

             {props.notif.type == "comment" ?<Link to={"/posts/"+props.notif.post_id._id} ><span>: {props.notif.content.slice(0,60)}</span></Link> : null}
             
             {props.notif.type == "addToGroup" && props.notif.group_id!==null? <span>: {props.notif.group_id.name}</span> : null}
            
          





          </div>
          <div 
            id="notif-date"
            style={{ width: "100%", textAlign: "right", fontStyle: "italic" }}
          >
            {new Date(props.notif.date).toLocaleDateString('fa-IR')}
          </div>
        </span>
        
          {/* follow & unfollow icon */}
          {!isFollowed && props.notif.type==="follow" && props.notif.sender_id!==null && props.notif.sender_id !== undefined ? (
            <span className="notif-col3" >
            <span>
              <a onClick={follow}> دنبال کردن</a>
            </span>
            </span>
          ) : null}


          {/* link to message */}
          { props.notif.type==="message" && props.notif.sender_id!==null && props.notif.sender_id !== undefined?
          <span className="notif-col3">
          <span>
          <p onClick={()=> navigate("/"+props.notif.sender_id.username+"/about"
            ,{state:{otherUser: {_id:props.notif.sender_id._id, username:props.notif.sender_id.username, name:props.notif.sender_id.name , job:"" , avatarPic:props.notif.sender_id.avatarPic} 
            , profileOfOther:true,message:true}})}
                > مشاهده پیام
                </p>
          </span>
          </span>
        : null}

         {/* link to post */}
         { (props.notif.type==="like" || props.notif.type==="taggedUsers" || props.notif.type==="comment" || props.notif.type ==="repost") && props.notif.sender_id!==null && props.notif.sender_id !== undefined?
          <span className="notif-col3" style={{background:"transparent",border:"none"}}>
          <span>
          { props.notif.post_id !==null ? <Link to={"/posts/"+props.notif.post_id._id} >
                  {props.notif.post_id.fileType==="image" ?
                  <img src={`${process.env.REACT_APP_BACK}/posts/${props.notif.post_id.file}`}
                        style={{borderRadius:"0%"}}></img>
                      :
                      <img src={`${process.env.REACT_APP_BACK}/posters/poster_${props.notif.post_id.file.replace(/\.[^/.]+$/, '')}.png`}
                      style={{borderRadius:"0%"}}
                      
                      ></img>
                }
              
                </Link>
                : null }

          </span>
          </span>
        : null}

        {/* link to group */}
        { props.notif.type==="addToGroup" && props.notif.sender_id!==null && props.notif.sender_id !== undefined?
          <span className="notif-col3">
          <span>
          <p onClick={()=> navigate("/"+props.notif.group_id.username+"/about"
            ,
            {state:{page: {_id:props.notif.group_id._id, username:props.notif.group_id.username, name:props.notif.group_id.name , avatarPic:props.notif.group_id.avatarPic},isPage:true , isUserPage:false}})}
                > مشاهده گروه
                </p>
          </span>
          </span>
        : null}
        
      </span>

      : null }

    </div>
  );
}

export default Notification;
