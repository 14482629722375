import "../Css/Tag.css";

function Tag(props)
{
    return(

        <div className="tag">
            <div className="button">
                <button>{props.value}</button>
                
            </div>

        </div>
    );
}

export default Tag;